<template>
  <b-modal :id="idModal" :size='size' ok-only ok-title="Fechar">
    <div slot="modal-title">
      <div class="f-22">Lista de substituição</div>
    </div>
    <div class="text-center">
      <div class="f-18">{{replacementList.descricao}}</div>
      <div class="text-muted pt-2 f-16" v-if="quantidade> 1">Quantidade de consumo: {{quantidade}}</div>
      <div class="text-muted pt-2 f-16" v-else>consumir à vontade</div>
    </div>
    <div class="pt-4">
      <b-tabs class="nav-tabs-top nav-responsive-xl p-t-5">
        <b-tab title="Itens da lista de substituição" title-link-class="f-15">
          <table-detail-replacement-list :replacement-list="replacementList" class="pt-4"/>
        </b-tab>
        <b-tab title="Média Nutrientes" title-link-class="f-15" @click="loadDataNutrients()" v-if="quantidade > 1">
          <b-overlay id="overTabsFood" :show="overlay.showOverlay" :variant="overlay.overlayVariant" :blur="overlay.overlayBlur" :spinner-variant="overlay.spinnerVariant" :spinner-type="overlay.spinnerType" :opacity="overlay.overlayOpacity" rounded="sm">
            <table-macro-micro-nutrients :macros="macronutrients" :micros="micronutrients" type="MEDIA" class="mt-3"/>
          </b-overlay>
        </b-tab>
      </b-tabs>
    </div>
  </b-modal>
</template>

<script>
import TableDetailReplacementList from '@/components/list/replacementList/TableDetailReplacementList'
import TableMacroMicroNutrients from '@/components/ui/TableMacroMicroNutrients'
import globals from '@/globals'
import axios from 'axios'

export default {
  name: 'modalReplacementListInfo',
  components: { TableDetailReplacementList, TableMacroMicroNutrients },
  props: ['idModal'],
  data () {
    return {
      replacementList: '',
      quantidade: 1,
      size: null,
      overlay: globals().configOverlay,
      micronutrients: [],
      macronutrients: {
        totalKcal: 0,
        totalCarboidratosGramas: 0,
        totalProteinasGramas: 0,
        totalGordurasGramas: 0
      }
    }
  },
  methods: {
    openModal (replacementList, quantidade) {
      this.replacementList = replacementList
      this.quantidade = quantidade
      this.$bvModal.show(this.idModal)
    },
    loadDataNutrients () {
      this.overlay.showOverlay = true
      let param = {}
      if (this.measure !== null) {
        param = { quantidade: this.quantidade }
      }
      axios.get(globals().baseApiUrl + '/listassubstituicao/' + this.replacementList.idGrupoAlimentoEmpresa + '/dadosnutricionais', { params: param, showLoader: false })
        .then(res => {
          this.overlay.showOverlay = false
          this.micronutrients = res.data.mediaMicronutrientes
          this.macronutrients.totalKcal = res.data.mediaMacronutrientes.mediaCalorias
          this.macronutrients.totalCarboidratosGramas = res.data.mediaMacronutrientes.mediaCarboidratos
          this.macronutrients.totalProteinasGramas = res.data.mediaMacronutrientes.mediaProteinas
          this.macronutrients.totalGordurasGramas = res.data.mediaMacronutrientes.mediaGordurasTotais
        })
        .catch(globals.showError)
    }
  }
}
</script>

<style scoped>

</style>
