import Vue from 'vue'
export default new Vue({
  methods: {
    newFoodSave (food) {
      this.$emit('newFoodSave', food)
    },
    hasNewFood (callback) {
      this.$on('newFoodSave', callback)
    },
    newRecipeSave (recipe) {
      this.$emit('newRecipeSave', recipe)
    },
    hasNewRecipe (callback) {
      this.$on('newRecipeSave', callback)
    },
    saveEditItem (objItem) {
      this.$emit('saveEditItem', objItem)
    },
    hasSaveEditItem (callback) {
      this.$on('saveEditItem', callback)
    },
    saveAddItemInCombined (objItem) {
      this.$emit('saveAddItemInCombined', objItem)
    },
    hasSaveAddItemInCombined (callback) {
      this.$on('saveAddItemInCombined', callback)
    },
    updateMacrosAveregaCombined (objItem) {
      this.$emit('updateMacrosAveregaCombined', objItem)
    },
    hasUpdateMacrosAveregaCombined (callback) {
      this.$on('updateMacrosAveregaCombined', callback)
    },
    parseParamCombinedMacrosMicros (objItem) {
      this.$emit('parseParamCombinedMacrosMicros', objItem)
    },
    hasParseParamCombinedMacrosMicros (callback) {
      this.$on('parseParamCombinedMacrosMicros', callback)
    },
    saveEditItemReplacementCombined (objItem) {
      this.$emit('saveEditItemReplacementCombined', objItem)
    },
    hasSaveEditItemReplacementCombined (callback) {
      this.$on('saveEditItemReplacementCombined', callback)
    },
    changeSelectedForWhiterPlan (objItem) {
      this.$emit('changeSelectedForWhiterPlan', objItem)
    },
    hasChangeSelectedForWhiterPlan (callback) {
      this.$on('changeSelectedForWhiterPlan', callback)
    },
    changeSelectedPlanCalculed (objItem) {
      this.$emit('changeSelectedPlanCalculed', objItem)
    },
    hasChangeSelectedPlanCalculed (callback) {
      this.$on('changeSelectedPlanCalculed', callback)
    },
    transportHashCardCreditJuno (objItem) {
      this.$emit('transportHashCardCreditJuno', objItem)
    },
    hasTransportHashCardCreditJuno (callback) {
      this.$on('transportHashCardCreditJuno', callback)
    },
    callUpdateMicrosFoodPlan (objItem) {
      this.$emit('callUpdateMicrosFoodPlan', objItem)
    },
    hasCallUpdateMicrosFoodPlan (callback) {
      this.$on('callUpdateMicrosFoodPlan', callback)
    },
    callRenderBodyComposition (objItem) {
      this.$emit('callRenderBodyComposition', objItem)
    },
    hasCallRenderBodyComposition (callback) {
      this.$on('callRenderBodyComposition', callback)
    }
  }
})
