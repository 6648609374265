import Vue from 'vue'
import { uuid } from 'vue-uuid'
export default new Vue({
  computed: {
    mealsViewBuild () {
      return this.$store.getters.getMealsVuewBuild
    }
  },
  methods: {
    getComponentForSaveFood (item) {
      const result = {
        uuid: item.uuid,
        alimento: { idAlimento: item.item.idItem, descricao: item.item.descricao },
        medida: item.medida,
        quantidade: item.quantidade
      }
      return result
    },
    getComponentForSaveRecipe (item) {
      const result = {
        uuid: item.uuid,
        receita: { idReceita: item.item.idItem, nome: item.item.descricao },
        medida: item.medida,
        quantidade: item.quantidade
      }
      return result
    },
    getComponentForSaveReplacement (item) {
      const result = {
        uuid: item.uuid,
        grupoAlimentoEmpresa: { idGrupoAlimentoEmpresa: item.item.idItem, descricao: item.item.descricao },
        quantidade: item.quantidade
      }
      return result
    },
    getComponentForSaveCombined (item) {
      const result = {
        uuid: item.uuid,
        combinacaoAlimentoEmpresa: { idCombinacaoAlimentoEmpresa: item.item.idItem, nome: item.item.descricao },
        quantidade: item.quantidade
      }
      return result
    },
    getFoodForItem (food) {
      const result = {
        uuid: uuid.v4(),
        item: { idItem: food.alimento.idAlimento, descricao: food.alimento.descricao },
        medida: food.medida,
        quantidade: food.quantidade,
        type: 'FOOD'
      }
      return result
    },
    getRecipeForItem (recipe) {
      const result = {
        uuid: uuid.v4(),
        item: { idItem: recipe.receita.idReceita, descricao: recipe.receita.nome },
        medida: recipe.medida,
        quantidade: recipe.quantidade,
        type: 'RECIPE'
      }
      return result
    },
    getReplacementForItem (replacement) {
      const result = {
        uuid: uuid.v4(),
        item: {
          idItem: replacement.grupoAlimentoEmpresa.idGrupoAlimentoEmpresa,
          descricao: replacement.grupoAlimentoEmpresa.descricao
        },
        quantidade: replacement.quantidade,
        type: 'REPLACEMENT'
      }
      return result
    },
    getCombinedForItem (combined) {
      const result = {
        uuid: uuid.v4(),
        item: {
          idItem: combined.combinacaoAlimentoEmpresa.idCombinacaoAlimentoEmpresa,
          descricao: combined.combinacaoAlimentoEmpresa.nome
        },
        quantidade: combined.quantidade,
        type: 'COMBINED'
      }
      return result
    },
    getMaskHour () {
      return '##:##'
    },
    formatParamsTotalMacros (objMeal) {
      const params = { alimentos: [], grupos: [], combinados: [], receitas: [] }
      params.alimentos = objMeal.alimentos.map((foodCurrent) => {
        const objFood = {
          idAlimento: foodCurrent.alimento.idAlimento,
          idMedida: foodCurrent.medida.idMedida,
          quantidade: foodCurrent.quantidade
        }
        return objFood
      })
      params.grupos = objMeal.grupos.map((replacementCurrent) => {
        const objReplacement = {
          idGrupo: replacementCurrent.grupoAlimentoEmpresa.idGrupoAlimentoEmpresa,
          quantidade: replacementCurrent.quantidade
        }
        return objReplacement
      })
      params.receitas = objMeal.receitas.map((recipeCurrent) => {
        const objRecipe = {
          idReceita: recipeCurrent.receita.idReceita,
          idMedida: recipeCurrent.medida.idMedida,
          quantidade: recipeCurrent.quantidade
        }
        return objRecipe
      })
      params.combinados = objMeal.alimentosCombinados.map((combinedCurrent) => {
        const objCombined = {
          idCombinacaoAlimentoEmpresa: combinedCurrent.combinacaoAlimentoEmpresa.idCombinacaoAlimentoEmpresa,
          quantidade: combinedCurrent.quantidade
        }
        return objCombined
      })
      return params
    },
    findIndexElementByUuidKey (vector, uuid) {
      return vector.findIndex(element => element.uuid === uuid)
    },
    rounding (value) {
      if (value === '0' || value === '-' || value === undefined || value === null || value === '' || isNaN(value) || value === Infinity) {
        return 0
      } else {
        const result = +(Math.round(value + 'e+2') + 'e-2')
        return result.toFixed(2)
      }
    },
    rouding3 (input) {
      if (input === '0' || input === '-' || input === undefined || input === null || input === '' || isNaN(input) || input === Infinity) {
        return 0
      } else {
        return +(Math.round(input + 'e+3') + 'e-3')
      }
    },
    getClassButton (action) {
      let result = ''
      switch (action) {
        case 'PDF' :
          result = 'ion ion-md-print'
          break
        case 'DELETE' :
          result = 'fas fa-times'
          break
        case 'WRITE' :
          result = 'fas fa-pen'
          break
        case 'READ' :
          result = 'far fa-eye'
          break
        default :
          result = ''
      }
      return result
    },
    getClassActionColor (action) {
      let result = ''
      switch (action) {
        case 'PDF' :
          result = 'btn-outline-secondary'
          break
        case 'DELETE' :
          result = 'btn-outline-danger'
          break
        case 'WRITE' :
          result = 'btn-outline-info'
          break
        case 'READ' :
          result = 'btn-outline-vimeo'
          break
        default :
          result = ''
      }
      return result
    },
    caculateRDAForMicronutrient (objIdade, map, valorMicronutriente, genero, isGestante, isLactante, unidade) {
      let valorRDA
      let htmlReturn = ''
      if (objIdade.qtdAno === 0) {
        if (objIdade.qtdMes >= 0 && objIdade.qtdMes <= 6) {
          valorRDA = map.get('0-6m')
        } else if (objIdade.qtdMes >= 7 && objIdade.qtdMes <= 12) {
          valorRDA = map.get('7-12m')
        }
      } else if (objIdade.qtdAno >= 1 && objIdade.qtdAno <= 3) {
        valorRDA = map.get('c-1-3')
      } else if (objIdade.qtdAno >= 4 && objIdade.qtdAno <= 8) {
        valorRDA = map.get('c-4-8')
      } else if (genero === 'M') {
        if (objIdade.qtdAno >= 9 && objIdade.qtdAno <= 13) {
          valorRDA = map.get('h-9-13')
        } else if (objIdade.qtdAno >= 14 && objIdade.qtdAno <= 18) {
          valorRDA = map.get('h-14-18')
        } else if (objIdade.qtdAno >= 19 && objIdade.qtdAno <= 30) {
          valorRDA = map.get('h-19-30')
        } else if (objIdade.qtdAno >= 31 && objIdade.qtdAno <= 50) {
          valorRDA = map.get('h-31-50')
        } else if (objIdade.qtdAno >= 31 && objIdade.qtdAno <= 50) {
          valorRDA = map.get('h-31-50')
        } else if (objIdade.qtdAno >= 51 && objIdade.qtdAno <= 70) {
          valorRDA = map.get('h-51-70')
        } else if (objIdade.qtdAno >= 71) {
          valorRDA = map.get('h-71')
        }
      } else if (genero === 'F') {
        if (isGestante) {
          if (objIdade.qtdAno <= 18) {
            valorRDA = map.get('g-18')
          } else if (objIdade.qtdAno >= 19 && objIdade.qtdAno <= 30) {
            valorRDA = map.get('g-19-30')
          } else if (objIdade.qtdAno >= 31 && objIdade.qtdAno <= 50) {
            valorRDA = map.get('g-31-50')
          }
        } else if (isLactante) {
          if (objIdade.qtdAno <= 18) {
            valorRDA = map.get('l-18')
          } else if (objIdade.qtdAno >= 19 && objIdade.qtdAno <= 30) {
            valorRDA = map.get('l-19-30')
          } else if (objIdade.qtdAno >= 31 && objIdade.qtdAno <= 50) {
            valorRDA = map.get('l-31-50')
          }
        } else {
          if (objIdade.qtdAno >= 9 && objIdade.qtdAno <= 13) {
            valorRDA = map.get('m-9-13')
          } else if (objIdade.qtdAno >= 14 && objIdade.qtdAno <= 18) {
            valorRDA = map.get('m-14-18')
          } else if (objIdade.qtdAno >= 19 && objIdade.qtdAno <= 30) {
            valorRDA = map.get('m-19-30')
          } else if (objIdade.qtdAno >= 31 && objIdade.qtdAno <= 50) {
            valorRDA = map.get('m-31-50')
          } else if (objIdade.qtdAno >= 31 && objIdade.qtdAno <= 50) {
            valorRDA = map.get('m-31-50')
          } else if (objIdade.qtdAno >= 51 && objIdade.qtdAno <= 70) {
            valorRDA = map.get('m-51-70')
          } else if (objIdade.qtdAno >= 71) {
            valorRDA = map.get('m-71')
          }
        }
      }
      if (valorRDA === undefined || valorRDA === null || valorRDA === '' || valorRDA === 'undefined') {
        htmlReturn = ''
      } else {
        const minValue = (valorRDA * 90) / 100
        const maxValue = (valorRDA * 110) / 100
        const isAcima = valorMicronutriente >= maxValue
        const isBaixo = valorMicronutriente <= minValue
        if (!isAcima && !isBaixo) {
          if (valorMicronutriente === valorRDA) {
            htmlReturn = '<span class="badge badge-success"> ' + valorRDA + ' ' + unidade + '</span>'
          } else if (valorMicronutriente > valorRDA) {
            htmlReturn = '<span class="badge badge-success"><i class="ion ion-ios-arrow-down"></i> ' + valorRDA + ' ' + unidade + '</span>'
          } else if (valorMicronutriente < valorRDA) {
            htmlReturn = '<span class="badge badge-success"><i class="ion ion-ios-arrow-up"></i> ' + valorRDA + ' ' + unidade + '</span>'
          }
        } else if (isAcima) {
          htmlReturn = '<span class="badge badge-danger2 text-white"><i class="ion ion-ios-arrow-down"></i> ' + valorRDA + ' ' + unidade + '</span>'
        } else if (isBaixo) {
          htmlReturn = '<span class="badge badge-danger2 text-white"><i class="ion ion-ios-arrow-up"></i> ' + valorRDA + ' ' + unidade + '</span>'
        }
      }
      return htmlReturn
    }
  }
})
