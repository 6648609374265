<template>
  <div>
    <h4 class="font-weight-bold py-3 mb-3">{{ title }}
      <div class="text-muted text-tiny mt-2" v-if="subtitle">
        <small class="font-weight-normal f-15">{{ subtitle }}</small>
      </div>
    </h4>
  </div>
</template>

<script>
export default {
  name: 'PageTitle',
  props: ['title', 'subtitle']
}
</script>

<style scoped>

</style>
