<template>
  <b-modal :id="idModal" :size='size' ok-only ok-title="Fechar">
    <div slot="modal-title">
      <div class="f-22">{{nameFood}}</div>
      <div class="text-muted f-20">{{medidaDescricao}}</div>
    </div>
    <div>
      <div class="row text-center p-b-10">
        <div class="col-sm p-5">
          <span class="f-19" v-if="nutrientes.calorias">{{ nutrientes.calorias.valor }} {{ nutrientes.calorias.nutriente.unidade }}</span>
          <br/>
          <span class="f-17">Calorias</span>
        </div>
        <div class="col-sm p-5">
          <span class="f-19" v-if="nutrientes.carboidratos">{{ nutrientes.carboidratos.valor }} {{ nutrientes.carboidratos.nutriente.unidade }}</span>
          <br/>
          <span class="f-17">Carboidratos</span>
        </div>
        <div class="col-sm p-5">
          <span class="f-19" v-if="nutrientes.proteinas">{{ nutrientes.proteinas.valor}} {{ nutrientes.proteinas.nutriente.unidade }}</span>
          <br/>
          <span class="f-17">Proteínas</span>
        </div>
        <div class="col-sm p-5">
          <span class="f-19" v-if="nutrientes.lipidios">{{ nutrientes.lipidios.valor}} {{ nutrientes.lipidios.nutriente.unidade }}</span>
          <br/>
          <span class="f-17">Lipídios total</span>
        </div>
      </div>
      <b-tabs class="nav-tabs-top nav-responsive-xl p-t-20">
        <b-tab title="Principais" title-link-class="f-15" v-if="nutrientes.principais.length > 0">
          <TableNutrients :nutrients="nutrientes.principais"/>
        </b-tab>
        <b-tab title="Lipídios" title-link-class="f-15" v-if="nutrientes.lipidiosV.length > 0">
          <TableNutrients :nutrients="nutrientes.lipidiosV"/>
        </b-tab>
        <b-tab title="Vitaminas" title-link-class="f-15" v-if="nutrientes.vitaminas.length > 0">
          <TableNutrients :nutrients="nutrientes.vitaminas"/>
        </b-tab>
        <b-tab title="Minerais" title-link-class="f-15" v-if="nutrientes.minerais.length > 0">
          <TableNutrients :nutrients="nutrientes.minerais"/>
        </b-tab>
        <b-tab title="Aminoácidos" title-link-class="f-15" v-if="nutrientes.aminoacidos.length > 0">
          <TableNutrients :nutrients="nutrientes.aminoacidos"/>
        </b-tab>
        <b-tab title="Outros" title-link-class="f-15" v-if="nutrientes.outros.length > 0">
          <TableNutrients :nutrients="nutrientes.outros"/>
        </b-tab>
      </b-tabs>
    </div>
  </b-modal>
</template>

<script>
import TableNutrients from '@/components/ui/TableNutrients'
export default {
  name: 'modalFoodInfo',
  components: { TableNutrients },
  props: ['idModal'],
  data () {
    return {
      size: null,
      nameFood: '',
      medida: '',
      medidaDescricao: '',
      quantidade: 0,
      dadosNutricionais: '',
      nutrientes: {
        calorias: '',
        carboidratos: '',
        proteinas: '',
        lipidios: '',
        principais: [],
        lipidiosV: [],
        vitaminas: [],
        aminoacidos: [],
        minerais: [],
        outros: []
      }
    }
  },
  methods: {
    openModal (data, nameFood, qtd, measure) {
      this.nameFood = nameFood
      this.medida = measure
      this.dadosNutricionais = data.dadosNutricionais
      if (this.medida.descricaoMedida === 'gramas' || this.medida.descricaoMedida === 'grama') {
        this.medidaDescricao = this.medida.valorMedida + ' ' + this.medida.descricaoMedida
        this.quantidade = 1
      } else {
        this.quantidade = qtd
        const valorMedida = this.$options.filters.arrendondamento(this.quantidade * this.medida.valorMedida)
        this.medidaDescricao = qtd + ' ' + this.medida.descricaoMedida + ' (' + valorMedida + ' ' + this.medida.unidade + ')'
      }
      this.clearVariables()
      this.formatNutrientes()
      this.$bvModal.show(this.idModal)
    },
    formatNutrientes () {
      for (let x = 0; x < this.dadosNutricionais.length; x++) {
        const objNutriente = this.dadosNutricionais[x]
        objNutriente.nomeOrder = objNutriente.nutriente.nutriente
        if (objNutriente.nutriente.idNutriente === '586d6a0503894421f0f96be3') {
          this.nutrientes.calorias = objNutriente
        } else if (objNutriente.nutriente.idNutriente === '586d6a0503894421f0f96bac') {
          this.nutrientes.carboidratos = objNutriente
        } else if (objNutriente.nutriente.idNutriente === '586d6a0503894421f0f96bd2') {
          this.nutrientes.proteinas = objNutriente
        } else if (objNutriente.nutriente.idNutriente === '586d6a0503894421f0f96bc1') {
          this.nutrientes.lipidios = objNutriente
        } else if (objNutriente.nutriente.tipoNutriente === 'Principais') {
          this.nutrientes.principais.push(objNutriente)
        } else if (objNutriente.nutriente.tipoNutriente === 'Lipídios') {
          this.nutrientes.lipidiosV.push(objNutriente)
        } else if (objNutriente.nutriente.tipoNutriente === 'Vitaminas') {
          this.nutrientes.vitaminas.push(objNutriente)
        } else if (objNutriente.nutriente.tipoNutriente === 'Aminoácidos') {
          this.nutrientes.aminoacidos.push(objNutriente)
        } else if (objNutriente.nutriente.tipoNutriente === 'Minerais') {
          this.nutrientes.minerais.push(objNutriente)
        } else if (objNutriente.nutriente.tipoNutriente === 'Outros') {
          this.nutrientes.outros.push(objNutriente)
        }
      }
    },
    clearVariables () {
      this.nutrientes.principais = []
      this.nutrientes.lipidiosV = []
      this.nutrientes.vitaminas = []
      this.nutrientes.aminoacidos = []
      this.nutrientes.minerais = []
      this.nutrientes.outros = []
    }
  }
}
</script>

<style scoped>

</style>
