<template>
  <b-modal :id="idModal" :size='size' ok-only ok-title="Fechar">
    <div slot="modal-title">
      <div class="f-22">Alimento combinado</div>
      <div class="text-muted f-16 p-t-10">{{combinedFood.nome}}</div>
      <div class="text-center">
        <div class="text-muted pt-2 f-16">Quantidade de consumo: {{quantidade}}</div>
      </div>
    </div>
    <div>
      <b-tabs class="nav-tabs-top nav-responsive-xl">
        <b-tab title="Itens" title-link-class="f-15">
          <TableItemsMedida :items-table="itensCombined" class="mt-3"/>
        </b-tab>
        <b-tab title="Tabela nutricional" title-link-class="f-15" @click="loadDataNutrients(combinedFood.idCombinacaoAlimentoEmpresa)">
          <b-overlay id="overTabsFood" :show="overlay.showOverlay" :variant="overlay.overlayVariant" :blur="overlay.overlayBlur" :spinner-variant="overlay.spinnerVariant" :spinner-type="overlay.spinnerType" :opacity="overlay.overlayOpacity" rounded="sm">
            <table-macro-micro-nutrients :macros="macronutrients" :micros="micronutrients" type="MEDIA" class="mt-3"/>
          </b-overlay>
        </b-tab>
      </b-tabs>
    </div>
  </b-modal>
</template>

<script>
import TableItemsMedida from '@/components/ui/TableItemsMedida'
import axios from 'axios'
import globals from '@/globals'
import TableMacroMicroNutrients from '@/components/ui/TableMacroMicroNutrients'
export default {
  name: 'modalCombinedFoodInfo',
  components: { TableMacroMicroNutrients, TableItemsMedida },
  props: ['idModal'],
  data () {
    return {
      size: null,
      overlay: globals().configOverlay,
      combinedFood: '',
      itensCombined: [],
      macronutrients: '',
      micronutrients: [],
      quantidade: 1
    }
  },
  methods: {
    openModal (combinedFood, quantidade) {
      this.micronutrients = []
      this.quantidade = quantidade
      this.combinedFood = combinedFood
      this.macronutrients = combinedFood.totalMacronutriente
      this.macronutrients.totalCarboidratosGramas = this.$options.filters.arrendondamento(this.macronutrients.totalCarboidratosGramas * this.quantidade)
      this.macronutrients.totalProteinasGramas = this.$options.filters.arrendondamento(this.macronutrients.totalProteinasGramas * this.quantidade)
      this.macronutrients.totalGordurasGramas = this.$options.filters.arrendondamento(this.macronutrients.totalGordurasGramas * this.quantidade)
      this.macronutrients.totalKcal = this.$options.filters.arrendondamento(this.macronutrients.totalKcal * this.quantidade)
      this.itensCombined = []
      this.initVectorAlimentos(this.combinedFood.alimentosObrigatorios)
      this.initVectorAlimentos(this.combinedFood.alimentosAlternativos)
      this.initVectorReceitas(this.combinedFood.receitasObrigatorias)
      this.initVectorReceitas(this.combinedFood.receitasAlternativas)
      this.initVectorListaSubstituicao(this.combinedFood.gruposObrigatorios)
      this.initVectorListaSubstituicao(this.combinedFood.gruposAlternativos)
      this.$bvModal.show(this.idModal)
    },
    loadDataNutrients (id) {
      if (this.micronutrients.length === 0) {
        this.overlay.showOverlay = true
        axios.get(globals().baseApiUrl + '/alimentoscombinados/' + id + '/dadosnutricionais', { showLoader: false, useCompany: true })
          .then(res => {
            this.overlay.showOverlay = false
            this.micronutrients = res.data.totalMicronutrientes.map(elem => {
              elem.valor = this.$options.filters.arrendondamento(elem.valor * this.quantidade)
              return elem
            })
          })
          .catch(globals.showError)
      }
    },
    initVectorAlimentos (vector) {
      for (const key in vector) {
        const alim = vector[key]
        const obj = { id: alim.alimento.idAlimento, descricao: alim.alimento.descricao, medida: this.$options.filters.medida100gramas(alim.medidaDescricaoCompleta) }
        this.itensCombined.push(obj)
      }
    },
    initVectorReceitas (vector) {
      for (const key in vector) {
        const receitaObj = vector[key]
        const obj = { id: receitaObj.receita.idReceita, descricao: receitaObj.receita.nome, medida: this.$options.filters.medida100gramas(receitaObj.medidaDescricaoCompleta) }
        this.itensCombined.push(obj)
      }
    },
    initVectorListaSubstituicao (vector) {
      for (const key in vector) {
        const listaobj = vector[key]
        const obj = { id: listaobj.grupoAlimentoEmpresa.idGrupoAlimentoEmpresa, descricao: listaobj.grupoAlimentoEmpresa.descricao, medida: this.$options.filters.pluralizeReplacementList(listaobj.quantidade) }
        this.itensCombined.push(obj)
      }
    }
  }
}
</script>

<style scoped>

</style>
