
export default {
  getMealsVuewBuild: (state) => state.mealsViewBuild,
  getFoodPlanMout: (state) => state.foodPlanMount,
  getGetView: (state) => state.getView,
  getCurrentIndexPlanFood: (state) => state.currentIndexPlanFood,
  getCurrentPercentInPlanFood: (state) => state.currentPercentInPlanFood,
  getPatientSelect: (state) => state.patientSelectDetail,
  getFoodPlanEdit: (state) => state.foodPlanEdit,
  getFatPercentage: (state) => state.fatPercentageAnthropometry
}
