<template>
  <table class="table table-sm mb-0 mt-2">
    <thead>
    <tr>
      <th class="p-l-20 f-700 f-15">Itens</th>
      <th class="f-700 text-center f-15">Medida</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="item in itemsTable" :key="item.id">
      <td class="p-l-20">{{item.descricao}}</td>
      <td class="text-center">{{item.medida}}</td>
    </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'TableItemsMedida',
  props: ['itemsTable']
}
</script>

<style scoped>

</style>
