<template>
  <b-modal :id="idModal" :size='size'>
    <div slot="modal-title" class="p-b-10">
        <div class="f-22">Adicionar uma nova medida</div>
        <div class="text-muted">{{name}}</div>
      </div>
      <div>
          <div class="row">
            <div class="form-group col-md-5">
              <label for="descricaoMedida" class="f-700 f-15">Medida*</label>
              <input type="text" v-model="params.descricaoMedida" id="descricaoMedida" name="descricaoMedida" class="form-control f-16" placeholder="Ex. colher de sopa, fatia, porção."/>
            </div>
            <div class="form-group col-md-2">
              <label for="qtdMedida" class="f-700 f-15">Quantidade*</label>
              <input type="text" v-model="params.valorQuantidade" id="qtdMedida" name="qtdMedida" class="form-control f-16" placeholder="ex.00.00" v-currency="configMask"/>
            </div>
            <div class="form-group col-md-3">
              <label for="pesoMedida" class="f-700 f-15">Peso (em gramas)*</label>
              <input type="text" v-model="params.valorMedida" id="pesoMedida" name="pesoMedida" class="form-control f-16" placeholder="ex.00.00" v-currency="configMask"/>
            </div>
            <div class="form-group col-md-2">
              <label class="f-700 f-15">Unidade</label>
              <div class="f-19">gramas</div>
            </div>
          </div>
          <div class="p-t-10">
            <span class="f-15 f-700 mr-2">É uma medida líquida?</span>
            <label class="switcher switcher-success">
              <input type="checkbox" class="switcher-input" v-model="params.medidaLiquida">
              <span class="switcher-indicator">
                <span class="switcher-yes"><span class="ion ion-md-checkmark"></span></span>
                <span class="switcher-no"><span class="ion ion-md-close"></span></span>
              </span>
            </label>
          </div>
      </div>
      <slot></slot>
      <template #modal-footer="{ ok, cancel }">
        <button type="button" class="btn btn-outline-success mr-4" @click="cancel"><i class="fas fa-times"></i> Descartar</button>
        <b-button variant="primary" :disabled="isAddButtonDisabled"  @click="save"><i class="far fa-save"></i> Salvar</b-button>
      </template>
  </b-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import globals from '@/globals'
import axios from 'axios'

export default {
  name: 'modalMeasureCreate',
  props: ['idModal', 'idItem', 'name', 'type'],
  data () {
    return {
      params: {
        descricao: '',
        descricaoMedida: '',
        unidade: 'g',
        valorMedida: '',
        valorQuantidade: '',
        medidaLiquida: false
      },
      overlay: globals().configOverlay,
      configMask: globals().defineNumberMask,
      size: 'lg',
      submitted: false
    }
  },
  methods: {
    openModal () {
      this.$bvModal.show(this.idModal)
    },
    save () {
      this.params.valorMedida = this.$options.filters.stringToNumberDecimal(this.params.valorMedida)
      this.params.valorQuantidade = this.$options.filters.stringToNumberDecimal(this.params.valorQuantidade)
      if (this.params.medidaLiquida) {
        this.params.descricao = this.params.descricaoMedida + ' (' + this.params.valorMedida + ' ml)'
      } else {
        this.params.descricao = this.params.descricaoMedida + ' (' + this.params.valorMedida + ' g)'
      }

      let url = ''
      if (this.type === 'FOOD') {
        url = globals().baseApiUrl + '/alimentos/' + this.idItem + '/medidas'
      } else if (this.type === 'RECIPE') {
        url = globals().baseApiUrl + '/receitas/' + this.idItem + '/medidas'
      }
      axios.post(url, this.params, { showLoader: true }).then(res => {
        this.$bvModal.hide(this.idModal)
        globals().showSuccess('Medida adicionada com sucesso!')
        this.$emit('resultCreateMeasure', res.data)
        this.$emit('reloadMeasures')
        this.cleanParams()
      }).catch(globals().showError)
    },
    cleanParams () {
      this.params = {
        descricao: '',
        unidade: 'g',
        valorMedida: '',
        valorQuantidade: '',
        medidaLiquida: false
      }
    }
  },
  validations: {
    descricao: { required }
  },
  computed: {
    isAddButtonDisabled () {
      return !(this.params.descricaoMedida && this.params.valorQuantidade && this.params.valorMedida)
    }
  }
}
</script>

<style scoped>

</style>
