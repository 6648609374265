<template>
  <div>
    <form @submit.prevent="handleSubmit" class="p-t-20">
      <div class="row">
        <div class="form-group col-md-4">
          <label for="nomeAlimento" class="f-700 f-15">Nome do Alimento*</label>
          <input type="text" v-model="alimentoParam.descricao" id="nomeAlimento" name="nomeAlimento" class="form-control f-16" :class="{ 'is-invalid': submitted && $v.alimentoParam.descricao.$error }" placeholder="Informe o nome do alimento"/>
          <div v-if="submitted && $v.alimentoParam.descricao.$error" class="invalid-feedback">
            <span v-if="!$v.alimentoParam.descricao.required">Nome do alimento deve ser informado</span>
            <span v-if="!$v.alimentoParam.descricao.minLength">Nome do alimento deve ter pelo menos 3 caracteres</span>
          </div>
        </div>
        <div class="form-group col-md-4">
          <label for="classificacao" class="f-700 f-15">Classificação*</label>
          <input type="text" v-model="alimentoParam.grupo" id="classificacao" name="classificacao" class="form-control f-16" :class="{ 'is-invalid': submitted && $v.alimentoParam.grupo.$error }" placeholder="Informe uma classificação (ex. produtos bovinos, frutas e sucos, etc."/>
          <div v-if="submitted && $v.alimentoParam.grupo.$error" class="invalid-feedback">
            <span v-if="!$v.alimentoParam.grupo.required">A classificação deve ser informado</span>
            <span v-if="!$v.alimentoParam.grupo.minLength">A classificação deve ter pelo menos 3 caracteres</span>
          </div>
        </div>
        <div class="form-group col-md-4">
          <label for="porcao" class="f-700 f-15">Porção (em gramas)*</label>
          <input :disabled="action === 'EDIT_FOOD'" ref="porcao" type="text" v-model="alimentoParam.porcao" id="porcao" name="porcao" class="form-control f-16" :class="{ 'is-invalid': submitted && $v.alimentoParam.porcao.$error }" placeholder="Informe o valor em gramas" v-currency="configMask"/>
          <div v-if="action === 'NEW_FOOD'">Ao salvar, os valores serão convertidos para 100g</div>
          <div v-if="submitted && $v.alimentoParam.porcao.$error" class="invalid-feedback">
            <span v-if="!$v.alimentoParam.porcao.required">Informe o valor da porção</span>
          </div>
        </div>
      </div>
      <div class="p-t-30">
        <h4>Macronutrientes</h4>
      </div>
      <div class="row">
        <div class="form-group col-md-3 col-sm-4">
          <label for="valorKcal" class="f-700 f-15">Valor energético (Kcal)*</label>
          <input ref="valorKcal" type="text" v-model="alimentoParam.calorias" id="valorKcal" name="valorKcal" class="form-control f-16" :class="{ 'is-invalid': submitted && $v.alimentoParam.calorias.$error }" placeholder="ex.00.00" v-currency="configMask"/>
          <div v-if="submitted && $v.alimentoParam.calorias.$error" class="invalid-feedback">
            <span v-if="!$v.alimentoParam.calorias.required">Informe o valor enegético do alimento.</span>
          </div>
        </div>
        <div class="form-group col-md-3 col-sm-4">
          <label for="carbos" class="f-700 f-15">Carboidratos (g)*</label>
          <input ref="carbos" type="text" v-model="alimentoParam.carboidratos" id="carbos" name="carbos" class="form-control f-16" :class="{ 'is-invalid': submitted && $v.alimentoParam.carboidratos.$error }" placeholder="ex.00.00" v-currency="configMask"/>
          <div v-if="submitted && $v.alimentoParam.carboidratos.$error" class="invalid-feedback">
            <span v-if="!$v.alimentoParam.carboidratos.required">Informe o valor de carboidratos do alimento.</span>
          </div>
        </div>
        <div class="form-group col-md-3 col-sm-4">
          <label for="valorProteinas" class="f-700 f-15">Proteínas (g)*</label>
          <input ref="valorProteinas" type="text" v-model="alimentoParam.proteinas" id="valorProteinas" name="valorProteinas" class="form-control f-16" :class="{ 'is-invalid': submitted && $v.alimentoParam.proteinas.$error }" placeholder="ex.00.00" v-currency="configMask"/>
          <div v-if="submitted && $v.alimentoParam.proteinas.$error" class="invalid-feedback">
            <span v-if="!$v.alimentoParam.proteinas.required">Informe o valor de proteínas do alimento.</span>
          </div>
        </div>
        <div class="form-group col-md-3 col-sm-4">
          <label for="valorLipidios" class="f-700 f-15">Lipídios (g)*</label>
          <input ref="valorLipidios" type="text" v-model="alimentoParam.lipidios" id="valorLipidios" name="valorLipidios" class="form-control f-16" :class="{ 'is-invalid': submitted && $v.alimentoParam.lipidios.$error }" placeholder="ex.00.00" v-currency="configMask"/>
          <div v-if="submitted && $v.alimentoParam.lipidios.$error" class="invalid-feedback">
            <span v-if="!$v.alimentoParam.lipidios.required">Informe o valor de lipídios do alimento.</span>
          </div>
        </div>
      </div>
      <div class="p-t-30">
        <h4>Micronutrientes</h4>
      </div>
      <b-tabs class="nav-tabs-top nav-responsive-xl p-b-20">
        <b-tab title="Principais" :active="tabName === 'principais'" title-link-class="f-15">
          <div class="row p-t-20">
            <div v-for="nutrient in valoresNutrientes.principais" :key="nutrient.idNutriente"  class="col-md-3 col-sm-4 p-t-25" >
              <label :for="nutrient.idNutriente" class="f-700 f-15">{{nutrient.nome}}</label>
              <input :ref="'name' + nutrient.idNutriente" type="text" v-model="nutrient.valor" :id="nutrient.idNutriente" :name="nutrient.idNutriente" class="form-control f-16"  placeholder="ex.00.00" v-currency="configMask"/>
            </div>
          </div>
        </b-tab>
        <b-tab title="Lípidos" :active="tabName === 'lipidios'" title-link-class="f-15">
          <div class="row p-t-20">
            <div v-for="nutrient in valoresNutrientes.gorduras" :key="nutrient.idNutriente"  class="col-md-3 col-sm-4 p-t-25" >
              <label :for="nutrient.idNutriente" class="f-700 f-15">{{nutrient.nome}}</label>
              <input :ref="'name' + nutrient.idNutriente" type="text" v-model="nutrient.valor" :id="nutrient.idNutriente" :name="nutrient.idNutriente" class="form-control f-16"  placeholder="ex.00.00" v-currency="configMask"/>
            </div>
          </div>
        </b-tab>
        <b-tab title="Vitaminas" :active="tabName === 'vitaminas'" title-link-class="f-15">
          <div class="row p-t-20">
            <div v-for="nutrient in valoresNutrientes.vitaminas" :key="nutrient.idNutriente"  class="col-md-3 col-sm-4 p-t-25" >
              <label :for="nutrient.idNutriente" class="f-700 f-15">{{nutrient.nome}}</label>
              <input :ref="'name' + nutrient.idNutriente" type="text" v-model="nutrient.valor" :id="nutrient.idNutriente" :name="nutrient.idNutriente" class="form-control f-16"  placeholder="ex.00.00" v-currency="configMask"/>
            </div>
          </div>
        </b-tab>
        <b-tab title="Minerais" :active="tabName === 'minerais'" title-link-class="f-15">
          <div class="row p-t-20">
            <div v-for="nutrient in valoresNutrientes.minerais" :key="nutrient.idNutriente"  class="col-md-3 col-sm-4 p-t-25" >
              <label :for="nutrient.idNutriente" class="f-700 f-15">{{nutrient.nome}}</label>
              <input :ref="'name' + nutrient.idNutriente" type="text" v-model="nutrient.valor" :id="nutrient.idNutriente" :name="nutrient.idNutriente" class="form-control f-16"  placeholder="ex.00.00" v-currency="configMask"/>
            </div>
          </div>
        </b-tab>
        <b-tab title="Aminoácidos" :active="tabName === 'aminoacidos'" title-link-class="f-15">
          <div class="row p-t-20">
            <div v-for="nutrient in valoresNutrientes.aminoacidos" :key="nutrient.idNutriente"  class="col-md-3 col-sm-4 p-t-25" >
              <label :for="nutrient.idNutriente" class="f-700 f-15">{{nutrient.nome}}</label>
              <input :ref="'name' + nutrient.idNutriente" type="text" v-model="nutrient.valor" :id="nutrient.idNutriente" :name="nutrient.idNutriente" class="form-control f-16"  placeholder="ex.00.00" v-currency="configMask"/>
            </div>
          </div>
        </b-tab>
        <b-tab title="Outros" :active="tabName === 'outros'" title-link-class="f-15">
          <div class="row p-t-20">
            <div v-for="nutrient in valoresNutrientes.outros" :key="nutrient.idNutriente"  class="col-md-3 col-sm-4 p-t-20" >
              <label :for="nutrient.idNutriente" class="f-700 f-15">{{nutrient.nome}}</label>
              <input :ref="'name' + nutrient.idNutriente" type="text" v-model="nutrient.valor" :id="nutrient.idNutriente" :name="nutrient.idNutriente" class="form-control f-16"  placeholder="ex.00.00" v-currency="configMask"/>
            </div>
          </div>
        </b-tab>
      </b-tabs>
      <div class="p-t-30" v-if="type === 'FORM'">
        <button type="button" class="btn btn-outline-success mr-4" @click="backListAlimento"><i class="fas fa-times"></i> Descartar</button>
        <button class="btn btn-primary"><i class="far fa-save"></i> Salvar</button>
      </div>
      <div class="p-t-30" v-if="type === 'MODAL'">
        <div class="text-right">
          <button type="button" class="btn btn-outline-success mr-4" @click="backListAlimento"><i class="fas fa-times"></i> Descartar</button>
          <button class="btn btn-primary"><i class="far fa-save"></i> Salvar</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
import globals from '@/globals'
import { required, minLength } from 'vuelidate/lib/validators'
import buscustom from '@/config/buscustom'

export default {
  name: 'FormFoodInner',
  props: ['foodEdit', 'action', 'type'],
  data () {
    return {
      tabName: 'principais',
      configMask: globals().defineNumberMask,
      idAlimento: '',
      alimentoParam: {
        descricao: '',
        grupo: '',
        porcao: '',
        calorias: '',
        carboidratos: '',
        proteinas: '',
        lipidios: '',
        dadosNutricionais: []
      },
      valoresNutrientes: {
        principais: [],
        gorduras: [],
        vitaminas: [],
        minerais: [],
        aminoacidos: [],
        outros: []
      },
      submitted: false
    }
  },
  validations: {
    alimentoParam: {
      descricao: {
        required,
        minLength: minLength(3)
      },
      grupo: {
        required,
        minLength: minLength(3)
      },
      porcao: { required },
      calorias: { required },
      carboidratos: { required },
      proteinas: { required },
      lipidios: { required }
    }
  },
  methods: {
    loadNutrients () {
      axios.get(globals().baseApiUrl + '/nutrientes', { showLoader: false, useCompany: true })
        .then(res => {
          for (const key in res.data.nutrientes) {
            const objNutriente = res.data.nutrientes[key]
            if (objNutriente.tipoNutriente === 'Lipídios') {
              this.valoresNutrientes.gorduras.push(this.getObjectNutrientEdit(objNutriente))
            } else if (objNutriente.tipoNutriente === 'Principais') {
              this.valoresNutrientes.principais.push(this.getObjectNutrientEdit(objNutriente))
            } else if (objNutriente.tipoNutriente === 'Vitaminas') {
              this.valoresNutrientes.vitaminas.push(this.getObjectNutrientEdit(objNutriente))
            } else if (objNutriente.tipoNutriente === 'Minerais') {
              this.valoresNutrientes.minerais.push(this.getObjectNutrientEdit(objNutriente))
            } else if (objNutriente.tipoNutriente === 'Aminoácidos') {
              this.valoresNutrientes.aminoacidos.push(this.getObjectNutrientEdit(objNutriente))
            } else {
              this.valoresNutrientes.outros.push(this.getObjectNutrientEdit(objNutriente))
            }
          }
          if (this.action === 'EDIT_FOOD') {
            this.loadFoodEdit()
          }
        })
        .catch(globals.showError)
    },
    loadFoodEdit () {
      axios.get(globals().baseApiUrl + '/alimentos/' + this.foodEdit.idAlimento + '/dadosnutricionais', { showLoader: true, useCompany: true })
        .then(res => {
          for (const key in res.data.dadosNutricionais) {
            const objDadoNutricional = res.data.dadosNutricionais[key]
            if (objDadoNutricional.nutriente.idNutriente === '586d6a0503894421f0f96be3') {
              this.alimentoParam.calorias = this.$options.filters.parseDoubleStringDecimal(objDadoNutricional.valor)
            } else if (objDadoNutricional.nutriente.idNutriente === '586d6a0503894421f0f96bac') {
              this.alimentoParam.carboidratos = this.$options.filters.parseDoubleStringDecimal(objDadoNutricional.valor)
            } else if (objDadoNutricional.nutriente.idNutriente === '586d6a0503894421f0f96bd2') {
              this.alimentoParam.proteinas = this.$options.filters.parseDoubleStringDecimal(objDadoNutricional.valor)
            } else if (objDadoNutricional.nutriente.idNutriente === '586d6a0503894421f0f96bc1') {
              this.alimentoParam.lipidios = this.$options.filters.parseDoubleStringDecimal(objDadoNutricional.valor)
            } else {
              this.setValueDadosNutricionaisForEdit(objDadoNutricional)
            }
          }
        })
        .catch(globals.showError)
    },
    getObjectNutrientEdit (objNutriente) {
      return {
        idNutriente: objNutriente.idNutriente,
        nome: objNutriente.nutriente + ' (' + objNutriente.unidade + ')',
        valor: ''
      }
    },
    pushVectorDadosNutricionais (vector) {
      if (vector.length > 0) {
        vector.map(elem => {
          elem.valor = this.$options.filters.stringToNumberDecimal(elem.valor)
          this.alimentoParam.dadosNutricionais.push(elem)
        })
      }
    },
    setValueDadosNutricionaisForEdit (objDadoNutricional) {
      this.valoresNutrientes.principais.filter(elem => elem.idNutriente === objDadoNutricional.nutriente.idNutriente).map(elem => {
        elem.valor = this.$options.filters.parseDoubleStringDecimal(objDadoNutricional.valor)
        return elem
      })
      this.valoresNutrientes.vitaminas.filter(elem => elem.idNutriente === objDadoNutricional.nutriente.idNutriente).map(elem => {
        elem.valor = this.$options.filters.parseDoubleStringDecimal(objDadoNutricional.valor)
        return elem
      })
      this.valoresNutrientes.gorduras.filter(elem => elem.idNutriente === objDadoNutricional.nutriente.idNutriente).map(elem => {
        elem.valor = this.$options.filters.parseDoubleStringDecimal(objDadoNutricional.valor)
        return elem
      })
      this.valoresNutrientes.aminoacidos.filter(elem => elem.idNutriente === objDadoNutricional.nutriente.idNutriente).map(elem => {
        elem.valor = this.$options.filters.parseDoubleStringDecimal(objDadoNutricional.valor)
        return elem
      })
      this.valoresNutrientes.outros.filter(elem => elem.idNutriente === objDadoNutricional.nutriente.idNutriente).map(elem => {
        elem.valor = this.$options.filters.parseDoubleStringDecimal(objDadoNutricional.valor)
        return elem
      })
      this.valoresNutrientes.minerais.filter(elem => elem.idNutriente === objDadoNutricional.nutriente.idNutriente).map(elem => {
        elem.valor = this.$options.filters.parseDoubleStringDecimal(objDadoNutricional.valor)
        return elem
      })
    },
    validateState (item) {
      const { $dirty, $error } = item
      return $dirty ? !$error : null
    },
    handleSubmit (e) {
      this.submitted = true
      this.$v.$touch()
      if (this.$v.alimentoParam.$invalid) {
        return
      }
      this.alimentoParam.dadosNutricionais = []
      this.alimentoParam.porcao = this.$options.filters.stringToNumberDecimal(this.alimentoParam.porcao)
      this.alimentoParam.dadosNutricionais.push({
        idNutriente: '586d6a0503894421f0f96be3',
        valor: this.$options.filters.stringToNumberDecimal(this.alimentoParam.calorias)
      })
      this.alimentoParam.dadosNutricionais.push({
        idNutriente: '586d6a0503894421f0f96bac',
        valor: this.$options.filters.stringToNumberDecimal(this.alimentoParam.carboidratos)
      })
      this.alimentoParam.dadosNutricionais.push({
        idNutriente: '586d6a0503894421f0f96bd2',
        valor: this.$options.filters.stringToNumberDecimal(this.alimentoParam.proteinas)
      })
      this.alimentoParam.dadosNutricionais.push({
        idNutriente: '586d6a0503894421f0f96bc1',
        valor: this.$options.filters.stringToNumberDecimal(this.alimentoParam.lipidios)
      })
      this.pushVectorDadosNutricionais(this.valoresNutrientes.principais.filter(elem => !globals().isEmpty(elem.valor)))
      this.pushVectorDadosNutricionais(this.valoresNutrientes.gorduras.filter(elem => !globals().isEmpty(elem.valor)))
      this.pushVectorDadosNutricionais(this.valoresNutrientes.vitaminas.filter(elem => !globals().isEmpty(elem.valor)))
      this.pushVectorDadosNutricionais(this.valoresNutrientes.minerais.filter(elem => !globals().isEmpty(elem.valor)))
      this.pushVectorDadosNutricionais(this.valoresNutrientes.aminoacidos.filter(elem => !globals().isEmpty(elem.valor)))
      this.pushVectorDadosNutricionais(this.valoresNutrientes.outros.filter(elem => !globals().isEmpty(elem.valor)))

      if (this.action === 'NEW_FOOD') {
        axios.post(globals().baseApiUrl + '/alimentos', this.alimentoParam, { showLoader: true })
          .then(res => {
            globals().showSuccess(res.data.message)
            this.backListAlimento()
            if (this.type === 'MODAL') {
              buscustom.newFoodSave(res.data.alimento)
            }
          })
          .catch(globals().showError)
      } else {
        axios.put(globals().baseApiUrl + '/alimentos/' + this.idAlimento, this.alimentoParam, { showLoader: true })
          .then(res => {
            globals().showSuccess(res.data.message)
            this.$router.push({ name: 'alimentosList' })
          })
          .catch(reason => {
            this.alimentoParam.porcao = this.$options.filters.parseDoubleStringDecimal(100)
            globals().showError(reason)
          })
      }
      this.submitted = false
    },
    getRefByid (idRef) {
      return this.$refs[idRef][0]
    },
    backListAlimento () {
      if (this.type === 'FORM') {
        this.$router.push({ name: 'alimentosList' })
      } else {
        this.$emit('closeModalFood')
      }
    }
  },
  mounted () {
    this.loadNutrients()
    if (this.action === 'EDIT_FOOD') {
      this.idAlimento = this.foodEdit.idAlimento
      this.alimentoParam.descricao = this.foodEdit.descricao
      this.alimentoParam.grupo = this.foodEdit.grupo
      this.alimentoParam.porcao = this.$options.filters.parseDoubleStringDecimal(100)
    }
  }
}
</script>

<style scoped>

</style>
