<template>
  <div>
    <div class="mt-2" v-if="type==='TOTAL_RESUME'">
      <span class="mr-3 text-muted2">Kcal: {{totalMacronutriente.totalKcal}} <span clas="f-13">Kcal</span></span>
      <span class="mr-3 text-muted2">CHO: {{totalMacronutriente.totalCarboidratosGramas}} g</span>
      <span class="mr-3 text-muted2">PTN: {{totalMacronutriente.totalProteinasGramas}} g</span>
      <span class="text-muted2">Lip: {{totalMacronutriente.totalGordurasGramas}} g</span>
    </div>
    <div class="mt-2" v-else>
      <div class="f-18">Total Macronutrientes</div>
      <span class="f-17 mr-3">Calorias: {{totalMacronutriente.totalKcal}} Kcal</span>
      <span class="f-17 mr-3">Carboidratos: {{totalMacronutriente.totalCarboidratosGramas | CHOGToKcal}} Kcal &bull; {{totalMacronutriente.totalCarboidratosGramas}} g</span>
      <span class="f-17 mr-3">Proteínas: {{totalMacronutriente.totalProteinasGramas | PTNGToKcal}} Kcal &bull; {{totalMacronutriente.totalProteinasGramas}} g</span>
      <span class="f-17">Lipídios: {{totalMacronutriente.totalGordurasGramas | LipGToKcal}} Kcal &bull; {{totalMacronutriente.totalGordurasGramas}} g</span>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import globals from '@/globals'

export default {
  name: 'InfoTotalMacronutrients',
  props: ['listFood', 'objMeal', 'totalMacronutriente', 'type'],
  data () {
    return {
      params: {
        alimentos: [],
        grupos: [],
        combinados: [],
        receitas: []
      },
      totalKcal: 0,
      totalCHO: 0,
      totalPTN: 0,
      totalLip: 0
    }
  },
  methods: {
    calculeMacros () {
      this.formatParams()
      axios.post(globals().baseApiUrl + '/nutrientes/macronutrientes/combine', this.params, { showLoader: false })
        .then(res => {
          const somaKcal = res.data.somas.find(soma => soma.idNutriente === globals().idKcalNutrient)
          const somaCHO = res.data.somas.find(soma => soma.idNutriente === globals().idCHONutrient)
          const somaPTN = res.data.somas.find(soma => soma.idNutriente === globals().idPTNNutrient)
          const somaLip = res.data.somas.find(soma => soma.idNutriente === globals().idLipNutrient)
          this.totalMacronutriente.totalKcal = somaKcal.valor
          this.totalMacronutriente.totalCarboidratosGramas = somaCHO.valor
          this.totalMacronutriente.totalProteinasGramas = somaPTN.valor
          this.totalMacronutriente.totalGordurasGramas = somaLip.valor
        })
        .catch(globals.showError)
    },
    formatParams () {
      if (this.listFood) {
        if (this.listFood.length > 0) {
          this.params.alimentos = this.listFood.map((foodCurrent) => {
            const objFood = {
              idAlimento: foodCurrent.alimento.idAlimento,
              idMedida: foodCurrent.medida.idMedida,
              quantidade: foodCurrent.quantidade
            }
            return objFood
          })
        } else {
          this.totalMacronutriente.totalKcal = 0
          this.totalMacronutriente.totalCarboidratosGramas = 0
          this.totalMacronutriente.totalProteinasGramas = 0
          this.totalMacronutriente.totalGordurasGramas = 0
        }
      }
    }
  },
  watch: {
    listFood (newVal, oldVal) {
      this.calculeMacros()
    }
  }
}
</script>
<style scoped>

</style>
