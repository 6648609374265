<template>
  <b-modal :id="idModal" :size='size' ok-only ok-title="Fechar" hide-footer>
    <div slot="modal-title">
      <div class="f-22">Novo Alimento</div>
      <form-food-inner :action="action" :food-edit="foodEdit" type="MODAL"  @closeModalFood="closeModal" @test="emitToField"/>
    </div>
  </b-modal>
</template>

<script>
import FormFoodInner from '@/components/list/foods/FormFoodInner'
export default {
  name: 'modalFormFood',
  components: { FormFoodInner },
  props: ['idModal'],
  data () {
    return {
      size: 'xl',
      action: 'NEW_FOOD',
      foodEdit: null
    }
  },
  methods: {
    openModal () {
      this.$bvModal.show(this.idModal)
    },
    closeModal () {
      this.$bvModal.hide(this.idModal)
    },
    emitToField (test) {
      this.$emit('setfoodsave', test)
    }
  }
}
</script>

<style scoped>

</style>
