<template>
  <div class="pt-3 pb-3">
    <label class="f-700 f-15" :for="field.templateOptions.name">{{  field.templateOptions.label }}</label>
    <input type="text" class="form-control f-16" v-model="model[field.key]" :name=" field.templateOptions.name"/>
  </div>
</template>

<script>
import baseField from 'vue-formly-bootstrap/src/fields/baseField'

export default {
  name: 'input-text-custom',
  mixins: [baseField],
  props: ['form', 'field', 'model', 'to']
}
</script>

<style scoped>

</style>
