import utils from '@/config/utils'
import axios from 'axios'
import globals from '@/globals'

export default {
  addMealsViewBuild (context, payload) {
    context.commit('addMealsViewBuild', payload)
  },
  cleanMealsViewBuild (context, payload) {
    context.commit('cleanMealsViewBuild', payload)
  },
  addPlanFoodInFoodPlanMount (context, payload) {
    context.commit('addPlanFoodInFoodPlanMount', payload)
  },
  cleanFoodPlanInFoodMount (context, payload) {
    context.commit('cleanFoodPlanInFoodMount', payload)
  },
  addItemInMealViewBuild (context, payload) {
    payload.type === 'MEAL_OPTION' ? context.commit('setDisableWithCloseCardMealViewBuildOptional', { indexMeal: payload.index, indexMealOptional: payload.indexMealOptional }) : context.commit('setDisableWithCloseCardMealViewBuild', payload.index)
    switch (payload.obj.type) {
      case 'FOOD': {
        const objFood = utils.getComponentForSaveFood(payload.obj)
        payload.type === 'MEAL_OPTION' ? context.commit('addItemFoodInMealViewBuildOptional', { indexMeal: payload.index, indexMealOptional: payload.indexMealOptional, objFood: objFood }) : context.commit('addItemFoodInMealViewBuild', { indexMeal: payload.index, objFood: objFood })
      }
        break
      case 'RECIPE': {
        const objRecipe = utils.getComponentForSaveRecipe(payload.obj)
        payload.type === 'MEAL_OPTION' ? context.commit('addItemRecipeInMealViewBuildOptional', { indexMeal: payload.index, indexMealOptional: payload.indexMealOptional, objRecipe: objRecipe }) : context.commit('addItemRecipeInMealViewBuild', { indexMeal: payload.index, objRecipe: objRecipe })
      }
        break
      case 'REPLACEMENT': {
        const objReplacement = utils.getComponentForSaveReplacement(payload.obj)
        payload.type === 'MEAL_OPTION' ? context.commit('addItemReplacementInMealViewBuildOptional', { indexMeal: payload.index, indexMealOptional: payload.indexMealOptional, objReplacement: objReplacement }) : context.commit('addItemReplacementInMealViewBuild', { indexMeal: payload.index, objReplacement: objReplacement })
      }
        break
      case 'COMBINED': {
        const objCombined = utils.getComponentForSaveCombined(payload.obj)
        payload.type === 'MEAL_OPTION' ? context.commit('addItemCombinedInMealViewBuildOptional', { indexMeal: payload.index, indexMealOptional: payload.indexMealOptional, objCombined: objCombined }) : context.commit('addItemCombinedInMealViewBuild', { indexMeal: payload.index, objCombined: objCombined })
      }
        break
    }
  },
  async updateTotalMacrosInMealViewBuild (context, payload) {
    const params = payload.type === 'MEAL' ? utils.formatParamsTotalMacros(context.state.mealsViewBuild[payload.index].refeicao) : utils.formatParamsTotalMacros(context.state.mealsViewBuild[payload.index].refeicao.refeicoesOpcionais[payload.indexMealOptional].refeicao)
    axios.post(globals().baseApiUrl + '/nutrientes/macronutrientes/combine', params, { showLoader: false })
      .then(res => {
        const somaKcal = res.data.somas.find(soma => soma.idNutriente === globals().idKcalNutrient)
        const somaCHO = res.data.somas.find(soma => soma.idNutriente === globals().idCHONutrient)
        const somaPTN = res.data.somas.find(soma => soma.idNutriente === globals().idPTNNutrient)
        const somaLip = res.data.somas.find(soma => soma.idNutriente === globals().idLipNutrient)
        if (payload.type === 'MEAL') {
          context.commit('updateTotalMacrosInMealViewBuild', { index: payload.index, somaKcal: somaKcal.valor, somaCHO: somaCHO.valor, somaPTN: somaPTN.valor, somaLip: somaLip.valor })
        } else if (payload.type === 'MEAL_OPTION') {
          context.commit('updateTotalMacrosInMealViewBuildOptional', { indexMeal: payload.index, indexMealOptional: payload.indexMealOptional, somaKcal: somaKcal.valor, somaCHO: somaCHO.valor, somaPTN: somaPTN.valor, somaLip: somaLip.valor })
        }
        this.dispatch('updateTotalMacrosViewBuild')
      })
      .catch(globals.showError)
  },
  updateTotalMacrosViewBuild (context) {
    if (context.state.mealsViewBuild.length > 0) {
      let totalKcal = 0
      let totalCho = 0
      let totalPtn = 0
      let totalLip = 0
      for (let i = 0; i < context.state.mealsViewBuild.length; i++) {
        const objMealView = context.state.mealsViewBuild[i]
        let tempKcal = objMealView.refeicao.totalMacronutriente.totalKcal
        let tempCho = objMealView.refeicao.totalMacronutriente.totalCarboidratosGramas
        let tempPtn = objMealView.refeicao.totalMacronutriente.totalProteinasGramas
        let tempLip = objMealView.refeicao.totalMacronutriente.totalGordurasGramas
        if (objMealView.refeicao.refeicoesOpcionais.length > 0) {
          let sizeOpcionais = 1
          for (let x = 0; x < objMealView.refeicao.refeicoesOpcionais.length; x++) {
            const objMealViewOpt = objMealView.refeicao.refeicoesOpcionais[x]
            tempKcal = tempKcal + objMealViewOpt.totalKcal
            tempCho = tempCho + objMealViewOpt.totalCarboidratosGramas
            tempPtn = tempPtn + objMealViewOpt.totalProteinasGramas
            tempLip = tempLip + objMealViewOpt.totalGordurasGramas
            if (objMealViewOpt.refeicao.alimentos.length > 0 || objMealViewOpt.refeicao.receitas.length > 0 || objMealViewOpt.refeicao.grupos.length > 0 || objMealViewOpt.refeicao.receitas.length > 0) {
              sizeOpcionais += 1
            }
          }
          tempKcal = tempKcal / sizeOpcionais
          tempCho = tempCho / sizeOpcionais
          tempPtn = tempPtn / sizeOpcionais
          tempLip = tempLip / sizeOpcionais
        }
        totalKcal = totalKcal + tempKcal
        totalCho = totalCho + tempCho
        totalPtn = totalPtn + tempPtn
        totalLip = totalLip + tempLip
      }
      context.commit('updateTotalMacrosInPlanFood', { totalKcal: totalKcal, totalCho: totalCho, totalPtn: totalPtn, totalLip: totalLip })
      if (context.state.foodPlanMount.configuracaoApresentacao.valorReferencia) {
        this.dispatch('updatePercentageComparisonReferenceValue')
      }
    }
  },
  updatePercentageComparisonReferenceValue (context) {
    const totalSumPlanFoodMacros = context.state.foodPlanMount.planosAlimentares[context.state.currentIndexPlanFood].totalMacronutriente.totalCarboidratosGramas + context.state.foodPlanMount.planosAlimentares[context.state.currentIndexPlanFood].totalMacronutriente.totalProteinasGramas + context.state.foodPlanMount.planosAlimentares[context.state.currentIndexPlanFood].totalMacronutriente.totalGordurasGramas
    context.state.currentPercentInPlanFood.perCarbo = utils.rounding((100 * context.state.foodPlanMount.planosAlimentares[context.state.currentIndexPlanFood].totalMacronutriente.totalCarboidratosGramas) / totalSumPlanFoodMacros)
    context.state.currentPercentInPlanFood.perProt = utils.rounding((100 * context.state.foodPlanMount.planosAlimentares[context.state.currentIndexPlanFood].totalMacronutriente.totalProteinasGramas) / totalSumPlanFoodMacros)
    context.state.currentPercentInPlanFood.perLip = utils.rounding((100 * context.state.foodPlanMount.planosAlimentares[context.state.currentIndexPlanFood].totalMacronutriente.totalGordurasGramas) / totalSumPlanFoodMacros)
    context.state.currentPercentInPlanFood.perKcal = utils.rounding((100 * context.state.foodPlanMount.planosAlimentares[context.state.currentIndexPlanFood].totalMacronutriente.totalKcal) / context.state.foodPlanMount.planosAlimentares[context.state.currentIndexPlanFood].getPlanoAlimentar.necessidadeEnergetica)

    if (context.state.currentPercentInPlanFood.perKcal <= 100) {
      context.state.currentPercentInPlanFood.colorKcal = 'GREEN'
    } else if (context.state.currentPercentInPlanFood.perKcal > 100) {
      context.state.currentPercentInPlanFood.colorKcal = 'RED'
    }

    const valorCarboPlano = context.state.foodPlanMount.planosAlimentares[context.state.currentIndexPlanFood].totalMacronutriente.totalCarboidratosGramas * 4
    context.state.currentPercentInPlanFood.perCarbo = (valorCarboPlano * context.state.foodPlanMount.planosAlimentares[context.state.currentIndexPlanFood].getPlanoAlimentar.porcentagemCarboidratos) / ((context.state.foodPlanMount.planosAlimentares[context.state.currentIndexPlanFood].getPlanoAlimentar.necessidadeEnergetica * context.state.foodPlanMount.planosAlimentares[context.state.currentIndexPlanFood].getPlanoAlimentar.porcentagemCarboidratos) / 100)
    if (context.state.currentPercentInPlanFood.perCarbo <= context.state.foodPlanMount.planosAlimentares[context.state.currentIndexPlanFood].getPlanoAlimentar.porcentagemCarboidratos) {
      context.state.currentPercentInPlanFood.colorCho = 'GREEN'
    } else if (context.state.currentPercentInPlanFood.perCarbo > context.state.foodPlanMount.planosAlimentares[context.state.currentIndexPlanFood].getPlanoAlimentar.porcentagemCarboidratos) {
      context.state.currentPercentInPlanFood.colorCho = 'RED'
    }

    const valorProtPlano = context.state.foodPlanMount.planosAlimentares[context.state.currentIndexPlanFood].totalMacronutriente.totalProteinasGramas * 4
    context.state.currentPercentInPlanFood.perProt = (valorProtPlano * context.state.foodPlanMount.planosAlimentares[context.state.currentIndexPlanFood].getPlanoAlimentar.porcentagemProteinas) / ((context.state.foodPlanMount.planosAlimentares[context.state.currentIndexPlanFood].getPlanoAlimentar.necessidadeEnergetica * context.state.foodPlanMount.planosAlimentares[context.state.currentIndexPlanFood].getPlanoAlimentar.porcentagemProteinas) / 100)
    if (context.state.currentPercentInPlanFood.perProt <= context.state.foodPlanMount.planosAlimentares[context.state.currentIndexPlanFood].getPlanoAlimentar.porcentagemProteinas) {
      context.state.currentPercentInPlanFood.colorPtn = 'GREEN'
    } else if (context.state.currentPercentInPlanFood.perProt > context.state.foodPlanMount.planosAlimentares[context.state.currentIndexPlanFood].getPlanoAlimentar.porcentagemProteinas) {
      context.state.currentPercentInPlanFood.colorPtn = 'RED'
    }

    const valorGordPlano = context.state.foodPlanMount.planosAlimentares[context.state.currentIndexPlanFood].totalMacronutriente.totalGordurasGramas * 9
    context.state.currentPercentInPlanFood.perLip = (valorGordPlano * context.state.foodPlanMount.planosAlimentares[context.state.currentIndexPlanFood].getPlanoAlimentar.porcentagemLipidios) / ((context.state.foodPlanMount.planosAlimentares[context.state.currentIndexPlanFood].getPlanoAlimentar.necessidadeEnergetica * context.state.foodPlanMount.planosAlimentares[context.state.currentIndexPlanFood].getPlanoAlimentar.porcentagemLipidios) / 100)
    if (context.state.currentPercentInPlanFood.perLip <= context.state.foodPlanMount.planosAlimentares[context.state.currentIndexPlanFood].getPlanoAlimentar.porcentagemLipidios) {
      context.state.currentPercentInPlanFood.colorLip = 'GREEN'
    } else if (context.state.currentPercentInPlanFood.perLip > context.state.foodPlanMount.planosAlimentares[context.state.currentIndexPlanFood].getPlanoAlimentar.porcentagemLipidios) {
      context.state.currentPercentInPlanFood.colorLip = 'RED'
    }

    context.state.currentPercentInPlanFood.perCarbo = isNaN(context.state.currentPercentInPlanFood.perCarbo) ? 0 : context.state.currentPercentInPlanFood.perCarbo
    context.state.currentPercentInPlanFood.perLip = isNaN(context.state.currentPercentInPlanFood.perLip) ? 0 : context.state.currentPercentInPlanFood.perLip
    context.state.currentPercentInPlanFood.perProt = isNaN(context.state.currentPercentInPlanFood.perProt) ? 0 : context.state.currentPercentInPlanFood.perProt
    context.state.currentPercentInPlanFood.perKcal = isNaN(context.state.currentPercentInPlanFood.perKcal) ? 0 : context.state.currentPercentInPlanFood.perKcal
  },
  cloneSearchInCardMeal (context, payload) {
    context.commit('setDisableWithCloseCardMealViewBuild', payload.index)
  },
  cloneSearchInCardMealOptional (context, payload) {
    context.commit('setDisableWithCloseCardMealViewBuildOptional', payload)
  },
  removeItemTableCardMealViewBuild (context, payload) {
    if (payload.type === 'FOOD') {
      const indexFood = payload.typeCard === 'MEAL_OPTION' ? utils.findIndexElementByUuidKey(context.state.mealsViewBuild[payload.index].refeicao.refeicoesOpcionais[payload.indexMealOptional].refeicao.alimentos, payload.uuid) : utils.findIndexElementByUuidKey(context.state.mealsViewBuild[payload.index].refeicao.alimentos, payload.uuid)
      payload.typeCard === 'MEAL_OPTION' ? context.commit('removeItemFoodInMealViewBuildOptional', { indexMeal: payload.index, indexMealOptional: payload.indexMealOptional, indexElement: indexFood }) : context.commit('removeItemFoodInMealViewBuild', { indexMeal: payload.index, indexElement: indexFood })
    } else if (payload.type === 'RECIPE') {
      const indexRecipe = payload.typeCard === 'MEAL_OPTION' ? utils.findIndexElementByUuidKey(context.state.mealsViewBuild[payload.index].refeicao.refeicoesOpcionais[payload.indexMealOptional].refeicao.receitas, payload.uuid) : utils.findIndexElementByUuidKey(context.state.mealsViewBuild[payload.index].refeicao.receitas, payload.uuid)
      payload.typeCard === 'MEAL_OPTION' ? context.commit('removeItemRecipeInMealViewBuildOptional', { indexMeal: payload.index, indexMealOptional: payload.indexMealOptional, indexElement: indexRecipe }) : context.commit('removeItemRecipeInMealViewBuild', { indexMeal: payload.index, indexElement: indexRecipe })
    } else if (payload.type === 'REPLACEMENT') {
      const indexReplacement = payload.typeCard === 'MEAL_OPTION' ? utils.findIndexElementByUuidKey(context.state.mealsViewBuild[payload.index].refeicao.refeicoesOpcionais[payload.indexMealOptional].refeicao.grupos, payload.uuid) : utils.findIndexElementByUuidKey(context.state.mealsViewBuild[payload.index].refeicao.grupos, payload.uuid)
      payload.typeCard === 'MEAL_OPTION' ? context.commit('removeItemReplacementInMealViewBuildOptional', { indexMeal: payload.index, indexMealOptional: payload.indexMealOptional, indexElement: indexReplacement }) : context.commit('removeItemReplacementInMealViewBuild', { indexMeal: payload.index, indexElement: indexReplacement })
    } else if (payload.type === 'COMBINED') {
      const indexCombined = payload.typeCard === 'MEAL_OPTION' ? utils.findIndexElementByUuidKey(context.state.mealsViewBuild[payload.index].refeicao.refeicoesOpcionais[payload.indexMealOptional].refeicao.alimentosCombinados, payload.uuid) : utils.findIndexElementByUuidKey(context.state.mealsViewBuild[payload.index].refeicao.alimentosCombinados, payload.uuid)
      payload.typeCard === 'MEAL_OPTION' ? context.commit('removeItemCombinedInMealViewBuildOptional', { indexMeal: payload.index, indexMealOptional: payload.indexMealOptional, indexElement: indexCombined }) : context.commit('removeItemCombinedInMealViewBuild', { indexMeal: payload.index, indexElement: indexCombined })
    }
    context.dispatch('updateTotalMacrosInMealViewBuild', { index: payload.index, type: payload.typeCard, indexMealOptional: payload.indexMealOptional })
  },
  updateItemTableCardMealViewBuild (context, payload) {
    if (payload.objItem.type === 'FOOD') {
      const vectorFood = payload.type === 'MEAL' ? context.state.mealsViewBuild[payload.index].refeicao.alimentos : context.state.mealsViewBuild[payload.index].refeicao.refeicoesOpcionais[payload.indexMealOptional].refeicao.alimentos
      vectorFood.filter(food => food.uuid === payload.objItem.uuid).map(food => {
        food.medida = payload.objItem.medida
        food.quantidade = payload.objItem.quantidade
        return food
      })
    } else if (payload.objItem.type === 'RECIPE') {
      const vectorRecipe = payload.type === 'MEAL' ? context.state.mealsViewBuild[payload.index].refeicao.receitas : context.state.mealsViewBuild[payload.index].refeicao.refeicoesOpcionais[payload.indexMealOptional].refeicao.receitas
      vectorRecipe.filter(recipe => recipe.uuid === payload.objItem.uuid).map(recipe => {
        recipe.medida = payload.objItem.medida
        recipe.quantidade = payload.objItem.quantidade
        return recipe
      })
    } else if (payload.objItem.type === 'REPLACEMENT') {
      const vectorReplacement = payload.type === 'MEAL' ? context.state.mealsViewBuild[payload.index].refeicao.grupos : context.state.mealsViewBuild[payload.index].refeicao.refeicoesOpcionais[payload.indexMealOptional].refeicao.grupos
      vectorReplacement.filter(food => food.uuid === payload.objItem.uuid).map(food => {
        food.medida = payload.objItem.medida
        food.quantidade = payload.objItem.quantidade
        return food
      })
    } else if (payload.objItem.type === 'COMBINED') {
      const vectorCombined = payload.type === 'MEAL' ? context.state.mealsViewBuild[payload.index].refeicao.alimentosCombinados : context.state.mealsViewBuild[payload.index].refeicao.refeicoesOpcionais[payload.indexMealOptional].refeicao.alimentosCombinados
      vectorCombined.filter(food => food.uuid === payload.objItem.uuid).map(food => {
        food.medida = payload.objItem.medida
        food.quantidade = payload.objItem.quantidade
        return food
      })
    }
    context.dispatch('updateTotalMacrosInMealViewBuild', { index: payload.index, type: payload.type, indexMealOptional: payload.indexMealOptional })
  },
  removeMealsViewBuild (context, payload) {
    context.commit('removeMealViewBuild', payload)
    this.dispatch('updateTotalMacrosViewBuild')
  },
  removeMealsViewBuildOptional (context, payload) {
    context.commit('removeMealOptionalViewBuild', payload)
    this.dispatch('updateTotalMacrosViewBuild')
  },
  copyMealViewBuild (context, payload) {
    context.commit('copyMealViewBuild', payload)
    this.dispatch('updateTotalMacrosViewBuild')
  },
  applyImportDataForMealViewBuild (context, payload) {
    if (payload.isClean) {
      if (payload.type === 'MEAL_OPTION') {
        context.commit('clearMealViewBuildByIndexOptional', payload)
      } else {
        context.commit('clearMealViewBuildByIndex', payload)
      }
    }
    payload.objMeal.alimentos.map(al => {
      const objFood = utils.getFoodForItem(al)
      al.uuid = objFood.uuid
      if (payload.type === 'MEAL_OPTION') {
        context.commit('addItensListViewInMealViewBuildOptional', { indexMeal: payload.indexMeal, indexMealOptional: payload.indexMealOptional, obj: objFood })
        context.commit('addItemFoodInMealViewBuildOptional', { indexMeal: payload.indexMeal, indexMealOptional: payload.indexMealOptional, objFood: al })
      } else {
        context.commit('addItensListViewInMealViewBuild', { indexMeal: payload.indexMeal, obj: objFood })
        context.commit('addItemFoodInMealViewBuild', { indexMeal: payload.indexMeal, objFood: al })
      }
    })
    payload.objMeal.receitas.map(rec => {
      const objRecipe = utils.getRecipeForItem(rec)
      rec.uuid = objRecipe.uuid
      if (payload.type === 'MEAL_OPTION') {
        context.commit('addItensListViewInMealViewBuildOptional', { indexMeal: payload.indexMeal, indexMealOptional: payload.indexMealOptional, obj: objRecipe })
        context.commit('addItemRecipeInMealViewBuildOptional', { indexMeal: payload.indexMeal, indexMealOptional: payload.indexMealOptional, objRecipe: rec })
      } else {
        context.commit('addItensListViewInMealViewBuild', { indexMeal: payload.indexMeal, obj: objRecipe })
        context.commit('addItemRecipeInMealViewBuild', { indexMeal: payload.indexMeal, objRecipe: rec })
      }
    })
    payload.objMeal.grupos.map(repl => {
      const objReplacement = utils.getReplacementForItem(repl)
      repl.uuid = objReplacement.uuid
      if (payload.type === 'MEAL_OPTION') {
        context.commit('addItensListViewInMealViewBuildOptional', { indexMeal: payload.indexMeal, indexMealOptional: payload.indexMealOptional, obj: objReplacement })
        context.commit('addItemReplacementInMealViewBuildOptional', { indexMeal: payload.indexMeal, indexMealOptional: payload.indexMealOptional, objReplacement: repl })
      } else {
        context.commit('addItensListViewInMealViewBuild', { indexMeal: payload.indexMeal, obj: objReplacement })
        context.commit('addItemReplacementInMealViewBuild', { indexMeal: payload.indexMeal, objReplacement: repl })
      }
    })
    payload.objMeal.alimentosCombinados.map(comb => {
      const objCombinedFood = utils.getCombinedForItem(comb)
      comb.uuid = objCombinedFood.uuid
      if (payload.type === 'MEAL_OPTION') {
        context.commit('addItensListViewInMealViewBuildOptional', { indexMeal: payload.indexMeal, indexMealOptional: payload.indexMealOptional, obj: objCombinedFood })
        context.commit('addItemCombinedInMealViewBuildOptional', { indexMeal: payload.indexMeal, indexMealOptional: payload.indexMealOptional, objCombined: comb })
      } else {
        context.commit('addItensListViewInMealViewBuild', { indexMeal: payload.indexMeal, obj: objCombinedFood })
        context.commit('addItemCombinedInMealViewBuild', { indexMeal: payload.indexMeal, objCombined: comb })
      }
    })
    context.commit('updateDataMealByImport', payload)
    if (payload.isClean) {
      this.dispatch('updateTotalMacrosViewBuild')
    }
  },
  updateObjectMealsViewBuildOrder (context, payload) {
    context.commit('updateObjectMealsViewBuildOrder', payload)
  },
  calculationGramPerKilogramWeight (context, payload) {
    if (context.state.foodPlanMount.dadoClinico.peso && context.state.foodPlanMount.planosAlimentares[payload.indexPlanFood].getPlanoAlimentar.necessidadeEnergetica) {
      context.state.foodPlanMount.planosAlimentares[payload.indexPlanFood].getPlanoAlimentar.valorKcalGKg = context.state.foodPlanMount.planosAlimentares[payload.indexPlanFood].getPlanoAlimentar.necessidadeEnergetica / context.state.foodPlanMount.dadoClinico.peso
      context.state.foodPlanMount.planosAlimentares[payload.indexPlanFood].getPlanoAlimentar.valorKcalGKg = +(Math.round(context.state.foodPlanMount.planosAlimentares[payload.indexPlanFood].getPlanoAlimentar.valorKcalGKg + 'e+2') + 'e-2')
      setTimeout(function () {
        context.state.getView.valorKcalGKg = context.state.foodPlanMount.planosAlimentares[payload.indexPlanFood].getPlanoAlimentar.valorKcalGKg.toLocaleString('pt-br', { minimumFractionDigits: 2 })
        if (context.state.foodPlanMount.configuracaoApresentacao.valorReferencia) {
          context.dispatch('updatePercentageComparisonReferenceValue')
        }
      }, 4000)
    }
    if (context.state.foodPlanMount.dadoClinico.peso && context.state.foodPlanMount.planosAlimentares[payload.indexPlanFood].getPlanoAlimentar.necessidadeEnergetica && context.state.foodPlanMount.planosAlimentares[payload.indexPlanFood].getPlanoAlimentar.porcentagemCarboidratos) {
      const kcalCho = (context.state.foodPlanMount.planosAlimentares[payload.indexPlanFood].getPlanoAlimentar.porcentagemCarboidratos * context.state.foodPlanMount.planosAlimentares[payload.indexPlanFood].getPlanoAlimentar.necessidadeEnergetica) / 100
      context.state.foodPlanMount.planosAlimentares[payload.indexPlanFood].getPlanoAlimentar.valorCarboGKg = kcalCho / (context.state.foodPlanMount.dadoClinico.peso * 4)
      context.state.foodPlanMount.planosAlimentares[payload.indexPlanFood].getPlanoAlimentar.valorCarboGKg = +(Math.round(context.state.foodPlanMount.planosAlimentares[payload.indexPlanFood].getPlanoAlimentar.valorCarboGKg + 'e+2') + 'e-2')
      setTimeout(function () {
        context.state.getView.valorCarboGKg = context.state.foodPlanMount.planosAlimentares[payload.indexPlanFood].getPlanoAlimentar.valorCarboGKg.toLocaleString('pt-br', { minimumFractionDigits: 2 })
        if (context.state.foodPlanMount.configuracaoApresentacao.valorReferencia) {
          context.dispatch('updatePercentageComparisonReferenceValue')
        }
      }, 4000)
    }
    if (context.state.foodPlanMount.dadoClinico.peso && context.state.foodPlanMount.planosAlimentares[payload.indexPlanFood].getPlanoAlimentar.necessidadeEnergetica && context.state.foodPlanMount.planosAlimentares[payload.indexPlanFood].getPlanoAlimentar.porcentagemProteinas) {
      const kcalCho = (context.state.foodPlanMount.planosAlimentares[payload.indexPlanFood].getPlanoAlimentar.porcentagemProteinas * context.state.foodPlanMount.planosAlimentares[payload.indexPlanFood].getPlanoAlimentar.necessidadeEnergetica) / 100
      context.state.foodPlanMount.planosAlimentares[payload.indexPlanFood].getPlanoAlimentar.valorPtnGKg = kcalCho / (context.state.foodPlanMount.dadoClinico.peso * 4)
      context.state.foodPlanMount.planosAlimentares[payload.indexPlanFood].getPlanoAlimentar.valorPtnGKg = +(Math.round(context.state.foodPlanMount.planosAlimentares[payload.indexPlanFood].getPlanoAlimentar.valorPtnGKg + 'e+2') + 'e-2')
      setTimeout(function () {
        context.state.getView.valorPtnGKg = context.state.foodPlanMount.planosAlimentares[payload.indexPlanFood].getPlanoAlimentar.valorPtnGKg.toLocaleString('pt-br', { minimumFractionDigits: 2 })
        if (context.state.foodPlanMount.configuracaoApresentacao.valorReferencia) {
          context.dispatch('updatePercentageComparisonReferenceValue')
        }
      }, 4000)
    }
    if (context.state.foodPlanMount.dadoClinico.peso && context.state.foodPlanMount.planosAlimentares[payload.indexPlanFood].getPlanoAlimentar.necessidadeEnergetica && context.state.foodPlanMount.planosAlimentares[payload.indexPlanFood].getPlanoAlimentar.porcentagemLipidios) {
      const kcalCho = (context.state.foodPlanMount.planosAlimentares[payload.indexPlanFood].getPlanoAlimentar.porcentagemLipidios * context.state.foodPlanMount.planosAlimentares[payload.indexPlanFood].getPlanoAlimentar.necessidadeEnergetica) / 100
      context.state.foodPlanMount.planosAlimentares[payload.indexPlanFood].getPlanoAlimentar.valorLipGKg = kcalCho / (context.state.foodPlanMount.dadoClinico.peso * 9)
      context.state.foodPlanMount.planosAlimentares[payload.indexPlanFood].getPlanoAlimentar.valorLipGKg = +(Math.round(context.state.foodPlanMount.planosAlimentares[payload.indexPlanFood].getPlanoAlimentar.valorLipGKg + 'e+2') + 'e-2')
      setTimeout(function () {
        context.state.getView.valorLipGKg = context.state.foodPlanMount.planosAlimentares[payload.indexPlanFood].getPlanoAlimentar.valorLipGKg.toLocaleString('pt-br', { minimumFractionDigits: 2 })
        if (context.state.foodPlanMount.configuracaoApresentacao.valorReferencia) {
          context.dispatch('updatePercentageComparisonReferenceValue')
        }
      }, 4000)
    }
  },
  calculatesEnergyRequirement (context, payload) {
    if (context.state.foodPlanMount.dadoClinico.peso && context.state.foodPlanMount.planosAlimentares[payload.indexPlanFood].getPlanoAlimentar.valorKcalGKg) {
      context.state.foodPlanMount.planosAlimentares[payload.indexPlanFood].getPlanoAlimentar.necessidadeEnergetica = context.state.foodPlanMount.planosAlimentares[payload.indexPlanFood].getPlanoAlimentar.valorKcalGKg * context.state.foodPlanMount.dadoClinico.peso
      context.state.foodPlanMount.planosAlimentares[payload.indexPlanFood].getPlanoAlimentar.necessidadeEnergetica = +(Math.round(context.state.foodPlanMount.planosAlimentares[payload.indexPlanFood].getPlanoAlimentar.necessidadeEnergetica + 'e+2') + 'e-2')
      setTimeout(function () {
        context.state.getView.totalKcal = context.state.foodPlanMount.planosAlimentares[payload.indexPlanFood].getPlanoAlimentar.necessidadeEnergetica.toLocaleString('pt-br', { minimumFractionDigits: 2 })
        if (context.state.foodPlanMount.configuracaoApresentacao.valorReferencia) {
          context.dispatch('updatePercentageComparisonReferenceValue')
        }
      }, 4000)
    }
    if (context.state.foodPlanMount.dadoClinico.peso && context.state.foodPlanMount.planosAlimentares[payload.indexPlanFood].getPlanoAlimentar.valorCarboGKg && context.state.foodPlanMount.planosAlimentares[payload.indexPlanFood].getPlanoAlimentar.necessidadeEnergetica) {
      const kcalCho = context.state.foodPlanMount.planosAlimentares[payload.indexPlanFood].getPlanoAlimentar.valorCarboGKg * context.state.foodPlanMount.dadoClinico.peso * 4
      const perCho = (100 * kcalCho) / context.state.foodPlanMount.planosAlimentares[payload.indexPlanFood].getPlanoAlimentar.necessidadeEnergetica
      context.state.foodPlanMount.planosAlimentares[payload.indexPlanFood].getPlanoAlimentar.porcentagemCarboidratos = +(Math.round(perCho + 'e+2') + 'e-2')
      setTimeout(function () {
        context.state.getView.percentualCarbo = context.state.foodPlanMount.planosAlimentares[payload.indexPlanFood].getPlanoAlimentar.porcentagemCarboidratos.toLocaleString('pt-br', { minimumFractionDigits: 2 })
        if (context.state.foodPlanMount.configuracaoApresentacao.valorReferencia) {
          context.dispatch('updatePercentageComparisonReferenceValue')
        }
      }, 4000)
    }
    if (context.state.foodPlanMount.dadoClinico.peso && context.state.foodPlanMount.planosAlimentares[payload.indexPlanFood].getPlanoAlimentar.valorPtnGKg && context.state.foodPlanMount.planosAlimentares[payload.indexPlanFood].getPlanoAlimentar.necessidadeEnergetica) {
      const kcalPtn = context.state.foodPlanMount.planosAlimentares[payload.indexPlanFood].getPlanoAlimentar.valorPtnGKg * context.state.foodPlanMount.dadoClinico.peso * 4
      const perPtn = (100 * kcalPtn) / context.state.foodPlanMount.planosAlimentares[payload.indexPlanFood].getPlanoAlimentar.necessidadeEnergetica
      context.state.foodPlanMount.planosAlimentares[payload.indexPlanFood].getPlanoAlimentar.porcentagemProteinas = +(Math.round(perPtn + 'e+2') + 'e-2')
      setTimeout(function () {
        context.state.getView.percentualPTN = context.state.foodPlanMount.planosAlimentares[payload.indexPlanFood].getPlanoAlimentar.porcentagemProteinas.toLocaleString('pt-br', { minimumFractionDigits: 2 })
        if (context.state.foodPlanMount.configuracaoApresentacao.valorReferencia) {
          context.dispatch('updatePercentageComparisonReferenceValue')
        }
      }, 4000)
    }
    if (context.state.foodPlanMount.dadoClinico.peso && context.state.foodPlanMount.planosAlimentares[payload.indexPlanFood].getPlanoAlimentar.valorLipGKg && context.state.foodPlanMount.planosAlimentares[payload.indexPlanFood].getPlanoAlimentar.necessidadeEnergetica) {
      const kcalPtn = context.state.foodPlanMount.planosAlimentares[payload.indexPlanFood].getPlanoAlimentar.valorLipGKg * context.state.foodPlanMount.dadoClinico.peso * 9
      const perPtn = (100 * kcalPtn) / context.state.foodPlanMount.planosAlimentares[payload.indexPlanFood].getPlanoAlimentar.necessidadeEnergetica
      context.state.foodPlanMount.planosAlimentares[payload.indexPlanFood].getPlanoAlimentar.porcentagemLipidios = +(Math.round(perPtn + 'e+2') + 'e-2')
      setTimeout(function () {
        context.state.getView.percentualLip = context.state.foodPlanMount.planosAlimentares[payload.indexPlanFood].getPlanoAlimentar.porcentagemLipidios.toLocaleString('pt-br', { minimumFractionDigits: 2 })
        if (context.state.foodPlanMount.configuracaoApresentacao.valorReferencia) {
          context.dispatch('updatePercentageComparisonReferenceValue')
        }
      }, 4000)
    }
  },
  clearDataForPlanFood (context, payload) {
    context.commit('restartPlanFood', payload)
  }
}
