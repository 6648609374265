<template>
  <div class="text-lowercase f-15">
    <span v-if="showPoint" class="pr-2 pb-2"><i class="fa fa-circle f-8 c-black"></i></span>
    <span v-if="medidaDescricao === 'gramas' || medidaDescricao === 'grama'">
       {{qtd * valorMedida | arrendondamento}}  {{medidaDescricao}} de {{nomeAlimento}}
    </span>
    <span v-else>
      {{qtd}} {{medidaDescricao}} de {{nomeAlimento}}
      <span v-if="medidaLiquida">
        ({{qtd * valorMedida | arrendondamento}} ml)
      </span>
      <span v-else>
        ({{qtd * valorMedida | arrendondamento}} g)
      </span>
    </span>
  </div>
</template>

<script>
export default {
  name: 'TextFoodWithMeasure',
  props: ['qtd', 'medidaDescricao', 'nomeAlimento', 'medidaLiquida', 'valorMedida', 'showPoint']
}
</script>

<style scoped>

</style>
