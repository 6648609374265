import axios from 'axios'
import globals from '@/globals'
import router from '@/router'

axios.interceptors.request.use(
  config => {
    const json = localStorage.getItem(globals().userToken)
    const objToken = JSON.parse(json)
    if (objToken) {
      config.headers.Authorization = objToken.token
    }
    const jsonUser = localStorage.getItem(globals().userKey)
    const objUser = JSON.parse(jsonUser)
    if (objUser) {
      config.headers.common.codempresa = objUser.empresa.idEmpresa
    }
    config.headers['Content-Type'] = 'application/json'
    return config
  },
  error => {
    Promise.reject(error)
  })

axios.interceptors.response.use((response) => {
  return response
}, function (error) {
  const originalRequest = error.config
  if (error.response.status === 401 && originalRequest.url === globals().baseApiUrl + '/login/atualizatoken') {
    router.push('/login')
    return Promise.reject(error)
  }
  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true
    const json = localStorage.getItem(globals().userToken)
    const objToken = JSON.parse(json)
    return axios.post(globals().baseApiUrl + '/login/atualizatoken',
      {
        tokenRefresh: objToken.refresh_token
      }).then(res => {
      if (res.status === 200) {
        localStorage.setItem(globals().userToken, JSON.stringify(res.data))
        axios.defaults.headers.Authorization = res.data.token
        return axios(originalRequest)
      }
      // eslint-disable-next-line handle-callback-err
    }).catch(err => {
      router.push('/login')
    })
  }
  return Promise.reject(error)
})
