import axios from 'axios'

export default {
  setUser (state, user) {
    state.user = user
    if (user) {
      axios.defaults.headers.common.Authorization = state.user.token.token
      state.isMenuVisible = true
    } else {
      delete axios.defaults.headers.common.Authorization
      state.isMenuVisible = false
    }
  },
  setToken (state, objToken) {
    state.token = objToken
  },
  setConfiguracaoApresentacaoEscrito (state, value) {
    state.foodPlanMount.configuracaoApresentacao.planoEscrito = value
  },
  addMealsViewBuild (state, payload) {
    state.mealsViewBuild.push(payload)
  },
  cleanMealsViewBuild (state, payload) {
    state.mealsViewBuild = []
  },
  addPlanFoodInFoodPlanMount (state, payload) {
    state.foodPlanMount.planosAlimentares.push(payload)
  },
  cleanFoodPlanInFoodMount (state, payload) {
    state.foodPlanMount.planosAlimentares = []
  },
  setDisableWithCloseCardMealViewBuild (state, payload) {
    state.mealsViewBuild[payload].isDisableAddItem = false
    state.mealsViewBuild[payload].isVisibleSearch = false
  },
  setDisableWithCloseCardMealViewBuildOptional (state, payload) {
    state.mealsViewBuild[payload.indexMeal].refeicao.refeicoesOpcionais[payload.indexMealOptional].isDisableAddItem = false
    state.mealsViewBuild[payload.indexMeal].refeicao.refeicoesOpcionais[payload.indexMealOptional].isVisibleSearch = false
  },
  addItemFoodInMealViewBuild (state, payload) {
    state.mealsViewBuild[payload.indexMeal].refeicao.alimentos.push(payload.objFood)
  },
  addItemFoodInMealViewBuildOptional (state, payload) {
    state.mealsViewBuild[payload.indexMeal].refeicao.refeicoesOpcionais[payload.indexMealOptional].refeicao.alimentos.push(payload.objFood)
  },
  removeItemFoodInMealViewBuild (state, payload) {
    state.mealsViewBuild[payload.indexMeal].refeicao.alimentos.splice(payload.indexElement, 1)
  },
  removeItemFoodInMealViewBuildOptional (state, payload) {
    state.mealsViewBuild[payload.indexMeal].refeicao.refeicoesOpcionais[payload.indexMealOptional].refeicao.alimentos.splice(payload.indexElement, 1)
  },
  addItemRecipeInMealViewBuild (state, payload) {
    state.mealsViewBuild[payload.indexMeal].refeicao.receitas.push(payload.objRecipe)
  },
  addItemRecipeInMealViewBuildOptional (state, payload) {
    state.mealsViewBuild[payload.indexMeal].refeicao.refeicoesOpcionais[payload.indexMealOptional].refeicao.receitas.push(payload.objRecipe)
  },
  removeItemRecipeInMealViewBuild (state, payload) {
    state.mealsViewBuild[payload.indexMeal].refeicao.receitas.splice(payload.indexElement, 1)
  },
  removeItemRecipeInMealViewBuildOptional (state, payload) {
    state.mealsViewBuild[payload.indexMeal].refeicao.refeicoesOpcionais[payload.indexMealOptional].refeicao.receitas.splice(payload.indexElement, 1)
  },
  addItemReplacementInMealViewBuild (state, payload) {
    state.mealsViewBuild[payload.indexMeal].refeicao.grupos.push(payload.objReplacement)
  },
  addItemReplacementInMealViewBuildOptional (state, payload) {
    state.mealsViewBuild[payload.indexMeal].refeicao.refeicoesOpcionais[payload.indexMealOptional].refeicao.grupos.push(payload.objReplacement)
  },
  removeItemReplacementInMealViewBuild (state, payload) {
    state.mealsViewBuild[payload.indexMeal].refeicao.grupos.splice(payload.indexElement, 1)
  },
  removeItemReplacementInMealViewBuildOptional (state, payload) {
    state.mealsViewBuild[payload.indexMeal].refeicao.refeicoesOpcionais[payload.indexMealOptional].refeicao.grupos.splice(payload.indexElement, 1)
  },
  addItemCombinedInMealViewBuild (state, payload) {
    state.mealsViewBuild[payload.indexMeal].refeicao.alimentosCombinados.push(payload.objCombined)
  },
  addItemCombinedInMealViewBuildOptional (state, payload) {
    state.mealsViewBuild[payload.indexMeal].refeicao.refeicoesOpcionais[payload.indexMealOptional].refeicao.alimentosCombinados.push(payload.objCombined)
  },
  removeItemCombinedInMealViewBuild (state, payload) {
    state.mealsViewBuild[payload.indexMeal].refeicao.alimentosCombinados.splice(payload.indexElement, 1)
  },
  removeItemCombinedInMealViewBuildOptional (state, payload) {
    state.mealsViewBuild[payload.indexMeal].refeicao.refeicoesOpcionais[payload.indexMealOptional].refeicao.alimentosCombinados.splice(payload.indexElement, 1)
  },
  updateTotalMacrosInMealViewBuild (state, payload) {
    state.mealsViewBuild[payload.index].refeicao.totalMacronutriente.totalKcal = payload.somaKcal
    state.mealsViewBuild[payload.index].refeicao.totalMacronutriente.totalCarboidratosGramas = payload.somaCHO
    state.mealsViewBuild[payload.index].refeicao.totalMacronutriente.totalProteinasGramas = payload.somaPTN
    state.mealsViewBuild[payload.index].refeicao.totalMacronutriente.totalGordurasGramas = payload.somaLip
  },
  updateTotalMacrosInMealViewBuildOptional (state, payload) {
    state.mealsViewBuild[payload.indexMeal].refeicao.refeicoesOpcionais[payload.indexMealOptional].refeicao.totalMacronutriente.totalKcal = payload.somaKcal
    state.mealsViewBuild[payload.indexMeal].refeicao.refeicoesOpcionais[payload.indexMealOptional].refeicao.totalMacronutriente.totalCarboidratosGramas = payload.somaCHO
    state.mealsViewBuild[payload.indexMeal].refeicao.refeicoesOpcionais[payload.indexMealOptional].refeicao.totalMacronutriente.totalProteinasGramas = payload.somaPTN
    state.mealsViewBuild[payload.indexMeal].refeicao.refeicoesOpcionais[payload.indexMealOptional].refeicao.totalMacronutriente.totalGordurasGramas = payload.somaLip
  },
  updateTotalMacrosInPlanFood (state, payload) {
    state.foodPlanMount.planosAlimentares[state.currentIndexPlanFood].totalMacronutriente.totalKcal = payload.totalKcal
    state.foodPlanMount.planosAlimentares[state.currentIndexPlanFood].totalMacronutriente.totalCarboidratosGramas = payload.totalCho
    state.foodPlanMount.planosAlimentares[state.currentIndexPlanFood].totalMacronutriente.totalProteinasGramas = payload.totalPtn
    state.foodPlanMount.planosAlimentares[state.currentIndexPlanFood].totalMacronutriente.totalGordurasGramas = payload.totalLip
  },
  removeMealViewBuild (state, payload) {
    state.mealsViewBuild.splice(payload.indexMeal, 1)
    state.foodPlanMount.planosAlimentares[payload.indexPlanFood].refeicoes.splice(payload.indexMeal, 1)
  },
  removeMealOptionalViewBuild (state, payload) {
    state.foodPlanMount.planosAlimentares[payload.indexPlanFood].refeicoes[payload.indexMeal].refeicoesOpcionais.splice(payload.indexMealOptional, 1)
  },
  copyMealViewBuild (state, payload) {
    state.mealsViewBuild.splice(payload.indexMeal + 1, 0, payload.refeicaoViewClone)
    state.foodPlanMount.planosAlimentares[payload.indexPlanFood].refeicoes.splice(payload.indexMeal + 1, 0, payload.refeicaoViewClone.refeicao)
  },
  clearMealViewBuildByIndex (state, payload) {
    state.mealsViewBuild[payload.indexMeal].itensListView = []
    state.mealsViewBuild[payload.indexMeal].refeicao.observacao = ''
    state.mealsViewBuild[payload.indexMeal].refeicao.alimentos = []
    state.mealsViewBuild[payload.indexMeal].refeicao.grupos = []
    state.mealsViewBuild[payload.indexMeal].refeicao.alimentosCombinados = []
    state.mealsViewBuild[payload.indexMeal].refeicao.receitas = []
  },
  clearMealViewBuildByIndexOptional (state, payload) {
    state.mealsViewBuild[payload.indexMeal].refeicao.refeicoesOpcionais[payload.indexMealOptional].itensListView = []
    state.mealsViewBuild[payload.indexMeal].refeicao.refeicoesOpcionais[payload.indexMealOptional].refeicao.observacao = ''
    state.mealsViewBuild[payload.indexMeal].refeicao.refeicoesOpcionais[payload.indexMealOptional].refeicao.alimentos = []
    state.mealsViewBuild[payload.indexMeal].refeicao.refeicoesOpcionais[payload.indexMealOptional].refeicao.grupos = []
    state.mealsViewBuild[payload.indexMeal].refeicao.refeicoesOpcionais[payload.indexMealOptional].refeicao.alimentosCombinados = []
    state.mealsViewBuild[payload.indexMeal].refeicao.refeicoesOpcionais[payload.indexMealOptional].refeicao.receitas = []
  },
  addItensListViewInMealViewBuild (state, payload) {
    state.mealsViewBuild[payload.indexMeal].itensListView.push(payload.obj)
  },
  addItensListViewInMealViewBuildOptional (state, payload) {
    state.mealsViewBuild[payload.indexMeal].refeicao.refeicoesOpcionais[payload.indexMealOptional].itensListView.push(payload.obj)
  },
  updateDataMealByImport (state, payload) {
    if (payload.type === 'MEAL_OPTION') {
      state.mealsViewBuild[payload.indexMeal].refeicao.refeicoesOpcionais[payload.indexMealOptional].refeicao.observacao = payload.objMeal.observacao
      state.mealsViewBuild[payload.indexMeal].refeicao.refeicoesOpcionais[payload.indexMealOptional].refeicao.totalMacronutriente.totalCarboidratosGramas = payload.objMeal.totalMacronutriente.totalCarboidratosGramas
      state.mealsViewBuild[payload.indexMeal].refeicao.refeicoesOpcionais[payload.indexMealOptional].refeicao.totalMacronutriente.totalProteinasGramas = payload.objMeal.totalMacronutriente.totalProteinasGramas
      state.mealsViewBuild[payload.indexMeal].refeicao.refeicoesOpcionais[payload.indexMealOptional].refeicao.totalMacronutriente.totalGordurasGramas = payload.objMeal.totalMacronutriente.totalGordurasGramas
      state.mealsViewBuild[payload.indexMeal].refeicao.refeicoesOpcionais[payload.indexMealOptional].refeicao.totalMacronutriente.totalKcal = payload.objMeal.totalMacronutriente.totalKcal
    } else {
      state.mealsViewBuild[payload.indexMeal].refeicao.observacao = payload.objMeal.observacao
      state.mealsViewBuild[payload.indexMeal].refeicao.totalMacronutriente.totalCarboidratosGramas = payload.objMeal.totalMacronutriente.totalCarboidratosGramas
      state.mealsViewBuild[payload.indexMeal].refeicao.totalMacronutriente.totalProteinasGramas = payload.objMeal.totalMacronutriente.totalProteinasGramas
      state.mealsViewBuild[payload.indexMeal].refeicao.totalMacronutriente.totalGordurasGramas = payload.objMeal.totalMacronutriente.totalGordurasGramas
      state.mealsViewBuild[payload.indexMeal].refeicao.totalMacronutriente.totalKcal = payload.objMeal.totalMacronutriente.totalKcal
    }
  },
  updateObjectMealsViewBuildOrder (state, payload) {
    state.mealsViewBuild = payload
  },
  restartPlanFood (state, payload) {
    state.foodPlanMount.idDieta = ''
    state.foodPlanMount.usuario = ''
    state.foodPlanMount.tipo = ''
    state.foodPlanMount.dataInicio = ''
    state.foodPlanMount.dataTermino = ''
    state.foodPlanMount.observacao = ''
    state.foodPlanMount.orientacaoHidratacao = ''
    state.foodPlanMount.get = {
      tipo: 0,
      necessidadeEnergetica: 0,
      porcentagemCarboidratos: 0,
      porcentagemProteinas: 0,
      porcentagemLipidios: 0,
      valorKcalGKg: 0,
      valorCarboGKg: 0,
      valorPtnGKg: 0,
      valorLipGKg: 0
    }
    state.foodPlanMount.dadoClinico = { peso: 0, altura: 0, status: 'NENHUM', massaMagra: 0 }
    state.foodPlanMount.prescricao = { titulo: '', descricao: '', posologia: '', suplementos: [], fitoterapicos: [] }
    state.foodPlanMount.configuracaoApresentacao = {
      planoCalculado: true,
      valorReferencia: true,
      caloriasCalculadas: false,
      visibilidadeMacro: true,
      planoEscrito: false
    }
    state.foodPlanMount.quantidadeDiaLixo = 0
    state.foodPlanMount.planosAlimentares = []
    state.mealsViewBuild = []
    state.currentIndexPlanFood = 0
    state.currentPercentInPlanFood.perKcal = 0
    state.currentPercentInPlanFood.perCarbo = 0
    state.currentPercentInPlanFood.perProt = 0
    state.currentPercentInPlanFood.perLip = 0
    state.currentPercentInPlanFood.colorKcal = ''
    state.currentPercentInPlanFood.colorCho = ''
    state.currentPercentInPlanFood.colorPtn = ''
    state.currentPercentInPlanFood.colorLip = ''
    state.getView.totalKcal = ''
    state.getView.percentualCarbo = ''
    state.getView.percentualPTN = ''
    state.getView.percentualLip = ''
    state.getView.valorKcalGKg = ''
    state.getView.valorCarboGKg = ''
    state.getView.valorPtnGKg = ''
    state.getView.valorLipGKg = ''
  }
}
