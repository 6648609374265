import Vue from 'vue'
import Vuex from 'vuex'
import getters from '@/config/store/getters'
import mutations from '@/config/store/mutations'
import actions from '@/config/store/actions'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    token: null,
    alimentos: null,
    alimentoscombinados: null,
    listassubstituicao: null,
    receitas: null,
    orientacoesNutricionais: null,
    prescricoes: null,
    avisos: null,
    templatesRefeicoes: null,
    templatesPlanosAlimentares: null,
    patientSelectDetail: null,
    foodPlanEdit: null,
    foodPlanMount: {
      idDieta: '',
      usuario: '',
      tipo: '',
      dataInicio: '',
      dataTermino: '',
      observacao: '',
      orientacaoHidratacao: '',
      enviado: false,
      get: {
        tipo: 0,
        necessidadeEnergetica: 0,
        porcentagemCarboidratos: 0,
        porcentagemProteinas: 0,
        porcentagemLipidios: 0,
        valorKcalGKg: 0,
        valorCarboGKg: 0,
        valorPtnGKg: 0,
        valorLipGKg: 0
      },
      dadoClinico: { peso: 0, altura: 0, status: 'NENHUM', massaMagra: 0 },
      prescricao: { titulo: '', descricao: '', posologia: '', suplementos: [], fitoterapicos: [] },
      configuracaoApresentacao: {
        planoCalculado: true,
        valorReferencia: true,
        caloriasCalculadas: false,
        visibilidadeMacro: true,
        planoEscrito: false
      },
      quantidadeDiaLixo: 0,
      planosAlimentares: []
    },
    mealsViewBuild: [],
    currentIndexPlanFood: 0,
    tabPlanFoodActive: '',
    currentPercentInPlanFood: {
      perKcal: 0,
      perCarbo: 0,
      perProt: 0,
      perLip: 0,
      colorKcal: '',
      colorCho: '',
      colorPtn: '',
      colorLip: ''
    },
    getView: {
      totalKcal: '',
      percentualCarbo: '',
      percentualPTN: '',
      percentualLip: '',
      valorKcalGKg: '',
      valorCarboGKg: '',
      valorPtnGKg: '',
      valorLipGKg: ''
    }
  },
  fatPercentageAnthropometry: null,
  getters,
  mutations,
  actions
})
