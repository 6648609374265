import layoutHelpers from '@/layout/helpers.js'
import Vue from 'vue'

export default function () {
  return {
    baseApiUrl: 'https://betaapi.nutrieduc.com.br/api', // https://betaapi.nutrieduc.com.br/v1  //http://localhost:8080/NutrieducApi/api
    userKey: '__knowledge_user',
    userToken: '__knowledge_token',
    userCompany: '__knowledge_company',
    companyTemplatesEvaluation: '__knowledge_templates_evaluation',
    templateHabitosVida: '__templateHabitosVida',
    templateHabitosVida_repostas: '__templateHabitosVidaRespostas',
    idKcalNutrient: '586d6a0503894421f0f96be3',
    idCHONutrient: '586d6a0503894421f0f96bac',
    idPTNNutrient: '586d6a0503894421f0f96bd2',
    idLipNutrient: '586d6a0503894421f0f96bc1',

    // Public url
    publicUrl: process.env.BASE_URL,

    // Layout helpers
    layoutHelpers,

    // Check for RTL layout
    get isRtlMode () {
      return document.documentElement.getAttribute('dir') === 'rtl' ||
        document.body.getAttribute('dir') === 'rtl'
    },

    // Check if IE
    get isIEMode () {
      return typeof document.documentMode === 'number'
    },

    // Check if IE10
    get isIE10Mode () {
      return this.isIEMode && document.documentMode === 10
    },

    // Layout navbar color
    get layoutNavbarBg () {
      return 'white'
    },

    // Layout sidenav color
    get layoutSidenavBg () {
      return 'sidenav-theme'
    },

    // Layout footer color
    get layoutFooterBg () {
      return 'footer-theme'
    },

    get configOverlay () {
      const configOverlay = {
        showOverlay: true,
        overlayBlur: '2px',
        overlayVariant: 'transparent',
        overlayOpacity: 0.85,
        spinnerType: 'border',
        spinnerVariant: 'primary',
        spinnerSize: 'default'
      }
      return configOverlay
    },

    get configEditorText () {
      return {
        customToolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          [{ script: 'sub' }, { script: 'super' }],
          ['blockquote', 'code-block'],
          [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
          [{ align: [] }],
          ['link'],
          ['clean', 'code-block']
        ]
      }
    },

    get defineNumberMask () {
      const optionMask = {
        currency: null,
        distractionFree: {
          hideNegligibleDecimalDigits: false,
          hideCurrencySymbol: false,
          hideGroupingSymbol: false
        }
      }
      return optionMask
    },

    isEmpty (str) {
      return (!str || str.length === 0)
    },

    showError (e) {
      if (e && e.response && e.response.data) {
        Vue.$toast.open({
          message: e.response.data.message,
          type: 'error',
          position: 'top-right',
          dismissible: true
        })
      } else if (typeof e === 'string') {
        Vue.toasted.global.defaultError({ msg: e })
      } else {
        Vue.toasted.global.defaultError({ msg: 'Erro no processamento da requisição' })
      }
    },

    showSuccess (message) {
      Vue.$toast.open({
        message: message,
        type: 'success',
        position: 'top-right',
        dismissible: true
      })
    },

    showWarning (message) {
      Vue.$toast.open({
        message: message,
        type: 'warning',
        position: 'top-right',
        dismissible: true
      })
    },

    showRemoveDialog (message, eventConfirm, eventCancel) {
      const dialogRemove = {
        type: 'warning',
        message: message,
        useConfirmBtn: true,
        customClass: 'f-16',
        customConfirmBtnText: 'Sim, remover',
        customCloseBtnText: 'Não',
        customCloseBtnClass: 'btn btn-outline-success',
        customConfirmBtnClass: 'btn btn-primary mr-3',
        onConfirm: eventConfirm,
        onClose: eventCancel
      }
      return dialogRemove
    },

    toBase64 (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          let encoded = reader.result.toString().replace(/^data:(.*,)?/, '')
          if ((encoded.length % 4) > 0) {
            encoded += '='.repeat(4 - (encoded.length % 4))
          }
          resolve(encoded)
        }
        reader.onerror = error => reject(error)
      })
    },

    getAgeCalculation (birthDate) {
      const objResult = { qtdAno: 0, qtdMes: 0 }
      objResult.qtdMes = Vue.filter('getDateDiff')(birthDate, new Date(), 'meses')
      objResult.qtdAno = Vue.filter('getDateDiff')(birthDate, new Date(), 'anos')
      return objResult
    },

    // Animate scrollTop
    scrollTop (to, duration, element = document.scrollingElement || document.documentElement) {
      if (element.scrollTop === to) return
      const start = element.scrollTop
      const change = to - start
      const startDate = +new Date()

      // t = current time; b = start value; c = change in value; d = duration
      const easeInOutQuad = (t, b, c, d) => {
        t /= d / 2
        if (t < 1) return c / 2 * t * t + b
        t--
        return -c / 2 * (t * (t - 2) - 1) + b
      }

      const animateScroll = () => {
        const currentDate = +new Date()
        const currentTime = currentDate - startDate
        element.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration))
        if (currentTime < duration) {
          requestAnimationFrame(animateScroll)
        } else {
          element.scrollTop = to
        }
      }

      animateScroll()
    }
  }
}
