<template>
  <sidenav :orientation="orientation" :class="curClasses">

    <!-- Inner -->
    <div class="sidenav-inner f-15" :class="{ 'py-1': orientation !== 'horizontal' }">
      <sidenav-router-link icon="ion ion-ios-home" to="/" :exact="true">Home</sidenav-router-link>
      <sidenav-menu icon="fas fa-edit" :active="isMenuActive('/consulta')" :open="isMenuOpen('/consulta')">
        <template slot="link-text">Consultas</template>
        <sidenav-router-link to="/consulta/iniciar">Iniciar consulta</sidenav-router-link>
        <sidenav-router-link to="/consulta/agenda">Agenda</sidenav-router-link>
        <sidenav-router-link to="/consulta/acompanhamento">Acompanhamento</sidenav-router-link>
      </sidenav-menu>
      <sidenav-menu icon="ion ion-md-chatboxes" :active="isMenuActive('/comunicacao')" :open="isMenuOpen('/comunicacao')">
        <template slot="link-text">Comunicação</template>
        <sidenav-router-link to="/comunicacao/avisospacientes">Aviso aos pacientes</sidenav-router-link>
      </sidenav-menu>
      <sidenav-menu icon="ion ion-md-copy" :active="isMenuActive('/modelos')" :open="isMenuOpen('/modelos')">
        <template slot="link-text">Modelos</template>
        <sidenav-router-link to="/modelos/planosalimentares">Planos alimentares</sidenav-router-link>
        <sidenav-router-link to="/modelos/refeicoes">Refeições</sidenav-router-link>
        <sidenav-router-link to="/modelos/prescricoes">Prescrições</sidenav-router-link>
        <sidenav-router-link to="/modelos/orientacoesnutricionais">Orientações nutricionais</sidenav-router-link>
      </sidenav-menu>
      <sidenav-menu icon="far fa-list-alt" :active="isMenuActive('/listas')" :open="isMenuOpen('/listas')">
        <template slot="link-text">Listas</template>
        <sidenav-router-link to="/listas/alimentos">Alimentos</sidenav-router-link>
        <sidenav-router-link to="/listas/alimentoscombinados">Alimentos combinados</sidenav-router-link>
        <sidenav-router-link to="/listas/listassubstituicoes">Substituição</sidenav-router-link>
        <sidenav-router-link to="/listas/receitas">Receitas</sidenav-router-link>
      </sidenav-menu>
      <sidenav-menu icon="ion ion-md-trending-up" :active="isMenuActive('/relatorios')" :open="isMenuOpen('/relatorios')">
        <template slot="link-text">Relatórios</template>
        <sidenav-router-link to="/relatorios/pacientesavaliacoes">Pacientes & Avaliações</sidenav-router-link>
      </sidenav-menu>
      <sidenav-menu icon="fas fa-file-invoice-dollar" :active="isMenuActive('/mensalidades')" :open="isMenuOpen('/mensalidades')">
        <template slot="link-text">Mensalidades</template>
        <sidenav-router-link to="/mensalidades/pendenciasehistorico">Pendências & histórico </sidenav-router-link>
        <sidenav-router-link to="/mensalidades/planoatual">Plano atual</sidenav-router-link>
        <sidenav-router-link to="/mensalidades/formadepagamento">Forma de pagamento</sidenav-router-link>
      </sidenav-menu>
      <sidenav-menu icon="fas fa-users-cog" :active="isMenuActive('/administracao')" :open="isMenuOpen('/administracao')">
        <template slot="link-text">Administração</template>
        <sidenav-router-link to="/administracao/gerenciaclinicas">Gerencia de clínicas</sidenav-router-link>
      </sidenav-menu>
      <sidenav-menu icon="ion ion-md-settings" :active="isMenuActive('/configuracao')" :open="isMenuOpen('/configuracao')">
        <template slot="link-text">Configuração</template>
        <sidenav-router-link to="/configuracao/preferencias">Preferências</sidenav-router-link>
        <sidenav-router-link to="/configuracao/conta">Conta</sidenav-router-link>
        <sidenav-router-link to="/configuracao/clinica">Clínica</sidenav-router-link>
      </sidenav-menu>
      <sidenav-router-link icon="ion ion-logo-youtube" to="/tutorial">Tutoriais</sidenav-router-link>
      <sidenav-router-link icon="fas fa-gift" to="/indique">Indique a Nutrieduc</sidenav-router-link>
      <sidenav-router-link icon="fas fa-sign-out-alt" to="/login">Sair</sidenav-router-link>
    </div>
  </sidenav>

</template>

<script>
import { Sidenav, SidenavLink, SidenavRouterLink, SidenavMenu, SidenavHeader, SidenavBlock, SidenavDivider } from '@/vendor/libs/sidenav'

export default {
  name: 'app-layout-sidenav',
  components: {
    /* eslint-disable vue/no-unused-components */
    Sidenav,
    SidenavLink,
    SidenavRouterLink,
    SidenavMenu,
    SidenavHeader,
    SidenavBlock,
    SidenavDivider
    /* eslint-enable vue/no-unused-components */
  },

  props: {
    orientation: {
      type: String,
      default: 'vertical'
    }
  },

  computed: {
    curClasses () {
      let bg = this.layoutSidenavBg

      if (this.orientation === 'horizontal' && (bg.indexOf(' sidenav-dark') !== -1 || bg.indexOf(' sidenav-light') !== -1)) {
        bg = bg
          .replace(' sidenav-dark', '')
          .replace(' sidenav-light', '')
          .replace('-darker', '')
          .replace('-dark', '')
      }

      return `bg-${bg} ` + (
        this.orientation !== 'horizontal' ? 'layout-sidenav' : 'layout-sidenav-horizontal container-p-x flex-grow-0'
      )
    }
  },

  methods: {
    isMenuActive (url) {
      return this.$route.path.indexOf(url) === 0
    },

    isMenuOpen (url) {
      return this.$route.path.indexOf(url) === 0 && this.orientation !== 'horizontal'
    },

    toggleSidenav () {
      this.layoutHelpers.toggleCollapsed()
    }
  }
}
</script>
