<template>
  <div class="p-l-10 p-b-20 p-t-20" style="overflow: hidden; outline: none; page-break-inside: avoid;">
    <div class="f-15 f-700">
      {{field.templateOptions.label}}
    </div>
    <div v-for="(option, index) in field.options" :key="index">
      <span class="f-15" v-if="option.value === model[field.key || index]">{{option.text}}</span>
    </div>
  </div>
</template>

<script>
import baseField from 'vue-formly-bootstrap/src/fields/baseField'

export default {
  name: 'radio-custom-report',
  mixins: [baseField],
  props: ['form', 'field', 'model', 'to']
}
</script>

<style scoped>

</style>
