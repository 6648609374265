<template>
  <div>
    <table class="table table-sm mb-0">
      <thead>
      <tr>
        <th class="p-l-20 f-700 f-15" width="70%">Itens</th>
      </tr>
      </thead>
      <tbody>
      <tr  v-for="(alimentoConsumo, index) in replacementList.alimentoConsumos" :key="index">
        <td class="p-l-20 pt-2 pb-2 f-15" width="70%">{{alimentoConsumo.medidaDescricaoCompleta}}</td>
      </tr>
      <tr  v-for="(receitaConsumo, index) in replacementList.receitaConsumos" :key="index">
        <td class="p-l-20 pt-2 pb-2 f-15" width="70%">{{receitaConsumo.medidaDescricaoCompleta}}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'TableDetailReplacementList',
  props: ['replacementList', 'title']
}
</script>
<style scoped>

</style>
