<template>
  <div>
    <div class="image-input" :style="{ 'background-image': `url(${imageData})` }" @click="chooseImage" v-if="type === 'SIGNATURE'" style="width: 250px; height: 150px; line-height:150px">
      <span v-if="!imageData" class="placeholder">
        {{title}}
      </span>
      <input class="file-input" ref="fileInput" type="file" @input="onSelectFile">
    </div>
    <div class="image-input" :style="{ 'background-image': `url(${imageData})` }" @click="chooseImage" v-else>
      <span v-if="!imageData" class="placeholder">
        {{title}}
      </span>
      <input class="file-input" ref="fileInput" type="file" @input="onSelectFile">
    </div>
    <div class="text-center p-t-5">
      <button v-if="!imageData" class="btn btn-success btn-sm" @click="chooseImage">Selecione</button>
      <button v-if="imageData" class="btn btn-success btn-sm" @click="chooseImage">Atualizar</button>
      <button v-if="imageData" class="btn btn-danger btn-sm" @click="removeImage">Remover</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputImage',
  props: ['title', 'type'],
  data () {
    return {
      imageData: null
    }
  },
  methods: {
    chooseImage () {
      this.$refs.fileInput.click()
    },
    removeImage () {
      this.imageData = null
    },
    loadPreviewImage (urlImage) {
      this.imageData = urlImage
    },
    onSelectFile () {
      const input = this.$refs.fileInput
      const files = input.files
      if (files && files[0]) {
        const reader = new FileReader()
        reader.onload = e => {
          this.imageData = e.target.result
        }
        reader.readAsDataURL(files[0])
        this.$emit('input', files[0])
      }
    }
  }
}
</script>

<style scoped>
  .image-input{
    display: block;
    width: 200px;
    height: 200px;
    cursor: pointer;
    background-size: cover;
    background-position: center center;
  }

  .placeholder {
    background: #F0F0F0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #333;
    font-size: 18px;
  }

  .placeholder:hover {
    background: #E0E0E0
  }

  .file-input {
    display: none
  }
</style>
