<template>
  <b-modal :id="idModal" :size='size' ok-only ok-title="Fechar" hide-footer no-close-on-backdrop >
    <div slot="modal-title">
      <div class="f-22">Nova Receita</div>
    </div>
    <div>
      <FormRecipeInner :action="action" :recipe-edit="recipeEdit" type="MODAL" @closeModalRecipe="closeModal" location="IN_FORM_RECIPE"/>
    </div>
  </b-modal>
</template>
<script>
import FormRecipeInner from '@/components/list/recipe/FormRecipeInner'
export default {
  name: 'modalFormRecipe',
  components: { FormRecipeInner },
  props: ['idModal'],
  data () {
    return {
      size: 'xl',
      action: 'NEW_RECIPE',
      recipeEdit: null
    }
  },
  methods: {
    openModal () {
      this.$bvModal.show(this.idModal)
    },
    closeModal () {
      this.$bvModal.hide(this.idModal)
    }
  }
}
</script>

<style scoped>

</style>
