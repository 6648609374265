<template>
  <div class="pt-3 pb-3">
    <div class="pb-2 f-700 f-16">{{ field.templateOptions.label }}</div>
    <b-radio-group v-model="model[field.key]" :options="field.options" />
  </div>
</template>

<script>
import baseField from 'vue-formly-bootstrap/src/fields/baseField'

export default {
  name: 'radio-custom',
  mixins: [baseField],
  props: ['form', 'field', 'model', 'to']
}
</script>

<style scoped>

</style>
