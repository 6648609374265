<template>
  <div class="table-responsive p-t-20">
    <table class="table table-sm mb-0">
      <thead>
      <tr>
        <th class="p-l-20 f-700 f-15">Informações nutricionais</th>
        <th class="f-700 text-center f-15">por porção</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="nutrient in sortedArrayNutrients" :key="nutrient.idDadoNutricional">
        <td class="p-l-20">{{nutrient.nomeOrder}}</td>
        <td class="text-center">{{nutrient.valor}} {{nutrient.nutriente.unidade}}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'TableNutrients',
  props: ['nutrients'],
  computed: {
    sortedArrayNutrients: function () {
      return [...this.nutrients].sort(function (a, b) {
        return ('' + a.nomeOrder).localeCompare(b.nomeOrder)
      })
    }
  }
}
</script>

<style scoped>

</style>
