<template>
  <div class="authentication-wrapper authentication-3">
    <div class="authentication-inner">

      <!-- Side container -->
      <!-- Do not display the container on extra small, small and medium screens -->
      <div class="d-none d-lg-flex col-lg-8 align-items-center ui-bg-cover ui-bg-overlay-container p-5" :style="`background-image: url('${publicUrl}vendor/img/bg-login.png');`">
        <div class="ui-bg-overlay bg-dark opacity-50"></div>
        <div class="w-100 text-white px-5">
          <h1 class="display-2 font-weight-bolder mb-4">Nutrieduc<br>Clínica</h1>
          <div class="text-large font-weight-light">
            Menos tempo gerindo seu negócio. Mais tempo para seus pacientes.<br/>
            Desde a avaliação ao acompanhamento da dieta, gerencie seus atendimentos de forma rápida e integrada com seus pacientes.
          </div>
        </div>
      </div>
      <!-- / Side container -->

      <!-- Form container -->
      <div class="theme-bg-white d-flex col-lg-4 align-items-center p-5">
        <!-- Inner container -->
        <!-- Have to add `.d-flex` to control width via `.col-*` classes -->
        <div class="d-flex col-sm-7 col-md-5 col-lg-12 px-0 px-xl-4 mx-auto">
          <div class="w-100">

            <!-- Logo -->
            <div class="d-flex justify-content-center align-items-center">
              <div>
                <div class="w-100 position-relative">
                  <img :src="`${publicUrl}vendor/img/logo/logo-verde-nutrieduc.png`">
                </div>
              </div>
            </div>
            <!-- / Logo -->

            <h4 class="text-center text-lighter font-weight-normal p-t-10 mb-0">Nutrieduc Clínica</h4>

            <!-- Form -->
            <form class="my-5">
              <b-form-group label="Email">
                <b-input v-model="credentials.username" />
              </b-form-group>
              <b-form-group>
                <div slot="label" class="d-flex justify-content-between align-items-end">
                  <div>Senha</div>
                  <a href="javascript:void(0)" class="d-block small">Esqueceu sua senha?</a>
                </div>
                <b-input type="password" v-model="credentials.password" />
              </b-form-group>

              <div class="d-flex justify-content-between align-items-center m-0">
                <b-check class="m-0">Continuar conectado</b-check>
                <b-btn variant="primary" @click="signin">Entrar</b-btn>
              </div>
            </form>
            <!-- / Form -->

            <div class="text-center text-muted">
              Ainda não tem uma conta Nutrieduc Clínica? <a href="javascript:void(0)">Clique aqui</a>
            </div>

          </div>
        </div>
      </div>
      <!-- / Form container -->

    </div>
  </div>
</template>

<!-- Page -->
<style src="@/vendor/styles/pages/authentication.scss" lang="scss"></style>

<script>
import axios from 'axios'
import globals from '@/globals'

export default {
  name: 'pages-authentication-login-v3',
  metaInfo: {
    title: 'Login v3 - Pages'
  },
  data: function () {
    return {
      credentials: {
        username: '',
        password: '',
        sistemaId: 3
      }
    }
  },
  methods: {
    signin () {
      axios.post(globals().baseApiUrl + '/login/sign', this.credentials, { showLoader: false })
        .then(res => {
          this.$store.commit('setUser', res.data)
          this.$store.commit('setToken', res.data.token)
          localStorage.setItem(globals().userKey, JSON.stringify(res.data))
          localStorage.setItem(globals().companyTemplatesEvaluation, JSON.stringify(res.data.avaliacoes))
          localStorage.setItem(globals().userToken, JSON.stringify(res.data.token))
          localStorage.setItem(globals().companyId, JSON.stringify(res.data.empresa))
          this.$router.push({ path: '/' })
        })
        .catch(globals().showError)
    }
  }
}
</script>
