<template>
  <div>
    <div>
      <div v-if="title" class="text-center f-16 mb-2">
        {{title | medida100gramas}}
      </div>
      <table class="table table-sm mb-0">
        <thead>
        <tr>
          <th class="p-l-20 f-700 f-15" width="70%">Macronutrientes</th>
          <th class="f-700 text-center f-15" v-if="type === 'MEDIA'">Valor em média</th>
          <th class="f-700 text-center f-15" v-else>Valor total</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td class="p-l-20" width="70%">Calorias</td>
          <td class="text-center">{{macros.totalKcal | arrendondamento }} Kcal</td>
        </tr>
        <tr>
          <td class="p-l-20" width="70%">Carboidratos</td>
          <td class="text-center">{{macros.totalCarboidratosGramas | arrendondamento }} g</td>
        </tr>
        <tr>
          <td class="p-l-20" width="70%">Proteínas</td>
          <td class="text-center">{{macros.totalProteinasGramas | arrendondamento }} g</td>
        </tr>
        <tr>
          <td class="p-l-20" width="70%">Lipídios</td>
          <td class="text-center">{{macros.totalGordurasGramas | arrendondamento }} g</td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="mt-2">
      <table class="table table-sm mb-0">
        <thead>
        <tr>
          <th class="p-l-20 f-700 f-15" width="70%">Micronutrientes</th>
          <th class="f-700 text-center f-15" v-if="type === 'MEDIA'">Valor em média</th>
          <th class="f-700 text-center f-15" v-else>Valor total</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="micro in micros" :key="micro.idNutriente">
          <td class="p-l-20" width="70%">{{micro.nutriente.nutriente}}</td>
          <td class="text-center">{{micro.valor}} {{micro.nutriente.unidade}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TableMacroMicroNutrients',
  props: ['macros', 'micros', 'type', 'title']
}
</script>

<style scoped>

</style>
