<template>
  <b-modal :id="idModal" :size='size' ok-only ok-title="Fechar" hide-footer>
    <div slot="modal-title">
      <div class="f-22">{{recipe.nome}}</div>
    </div>
    <div>
      <div>
        <b-tabs class="nav-tabs-top nav-responsive-xl p-t-5">
          <b-tab title="Receita" title-link-class="f-15">
            <div class="mt-3">
              <div v-if="recipe.foto" class="mb-3">
                <img :src="recipe.foto" style="width: 210px; height: 150px; line-height:150px"/>
              </div>
              <div class="f-16">
                <span class="f-700 f-17">Ingrediente</span>
                <div v-for="ingrediente in recipe.ingredientes" :key="ingrediente.alimento.idAlimento">
                  <div class="p-t-5">
                    {{ingrediente.medidaDescricaoCompleta}}
                  </div>
                </div>
                <div class="p-t-30">
                  <span class="f-17 f-700">Modo de Preparo</span>
                  <div class="p-t-5">
                    <div v-html="recipe.modoPreparo"></div>
                  </div>
                </div>
                <div v-if="recipe.porcoes>0" class="p-t-30">
                  <span class="f-17 f-700">Porção</span>
                  <div class="p-t-5" v-if="recipe.porcoes>1">
                    <span>Esta receita rende: {{recipe.porcoes}} porções</span>
                  </div>
                  <div class="p-t-5" v-else>
                    <span>Esta receita rende: {{recipe.porcoes}} porção</span>
                  </div>
                </div>
                <div v-if="recipe.linkYoutube" class="p-t-30">
                  <span class="f-17 f-700">Link do Youtube</span>
                  <div class="p-t-5">
                    <a :href="recipe.linkYoutube" target="_blank">{{recipe.linkYoutube}}</a>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab title="Tabela nutricional" title-link-class="f-15" @click="loadDataNutrients(recipe.idReceita)">
            <b-overlay id="overTabsFood" :show="overlay.showOverlay" :variant="overlay.overlayVariant" :blur="overlay.overlayBlur" :spinner-variant="overlay.spinnerVariant" :spinner-type="overlay.spinnerType" :opacity="overlay.overlayOpacity" rounded="sm">
              <table-macro-micro-nutrients :macros="macronutrients" :micros="micronutrients" type="TOTAL" :title="titleForMicros" class="mt-3"/>
            </b-overlay>
          </b-tab>
        </b-tabs>
      </div>
      <div class="text-right">
        <button type="button" class="btn btn-outline-success mr-4" @click="printRecipe"><i class="fas fa-print"></i> Imprimir</button>
        <button class="btn btn-primary" @click="closeModal"><i class="fas fa-times"></i> Fechar</button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import axios from 'axios'
import globals from '@/globals'
import TableMacroMicroNutrients from '@/components/ui/TableMacroMicroNutrients'

export default {
  name: 'modalRecipeInfo',
  components: { TableMacroMicroNutrients },
  props: ['idModal'],
  data () {
    return {
      size: null,
      overlay: globals().configOverlay,
      recipe: '',
      measure: '',
      quantidade: 1,
      macronutrients: {
        totalKcal: 0,
        totalCarboidratosGramas: 0,
        totalProteinasGramas: 0,
        totalGordurasGramas: 0
      },
      micronutrients: [],
      titleForMicros: ''
    }
  },
  methods: {
    openModal (recipe, measure, qtd) {
      this.recipe = recipe
      this.measure = measure
      this.quantidade = qtd
      this.titleForMicros = this.measure === null ? '100 gramas' : this.$options.filters.formatMedida(this.measure, this.quantidade)
      this.$bvModal.show(this.idModal)
    },
    loadDataNutrients (id) {
      this.overlay.showOverlay = true
      let param = {}
      if (this.measure !== null) {
        param = { idMedida: this.measure.idMedida, quantidade: this.quantidade }
      }
      axios.get(globals().baseApiUrl + '/receitas/' + id + '/dadosnutricionais', { params: param, showLoader: false })
        .then(res => {
          this.overlay.showOverlay = false
          this.macronutrients.totalKcal = res.data.dadosNutricionais.find(obj => obj.nutriente.idNutriente === globals().idKcalNutrient).valor
          this.macronutrients.totalCarboidratosGramas = res.data.dadosNutricionais.find(obj => obj.nutriente.idNutriente === globals().idCHONutrient).valor
          this.macronutrients.totalProteinasGramas = res.data.dadosNutricionais.find(obj => obj.nutriente.idNutriente === globals().idPTNNutrient).valor
          this.macronutrients.totalGordurasGramas = res.data.dadosNutricionais.find(obj => obj.nutriente.idNutriente === globals().idLipNutrient).valor
          this.micronutrients = res.data.dadosNutricionais
        })
        .catch(globals.showError)
    },
    printRecipe () {
      window.open(globals().baseApiUrl + '/receitas/' + this.recipe.idReceita + '/pdf', '_blank')
    },
    closeModal () {
      this.$bvModal.hide(this.idModal)
    }
  }
}
</script>

<style scoped>

</style>
