// Additional polyfills
import 'custom-event-polyfill'
import 'url-polyfill'
import Vue from 'vue'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'
import VueMask from 'di-vue-mask'
import money from 'v-money'

import App from './App'
import store from './config/store'
import router from './router'
import BootstrapVue from 'bootstrap-vue'
import globals from './globals'
import Popper from 'popper.js'
import './config/axios'
import './filters/filter'
import Vuelidate from 'vuelidate'
import VueCurrencyInput from 'vue-currency-input'
import VueTheMask from 'vue-the-mask'
import { Simplert } from 'vue2-simplert-plugin'
import 'vue2-simplert-plugin/dist/vue2-simplert-plugin.min.css'
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import SearchItem from '@/components/ui/SearchItem'
import VueCountryCode from 'vue-country-code-select'
import VueMaskDirective from 'vue-mask-directive'
import moment from 'moment'
import VueFormly from 'vue-formly'
import VueFormlyBootstrap from 'vue-formly-bootstrap'
import radioCustom from '@/components/ui/formly/radio-custom'
import inputTextCustom from '@/components/ui/formly/input-text-custom'
import radioCustomReport from '@/components/ui/formly/radio-custom-report'
import inputTextCustomReport from '@/components/ui/formly/input-text-custom-report'

// Required to enable animations on dropdowns/tooltips/popovers
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false

Vue.config.productionTip = false
Vue.prototype.moment = moment

Vue.use(BootstrapVue)
Vue.use(VueToast)
Vue.use(VueMask)
Vue.use(Vuelidate)
Vue.use(VueCurrencyInput)
Vue.use(Simplert)
Vue.use(VueCountryCode)
Vue.use(VueMaskDirective)
Vue.use(VueTheMask)
Vue.use(money, { precision: 4 })
Vue.use(VueFormly)
Vue.use(VueFormlyBootstrap)
Vue.component('vue-bootstrap-typeahead', VueBootstrapTypeahead)
Vue.component('SearchItem', SearchItem)

VueFormly.addType('radio-custom', radioCustom)
VueFormly.addType('input-text-custom', inputTextCustom)
VueFormly.addType('radio-custom-report', radioCustomReport)
VueFormly.addType('input-text-custom-report', inputTextCustomReport)

// Global RTL flag
Vue.mixin({
  data: globals
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
