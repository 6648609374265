<template>
  <div>
    <PageTitle main="Administração"/>
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle'

export default {
  name: 'AdminPages',
  components: { PageTitle }
}
</script>

<style scoped>

</style>
