<template>
  <b-modal :id="idModal" :size='size' ok-only ok-title="Fechar">
    <div slot="modal-title">
      <div class="f-22">Nutrientes</div>
    </div>
    <div>
      <table-macro-micro-nutrients :macros="macronutrients" :micros="micronutrients" :type="type" :title="titleForMicros" />
    </div>
  </b-modal>
</template>

<script>
import TableMacroMicroNutrients from '@/components/ui/TableMacroMicroNutrients'

export default {
  name: 'modalDetailTotalNutrients',
  components: { TableMacroMicroNutrients },
  props: ['idModal', 'type'],
  data () {
    return {
      size: null,
      macronutrients: [],
      micronutrients: [],
      titleForMicros: ''
    }
  },
  methods: {
    openModal (macros, micros, title) {
      this.macronutrients = macros
      this.micronutrients = micros
      this.titleForMicros = title
      this.$bvModal.show(this.idModal)
    },
    defineType (type) {

    }
  }
}
</script>

<style scoped>

</style>
