import { render, staticRenderFns } from "./FormFoodInner.vue?vue&type=template&id=0106af19&scoped=true&"
import script from "./FormFoodInner.vue?vue&type=script&lang=js&"
export * from "./FormFoodInner.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0106af19",
  null
  
)

export default component.exports