import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
import NotFound from '@/components/NotFound'
import AdminPages from '@/components/admin/AdminPages'

import globals from '@/globals'

// Layouts
import Layout1 from '@/layout/Layout1'
import AuthenticationLoginV3 from '@/components/auth/AuthenticationLoginV3'

Vue.use(Router)
Vue.use(Meta)

const router = new Router({
  base: '/',
  mode: 'history',
  routes: [{
    path: '/',
    component: Layout1,
    children: [{
      path: '',
      component: () => import('@/components/Home')
    }, {
      name: 'iniciarConsulta',
      path: '/consulta/iniciar',
      component: () => import('@/components/consult/startConsultation/Patients')
    }, {
      name: 'novoPaciente',
      path: '/consulta/iniciar/novopaciente',
      component: () => import('@/components/consult/startConsultation/newPatient/NewPatient')
    }, {
      name: 'painelPaciente',
      path: '/consulta/iniciar/painelpaciente',
      component: () => import('@/components/consult/startConsultation/detailsPatient/panelPatient'),
      props: true
    }, {
      name: 'painelPlanoAlimentar',
      path: '/consulta/iniciar/painelpaciente/planoalimentar',
      component: () => import('@/components/consult/startConsultation/detailsPatient/foodPlan/PanelFoodPlan'),
      props: true
    }, {
      name: 'painelAnamneses',
      path: '/consulta/iniciar/painelpaciente/anamenses',
      component: () => import('@/components/consult/startConsultation/detailsPatient/anamnesis/panelAnamnesis'),
      props: true
    }, {
      name: 'painelAntropometria',
      path: '/consulta/iniciar/painelpaciente/antropometria',
      component: () => import('@/components/consult/startConsultation/detailsPatient/anthropometry/panelAnthropometry'),
      props: true
    }, {
      name: 'painelHabitosVida',
      path: '/consulta/iniciar/painelpaciente/habitosvida',
      component: () => import('@/components/consult/startConsultation/detailsPatient/lifeHabits/panelLifeHabits'),
      props: true
    }, {
      name: 'painelRecibos',
      path: '/consulta/iniciar/painelpaciente/recibos',
      component: () => import('@/components/consult/startConsultation/detailsPatient/receipts/panelReceipts'),
      props: true
    }, {
      name: 'painelRecodatorioAlimentar',
      path: '/consulta/iniciar/painelpaciente/recordatorioalimentar',
      component: () => import('@/components/consult/startConsultation/detailsPatient/dietaryRecall/panelDietaryRecall'),
      props: true
    }, {
      name: 'painelExameLaboratorial',
      path: '/consulta/iniciar/painelpaciente/examelaboratorial',
      component: () => import('@/components/consult/startConsultation/detailsPatient/laboratoryExams/panelLaboratoryExams'),
      props: true
    }, {
      name: 'painelFichaProntuario',
      path: '/consulta/iniciar/painelpaciente/prontuario',
      component: () => import('@/components/consult/startConsultation/detailsPatient/medicalRecord/panelMedicalRecord'),
      props: true
    }, {
      name: 'agenda',
      path: '/consulta/agenda',
      component: () => import('@/components/consult/schedule/PanelSchedule')
    }, {
      name: 'acompanhamento',
      path: '/consulta/acompanhamento',
      component: () => import('@/components/consult/followup/PanelFollowUp')
    }, {
      name: 'detalhesAcompanhamento',
      path: '/consulta/acompanhamento/detalhes',
      component: () => import('@/components/consult/followup/DetailPanelFollowUp'),
      props: true
    }, {
      name: 'comunicacaoAvisosPacientes',
      path: '/comunicacao/avisospacientes',
      component: () => import('@/components/communication/patientWarnings/PanelPatientWarnings')
    }, {
      name: 'alimentosList',
      path: '/listas/alimentos',
      component: () => import('@/components/list/foods/Foods')
    }, {
      name: 'listaAlimentosCombinados',
      path: '/listas/alimentoscombinados',
      component: () => import('@/components/list/combinedFoods/CombinedFoods')
    }, {
      name: 'formAlimentosCombinados',
      path: '/listas/alimentoscombinados/formalimentoscombinados',
      component: () => import('@/components/list/combinedFoods/FormCombinedFoods'),
      props: true
    }, {
      name: 'listaSubstituicaoList',
      path: '/listas/listassubstituicoes',
      component: () => import('@/components/list/replacementList/ReplacementList')
    }, {
      name: 'formListaSubstituicao',
      path: '/listas/listassubstituicoes/novalistasubstituicao',
      component: () => import('@/components/list/replacementList/FormReplacementList'),
      props: true
    }, {
      name: 'receitasList',
      path: '/listas/receitas',
      component: () => import('@/components/list/recipe/Recipes')
    }, {
      name: 'medidasAlimentos',
      path: '/listas/alimentos/medidas',
      component: () => import('@/components/list/foods/TableMeasures'),
      props: true
    }, {
      name: 'formAlimento',
      path: '/listas/alimentos/novoalimento',
      component: () => import('@/components/list/foods/FormFood'),
      props: true
    }, {
      name: 'medidasReceitas',
      path: '/listas/receitas/medidas',
      component: () => import('@/components/list/foods/TableMeasures'),
      props: true
    }, {
      name: 'formReceita',
      path: '/listas/receitas/novareceita',
      component: () => import('@/components/list/recipe/FormRecipe'),
      props: true
    }, {
      name: 'foodsPlans',
      path: '/modelos/planosalimentares',
      component: () => import('@/components/models/foodPlans/PanelModelsFoodPlans')
    }, {
      name: 'meals',
      path: '/modelos/refeicoes',
      component: () => import('@/components/models/meals/PanelModelsMeals')
    }, {
      name: 'nutritionalGuidance',
      path: '/modelos/orientacoesnutricionais',
      component: () => import('@/components/models/nutritionalGuidance/NutritionalGuidance')
    }, {
      name: 'formNutritionalGuidance',
      path: '/modelos/orientacoesnutricionais/formorientacaonutricional',
      component: () => import('@/components/models/nutritionalGuidance/FormNutrionalGuidance'),
      props: true
    }, {
      name: 'prescricoes',
      path: '/modelos/prescricoes',
      component: () => import('@/components/models/prescription/Prescription')
    }, {
      name: 'formPrescription',
      path: '/modelos/prescricoes/formprescricao',
      component: () => import('@/components/models/prescription/FormPrescription'),
      props: true
    }, {
      name: 'relatorioPacientesAvaliacoes',
      path: '/relatorios/pacientesavaliacoes',
      component: () => import('@/components/reports/patientsEvaluations/PanelReportPatientsEvaluations')
    }, {
      name: 'formaDePagamento',
      path: '/mensalidades/formadepagamento',
      component: () => import('@/components/signature/formPayment/PanelFormPayment')
    }, {
      name: 'pendenciasHistorico',
      path: '/mensalidades/pendenciasehistorico',
      component: () => import('@/components/signature/pendingHistory/PanelPendingHistory')
    }, {
      name: 'planoAtual',
      path: '/mensalidades/planoatual',
      component: () => import('@/components/signature/plan/PanelPlan')
    }, {
      name: 'administracao',
      path: '/administracao/gerenciaclinicas',
      component: () => import('@/components/admin/clinicManagement/PanelClinicManagement')
    }, {
      name: 'configuracaoPreferencias',
      path: '/configuracao/preferencias',
      component: () => import('@/components/configuration/preferences/PanelPreferences')
    }, {
      name: 'configuracaoConta',
      path: '/configuracao/conta',
      component: () => import('@/components/configuration/account/PanelAccount')
    }, {
      name: 'configuracaoClinica',
      path: '/configuracao/clinica',
      component: () => import('@/components/configuration/clinic/PanelClinic')
    }, {
      name: 'tutorial',
      path: '/tutorial',
      component: () => import('@/components/Tutorial')
    }, {
      name: 'indiqueNutrieduc',
      path: '/indique',
      component: () => import('@/components/Indicate')
    }, {
      path: 'page-2',
      component: () => import('@/components/Page2')
    }]
  }, {
    // 404 Not Found page
    path: '*',
    component: NotFound
  }, {
    name: 'adminPages',
    path: '/admin',
    component: AdminPages
  }, {
    name: 'login',
    path: '/login',
    component: AuthenticationLoginV3
  }]
})

router.afterEach(() => {
  // Remove initial splash screen
  const splashScreen = document.querySelector('.app-splash-screen')
  if (splashScreen) {
    splashScreen.style.opacity = 0
    setTimeout(() =>
      splashScreen && splashScreen.parentNode && splashScreen.parentNode.removeChild(splashScreen), 300)
  }

  // On small screens collapse sidenav
  if (window.layoutHelpers && window.layoutHelpers.isSmallScreen() && !window.layoutHelpers.isCollapsed()) {
    setTimeout(() => window.layoutHelpers.setCollapsed(true, true), 10)
  }

  // Scroll to top of the page
  globals().scrollTop(0, 0)
})

router.beforeEach((to, from, next) => {
  // Set loading state
  document.body.classList.add('app-loading')

  // Add tiny timeout to finish page transition
  setTimeout(() => next(), 10)
})

export default router
