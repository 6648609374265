<template>
  <div>
    <div v-if="type === 'COMBINED'">
      <div class="row">
        <div class="col-md-2">
          <label class="f-700 f-15">Tipo</label>
          <b-select v-model="optionSelected" :options="optionsForCombined" text-field="text" class="f-15" value-field="value" @change="changeOptionSearch"/>
        </div>
        <div :class="visibleMeasures ? 'col-md-4' : 'col-md-6'">
          <label class="f-700 f-15">Pesquisar</label>
          <vue-bootstrap-typeahead class="f-15" v-model="query" :data="itensFounds" :serializer="item => item.descricao" @hit="clickSelectItem($event)" :placeholder="placeholderFieldSearch" ref="searchitem"/>
        </div>
        <div class="col-md-3 col-lg-3 col-sm-3" v-if="visibleMeasures">
          <b-form-group label-class="f-700 p-b-12" label="Medidas" style="margin-bottom: 5px">
            <b-select v-model="measureSelectId" :options="measures" text-field="descricao" class="f-15" value-field="idMedida" @change="selectMeasure"/>
          </b-form-group>
          <span><b-btn variant="info2 btn-xs" @click="newMeasure" :disabled="isCreateMeasureDisabled">Nova Medida</b-btn></span>
        </div>
        <div class="col-md-2 col-lg-2 col-sm-2">
          <label class="f-700 f-15">Qtde.</label>
          <input type="text" class="form-control f-16"  placeholder="ex.00.00" v-currency="configMask" v-model="quantidade"/>
        </div>
      </div>
      <div class="pt-5 text-right">
        <b-btn  variant="danger2 btn-sm" class="text-white mr-2" @click="closeModalCombined">Cancelar</b-btn>
        <b-btn  variant="primary btn-sm" @click="addItem" :disabled="isAddButtonDisabled">Adicionar</b-btn>
      </div>
      <modal-measure-create v-if="itemSelected" id-modal="idModalMeasureCreateSearch" ref="modalmeasurecreateref" :type="optionSelected === 'food' ? 'FOOD' : 'RECIPE'" :idItem="itemSelected.idItem" :name="itemSelected.descricao" v-on:resultCreateMeasure="onResultCreateMeasure"/>
    </div>
    <div v-else>
      <div v-if="align==='CARD' || align === 'CARD_OPTION'">
        <div class="visibleFieldComplete">
          <div class="row">
            <div class="col-md-3 col-lg-4 col-xl-5 col-sm-4">
              <label class="f-700 f-15">Pesquisar</label>
              <vue-bootstrap-typeahead class="f-15" v-model="query" :data="itensFounds" :serializer="item => item.descricao" @hit="clickSelectItem($event)" :placeholder="placeholderFieldSearch" ref="searchitem1"/>
              <div class="pt-2" v-if="type === 'FULL_OPTIONS'"><b-radio-group  v-model="optionSelected" :options="optionsFull" @change="changeOptionSearch"/></div>
              <div class="pt-2" v-if="type === 'ONLY_FOOD_RECIPE'"><b-radio-group  v-model="optionSelected" :options="optionsOnlyFoodRecipe" @change="changeOptionSearch"/></div>
              <div class="pt-2" v-if="optionSelected === 'food'"><a href="#" @click="openFormFood"><i class="ion ion-ios-add"></i>  Cadastrar alimento</a></div>
              <div class="pt-2" v-if="optionSelected === 'recipe'"><a href="#" @click="openFormRecipe"><i class="ion ion-ios-add"></i>  Cadastrar receita</a></div>
            </div>
            <div class="col-md-3 col-lg-3 col-xl-3 col-sm-3" v-if="optionSelected === 'food' || optionSelected === 'recipe'">
              <b-form-group label-class="f-700 p-b-12" label="Medidas" style="margin-bottom: 5px">
                <b-select v-model="measureSelectId" :options="measures" text-field="descricao" class="f-15" value-field="idMedida" @change="selectMeasure"/>
              </b-form-group>
              <span><b-btn variant="info2 btn-xs" @click="newMeasure" :disabled="isCreateMeasureDisabled">Nova Medida</b-btn></span>
            </div>
            <div class="col-md-2 col-lg-1 col-xl-1 col-sm-2">
              <label class="f-700 f-15">Qtde.</label>
              <input type="text" class="form-control f-16"  placeholder="ex.00.00" v-currency="configMask" v-model="quantidade"/>
            </div>
            <div class="col-md-4 col-lg-3 col-xl-3 col-sm-3">
              <div class="p-t-30">
                <span class="p-r-5"><b-btn variant="primary btn-xs" @click="addItem" :disabled="isAddButtonDisabled">Adicionar</b-btn></span>
                <span class="p-r-5"><b-btn variant="info btn-xs" :disabled="isAddButtonDisabled" @click="infoItem">Infos.</b-btn></span>
                <span ><b-btn variant="danger2 btn-xs" class="text-white" @click="cancelItemCard">Cancelar</b-btn></span>
              </div>
            </div>
          </div>
        </div>
        <div class="visibleFieldCompact">
          <div class="row">
            <div class="col-sm-7 col-md-7 col-lg-7 col-xl-7 col-sm-4 pt-3">
              <label class="f-700 f-15">Pesquisar</label>
              <vue-bootstrap-typeahead class="f-15" v-model="query" :data="itensFounds" :serializer="item => item.descricao" @hit="clickSelectItem($event)" :placeholder="placeholderFieldSearch" ref="searchitem2"/>
              <div class="pt-2" v-if="type === 'FULL_OPTIONS'"><b-radio-group  v-model="optionSelected" :options="optionsFull" @change="changeOptionSearch"/></div>
              <div class="pt-2" v-if="type === 'ONLY_FOOD_RECIPE'"><b-radio-group  v-model="optionSelected" :options="optionsOnlyFoodRecipe" @change="changeOptionSearch"/></div>
              <div class="pt-2" v-if="optionSelected === 'food'"><a href="#" @click="openFormFood"><i class="ion ion-ios-add"></i>  Cadastrar alimento</a></div>
              <div class="pt-2" v-if="optionSelected === 'recipe'"><a href="#" @click="openFormRecipe"><i class="ion ion-ios-add"></i>  Cadastrar receita</a></div>
            </div>
            <div class="col-sm-5 col-md-5 col-lg-5 col-xl-5 col-sm-3 pt-3" v-if="optionSelected === 'food' || optionSelected === 'recipe'">
              <b-form-group label-class="f-700 p-b-12" label="Medidas" style="margin-bottom: 5px">
                <b-select v-model="measureSelectId" :options="measures" text-field="descricao" class="f-15" value-field="idMedida" @change="selectMeasure"/>
              </b-form-group>
              <span><b-btn variant="info2 btn-xs" @click="newMeasure" :disabled="isCreateMeasureDisabled">Nova Medida</b-btn></span>
            </div>
          </div>
          <div class="row pt-3">
            <div class="col-sm-4 col-md-4 col-lg-4 col-xl-3 col-sm-2 pt-3">
              <label class="f-700 f-15">Qtde.</label>
              <input type="text" class="form-control f-16"  placeholder="ex.00.00" v-currency="configMask" v-model="quantidade"/>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6 col-xl-5 col-sm-3 pt-3">
              <div class="p-t-30">
                <span class="p-r-10"><b-btn variant="primary btn-xs" @click="addItem" :disabled="isAddButtonDisabled">Adicionar</b-btn></span>
                <span class="p-r-10"><b-btn variant="info btn-xs" :disabled="isAddButtonDisabled" @click="infoItem">Infos.</b-btn></span>
                <span ><b-btn variant="danger2 btn-xs" class="text-white" @click="cancelItemCard">Cancelar</b-btn></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-else>
        <div class="col-md-3 col-lg-3 col-xl-3 col-sm-4">
          <label class="f-700 f-15">Pesquisar</label>
          <vue-bootstrap-typeahead class="f-15" v-model="query" :data="itensFounds" :serializer="item => item.descricao" @hit="clickSelectItem($event)" :placeholder="placeholderFieldSearch" ref="searchitem"/>
          <div class="pt-2" v-if="type === 'FULL_OPTIONS'"><b-radio-group  v-model="optionSelected" :options="optionsFull" @change="changeOptionSearch"/></div>
          <div class="pt-2" v-if="type === 'ONLY_FOOD_RECIPE'"><b-radio-group  v-model="optionSelected" :options="optionsOnlyFoodRecipe" @change="changeOptionSearch"/></div>
          <div class="pt-2" v-if="optionSelected === 'food'"><a href="#" @click="openFormFood"><i class="ion ion-ios-add"></i>  Cadastrar alimento</a></div>
          <div class="pt-2" v-if="optionSelected === 'recipe'"><a href="#" @click="openFormRecipe"><i class="ion ion-ios-add"></i>  Cadastrar receita</a></div>
        </div>
        <div class="col-md-3 col-lg-3 col-xl-3 col-sm-3">
          <b-form-group label-class="f-700 p-b-12" label="Medidas" style="margin-bottom: 5px">
            <b-select v-model="measureSelectId" :options="measures" text-field="descricao" class="f-15" value-field="idMedida" @change="selectMeasure"/>
          </b-form-group>
          <span><b-btn variant="info2 btn-xs" @click="newMeasure" :disabled="isCreateMeasureDisabled">Nova Medida</b-btn></span>
        </div>
        <div class="col-md-2 col-lg-2 col-xl-2 col-sm-2">
          <label class="f-700 f-15">Qtde.</label>
          <input type="text" class="form-control f-16"  placeholder="ex.00.00" v-currency="configMask" v-model="quantidade"/>
        </div>
        <div class="col-md-4 col-lg-4 col-xl-4 col-sm-3">
          <div class="p-t-30">
            <span class="p-r-10"><b-btn variant="primary btn-xs" @click="addItem" :disabled="isAddButtonDisabled">Adicionar</b-btn></span>
            <span class="p-r-10"><b-btn variant="info btn-xs" :disabled="isAddButtonDisabled" @click="infoItem">Infos.</b-btn></span>
            <span ><b-btn variant="danger2 btn-xs" class="text-white" @click="cancelItem">Cancelar</b-btn></span>
          </div>
        </div>
      </div>
      <modal-food-info id-modal="idModalSearch" ref="modalfoodinfo"/>
      <modal-measure-create v-if="itemSelected" id-modal="idModalMeasureCreateSearch" ref="modalmeasurecreateref" :type="optionSelected === 'food' ? 'FOOD' : 'RECIPE'" :idItem="itemSelected.idItem" :name="itemSelected.descricao" v-on:resultCreateMeasure="onResultCreateMeasure"/>
      <modal-form-food id-modal="idNewFood" ref="modalfoodnew" />
      <modal-form-recipe idModal="idNewRecipeInSearch" ref="modalrecipenew" v-if="location === 'OUT_FORM_RECIPE'"/>
      <modal-recipe-info id-modal="idModalRecipeInSearch" ref="modalrecipeinsearch" />
      <modal-replacement-list-info id-modal="idModalReplacementeInSearch" ref="modalreplacementinsearch" />
      <modal-combined-food-info id-modal="idModalCombinedFoodInSearch" ref="modalcombinedinsearch" />
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import globals from '@/globals'
import ModalFoodInfo from '@/components/modals/foods/modalFoodInfo'
import ModalMeasureCreate from '@/components/modals/measures/modalMeasureCreate'
import ModalFormFood from '@/components/modals/foods/modalFormFood'
import buscustom from '@/config/buscustom'
import ModalRecipeInfo from '@/components/modals/recipeInfo/modalRecipeInfo'
import ModalFormRecipe from '@/components/modals/recipeInfo/modalFormRecipe'
import ModalReplacementListInfo from '@/components/modals/replacementList/modalReplacementListInfo'
import ModalCombinedFoodInfo from '@/components/modals/combinedFood/modalCombinedFoodInfo'
import { mapActions } from 'vuex'
import { uuid } from 'vue-uuid'

export default {
  name: 'SearchItem',
  props: ['type', 'ingredients', 'listItems', 'location', 'align', 'indexRefeicaoCard', 'indexMealOptionCard'],
  components: { ModalCombinedFoodInfo, ModalReplacementListInfo, ModalFormRecipe, ModalRecipeInfo, ModalFormFood, VueBootstrapTypeahead, ModalFoodInfo, ModalMeasureCreate },
  data () {
    return {
      query: '',
      configMask: globals().defineNumberMask,
      itemSelected: null,
      measureSelectId: null,
      measureSelect: '',
      quantidade: '',
      itensFounds: [],
      measures: [],
      visibleMeasures: true,
      paramsSearchFoods: {
        numpagina: 1,
        totalpagina: 50,
        alimentosempresa: true,
        resultunificado: true,
        pesquisaalimento: ''
      },
      paramsSearch: {
        numpagina: 1,
        totalpagina: 50,
        pesquisadescricao: ''
      },
      optionsFull: [
        { text: 'Alimento', value: 'food' },
        { text: 'Receita', value: 'recipe' },
        { text: 'Substituição', value: 'replacementList' },
        { text: 'Combinação', value: 'combinedFood' }
      ],
      optionsForCombined: [
        { text: 'Alimento', value: 'food' },
        { text: 'Receita', value: 'recipe' },
        { text: 'Substituição', value: 'replacementList' }
      ],
      optionsOnlyFoodRecipe: [
        { text: 'Alimento', value: 'food' },
        { text: 'Receita', value: 'recipe' }
      ],
      optionSelected: 'food',
      placeholderFieldSearch: 'Informe o nome do alimento...',
      urlSearchItem: globals().baseApiUrl + '/alimentos',
      urlSeachMeasure: ''
    }
  },
  methods: {
    ...mapActions(['addItemInMealViewBuild', 'updateTotalMacrosInMealViewBuild', 'cloneSearchInCardMeal', 'cloneSearchInCardMealOptional']),
    changeOptionSearch (optionSelected) {
      if (optionSelected === 'food') {
        this.placeholderFieldSearch = 'Informe o nome do alimento...'
        this.urlSearchItem = globals().baseApiUrl + '/alimentos'
        this.visibleMeasures = true
      } else if (optionSelected === 'recipe') {
        this.placeholderFieldSearch = 'Informe o nome da receita...'
        this.urlSearchItem = globals().baseApiUrl + '/receitas'
        this.visibleMeasures = true
      } else if (optionSelected === 'replacementList') {
        this.placeholderFieldSearch = 'Informe o nome da lista de substituição...'
        this.urlSearchItem = globals().baseApiUrl + '/listassubstituicao'
        this.visibleMeasures = false
      } else if (optionSelected === 'combinedFood') {
        this.placeholderFieldSearch = 'Informe o nome da combinacao...'
        this.urlSearchItem = globals().baseApiUrl + '/alimentoscombinados'
        this.visibleMeasures = false
      }
      this.cancelItem()
    },
    clickSelectItem (event) {
      this.itemSelected = event
      if (this.optionSelected === 'food') {
        this.urlSeachMeasure = globals().baseApiUrl + '/alimentos/' + this.itemSelected.idItem + '/medidas'
        this.loadMeasures()
      } else if (this.optionSelected === 'recipe') {
        this.urlSeachMeasure = globals().baseApiUrl + '/receitas/' + this.itemSelected.idItem + '/medidas'
        this.loadMeasures()
      } else {
        this.quantidade = this.$options.filters.parseDoubleStringDecimal(1.0)
      }
    },
    selectMeasure () {
      const measureSelectTest = this.measureSelectId
      const measure = this.measures.filter(function (measure) {
        return measure.idMedida === measureSelectTest
      })
      this.measureSelect = measure[0]
      this.quantidade = this.$options.filters.parseDoubleStringDecimal(this.measureSelect.valorQuantidade)
    },
    openFormFood () {
      this.$refs.modalfoodnew.openModal()
    },
    openFormRecipe () {
      this.$refs.modalrecipenew.openModal()
    },
    loadMeasures () {
      axios.get(this.urlSeachMeasure, { showLoader: false }).then(res => {
        this.measures = []
        this.measures = res.data.medidas
        if (this.measures.length > 0) {
          this.measureSelect = this.measures[0]
          this.measureSelectId = this.measureSelect.idMedida
          this.quantidade = this.$options.filters.parseDoubleStringDecimal(this.measureSelect.valorQuantidade)
        }
      }).catch(globals.showError)
    },
    newMeasure () {
      this.$refs.modalmeasurecreateref.openModal()
    },
    onResultCreateMeasure (value) {
      this.measures.push(value)
      this.measureSelectId = value.idMedida
      this.measureSelect = value
    },
    infoItem () {
      const qtd = this.$options.filters.stringToNumberDecimal(this.quantidade)
      if (this.optionSelected === 'food') {
        const param = { idMedida: this.measureSelect.idMedida, quantidade: qtd }
        axios.get(globals().baseApiUrl + '/alimentos/' + this.itemSelected.idItem + '/dadosnutricionais', { params: param, showLoader: true })
          .then(res => {
            this.$refs.modalfoodinfo.openModal(res.data, this.itemSelected.descricao, qtd, this.measureSelect)
          })
          .catch(globals.showError)
      } else if (this.optionSelected === 'recipe') {
        axios.get(globals().baseApiUrl + '/receitas/find/' + this.itemSelected.idItem, { showLoader: true })
          .then(res => {
            this.$refs.modalrecipeinsearch.openModal(res.data, this.measureSelect, qtd)
          })
          .catch(globals.showError)
      } else if (this.optionSelected === 'replacementList') {
        axios.get(globals().baseApiUrl + '/listassubstituicao/find/' + this.itemSelected.idItem, { showLoader: true })
          .then(res => {
            this.$refs.modalreplacementinsearch.openModal(res.data, qtd)
          })
          .catch(globals.showError)
      } else {
        axios.get(globals().baseApiUrl + '/alimentoscombinados/find/' + this.itemSelected.idItem, { showLoader: true })
          .then(res => {
            this.$refs.modalcombinedinsearch.openModal(res.data, qtd)
          })
          .catch(globals.showError)
      }
    },
    cancelItem () {
      this.measures = []
      this.itensFounds = []
      this.itemSelected = null
      this.measureSelect = null
      this.quantidade = ''
      this.query = ''
      if (this.align === 'CARD' || this.align === 'CARD_OPTION') {
        this.$refs.searchitem1.inputValue = ''
        this.$refs.searchitem2.inputValue = ''
      } else {
        this.$refs.searchitem.inputValue = ''
      }
    },
    cancelItemCard () {
      this.cancelItem()
      if (this.align === 'CARD') {
        this.cloneSearchInCardMeal({ index: this.indexRefeicaoCard })
      } else if (this.align === 'CARD_OPTION') {
        this.cloneSearchInCardMealOptional({ indexMeal: this.indexRefeicaoCard, indexMealOptional: this.indexMealOptionCard })
      }
    },
    addItem () {
      if (this.type === 'ONLY_FOOD') {
        const ingredient = { alimento: { idAlimento: this.itemSelected.idItem, descricao: this.itemSelected.descricao }, medida: this.measureSelect, quantidade: this.$options.filters.stringToNumberDecimal(this.quantidade) }
        this.ingredients.push(ingredient)
      } else {
        let itemAdd = {}
        if (this.optionSelected === 'food') {
          itemAdd = { uuid: uuid.v1(), item: this.itemSelected, medida: this.measureSelect, quantidade: this.$options.filters.stringToNumberDecimal(this.quantidade), type: 'FOOD' }
        } else if (this.optionSelected === 'recipe') {
          itemAdd = { uuid: uuid.v1(), item: this.itemSelected, medida: this.measureSelect, quantidade: this.$options.filters.stringToNumberDecimal(this.quantidade), type: 'RECIPE' }
        } else if (this.optionSelected === 'replacementList') {
          itemAdd = { uuid: uuid.v1(), item: this.itemSelected, quantidade: this.$options.filters.stringToNumberDecimal(this.quantidade), type: 'REPLACEMENT' }
        } else if (this.optionSelected === 'combinedFood') {
          itemAdd = { uuid: uuid.v1(), item: this.itemSelected, quantidade: this.$options.filters.stringToNumberDecimal(this.quantidade), type: 'COMBINED' }
        }
        this.listItems.push(itemAdd)
        if (this.align === 'CARD') {
          this.addItemInMealViewBuild({ index: this.indexRefeicaoCard, obj: itemAdd, type: 'MEAL' })
          this.updateTotalMacrosInMealViewBuild({ index: this.indexRefeicaoCard, type: 'MEAL' })
        } else if (this.align === 'CARD_OPTION') {
          this.addItemInMealViewBuild({ index: this.indexRefeicaoCard, indexMealOptional: this.indexMealOptionCard, obj: itemAdd, type: 'MEAL_OPTION' })
          this.updateTotalMacrosInMealViewBuild({ index: this.indexRefeicaoCard, indexMealOptional: this.indexMealOptionCard, type: 'MEAL_OPTION' })
        }
      }
      if (this.type === 'COMBINED') {
        buscustom.saveAddItemInCombined('closeModal')
        buscustom.updateMacrosAveregaCombined('true')
      } else {
        this.cancelItem()
      }
    },
    closeModalCombined () {
      this.cancelItem()
      buscustom.saveAddItemInCombined('closeModal')
    },
    pushItensFounds (response) {
      switch (this.optionSelected) {
        case 'food':
          this.itensFounds = response.alimentos.map(al => {
            const json = { descricao: al.descricao, idItem: al.idAlimento }
            return json
          })
          break
        case 'recipe':
          this.itensFounds = response.receitas.map(rec => {
            const json = { descricao: rec.nome, idItem: rec.idReceita }
            return json
          })
          break
        case 'replacementList' :
          this.itensFounds = response.grupos.map(grp => {
            const json = { descricao: grp.descricao, idItem: grp.idGrupoAlimentoEmpresa }
            return json
          })
          break
        case 'combinedFood' :
          this.itensFounds = response.alimentosCombinados.map(alComb => {
            const json = { descricao: alComb.nome, idItem: alComb.idCombinacaoAlimentoEmpresa }
            return json
          })
          break
      }
    }
  },
  watch: {
    query (newQuery) {
      if (newQuery.length >= 3) {
        let params = {}
        if (this.optionSelected === 'food') {
          this.paramsSearchFoods.pesquisaalimento = newQuery
          params = this.paramsSearchFoods
        } else {
          this.paramsSearch.pesquisadescricao = newQuery
          params = this.paramsSearch
        }
        axios.get(this.urlSearchItem, { params: params, showLoader: false })
          .then((res) => {
            this.pushItensFounds(res.data)
          })
      }
    }
  },
  computed: {
    isAddButtonDisabled () {
      if (this.optionSelected === 'food' || this.optionSelected === 'recipe') {
        return !(this.itemSelected && this.measureSelect && this.quantidade)
      } else {
        return !(this.itemSelected && this.quantidade)
      }
    },
    isCreateMeasureDisabled () {
      return !(this.itemSelected)
    }
  },
  mounted () {
    buscustom.hasNewFood((food) => {
      this.itemSelected = { idItem: food.idAlimento, descricao: food.descricao }
      this.itensFounds.push(this.itemSelected)
      this.urlSeachMeasure = globals().baseApiUrl + '/alimentos/' + this.itemSelected.idItem + '/medidas'
      this.loadMeasures()
      this.$refs.searchitem.inputValue = this.itemSelected.descricao
    })
    buscustom.hasNewRecipe((recipe) => {
      this.itemSelected = { idItem: recipe.idReceita, descricao: recipe.nome }
      this.itensFounds.push(this.itemSelected)
      this.urlSeachMeasure = globals().baseApiUrl + '/receitas/' + this.itemSelected.idItem + '/medidas'
      this.loadMeasures()
      this.$refs.searchitem.inputValue = this.itemSelected.descricao
    })
  }
}
</script>

<style scoped>
.hide {
  display: none;
}
</style>
