<template>
  <div>
    <div class="row p-b-20">
      <div :class="type === 'FORM' ? 'col-6 col-md-5 col-lg-5 col-xl-3' : 'col-6 col-md-5 col-xl-4'">
        <div style="display: block; margin-left: auto; margin-right: auto; width: 50%">
          <input-image v-model="imageData" title="Foto da receita" ref="imagePreview" />
        </div>
      </div>
      <div :class="type === 'FORM'? 'col-12 col-sm-6 col-md-7 col-lg-6 col-xl-6' : 'col-9 col-xl-7 pl-4'">
        <div class="p-t-20">
          <label for="nomeReceita" class="f-700 f-15">Nome*</label>
          <input type="text" v-model="receitaParam.nome" id="nomeReceita" name="nomeReceita" class="form-control f-16" placeholder="Nome da receita"/>
        </div>
        <div class="p-t-30">
          <label for="linkYoutube" class="f-700 f-15">Link do youtube</label>
          <input type="text" v-model="receitaParam.linkYoutube" id="linkYoutube" name="linkYoutube" class="form-control f-16" placeholder="Informe o link vídeo da receita no youtube"/>
        </div>
      </div>
    </div>
    <div class="p-t-30 p-l-20 p-r-20">
      <div>
        <h4 class="mb-0">Adicionar ingredientes*</h4>
      </div>
      <div class="p-t-15">
        <SearchItem type="ONLY_FOOD" :ingredients="receitaParam.ingredientes" :location="location"/>
      </div>
      <div class="p-t-30">
        <div class="shadow mb-3 bg-white rounded">
          <b-card-header header-tag="h6" class="bg-headertable">Ingredientes</b-card-header>
          <div class="table-responsive">
            <div v-if="receitaParam.ingredientes.length > 0">
              <table class="table card-table table-striped">
                <thead>
                <tr>
                  <th>Ingrediente</th>
                  <th class="text-center">Mais informações</th>
                  <th class="text-center">Remover</th>
                </tr>
                </thead>
                <tbody>
                <tr  v-for="(ingrediente, index) in receitaParam.ingredientes" :key="index">
                  <td>
                    <TextFoodWithMeasure :qtd="ingrediente.quantidade" :medida-descricao="ingrediente.medida.descricaoMedida" :nome-alimento="ingrediente.alimento.descricao" :medida-liquida="ingrediente.medida.medidaLiquida" :valor-medida="ingrediente.medida.valorMedida "/>
                  </td>
                  <td class="text-center">
                    <button type="button" class="btn btn-outline-secondary icon-btn borderless btn-sm ajust-outline-btn" @click="moreInfoIngredient(ingrediente)"><i class="fas fa-info-circle"></i></button>
                  </td>
                  <td class="text-center"><button type="button" class="btn btn-outline-danger icon-btn borderless btn-sm ajust-outline-btn" @click="removeIngredient(index)"><i class="ion ion-md-close"></i></button></td>
                </tr>
                </tbody>
              </table>
            </div>
            <div v-else class="p-20 f-16">
              Sem ingredientes adicionados
            </div>
          </div>
        </div>
        <div v-if="receitaParam.ingredientes.length > 0" class="f-16 p-t-20">
          Quantidade total : {{totalGramagem}} g
        </div>
      </div>
      <div class="p-t-30 mb-4">
        <h4 class="mb-1">Porções</h4>
        <input type="text" v-model="receitaParam.porcoes" id="porcoes" name="porcoes" :class="type === 'FORM' ? 'form-control f-16 col-sm-3' : 'form-control f-16 col-sm-4' " placeholder="Quanto rende cara porção da receita. ex.00,00" v-currency="configMask"/>
      </div>
      <div class="p-t-30">
        <h4>Modo de preparo*</h4>
        <vue-editor v-model="receitaParam.modoPreparo" :editorToolbar="configEditorText.customToolbar"></vue-editor>
      </div>
    </div>
    <InfoTotalMacronutrients class="mt-5 p-l-20 mb-4 pt-2" :list-food="receitaParam.ingredientes" :total-macronutriente="receitaParam.totalMacronutriente" v-bind:class="{ hide : receitaParam.ingredientes.length <= 0 }"/>
    <div class="p-t-30 p-l-20" :class="type === 'MODAL' ? 'text-right pr-4' : ''">
      <b-btn variant="primary" @click="openModalNutrients" :disabled="isEnableActionButton"><span class="fas fa-ellipsis-v"></span>&nbsp;&nbsp;Ver Micronutrientes</b-btn>
    </div>
    <div class="p-t-30 p-l-20" :class="type === 'MODAL' ? 'text-right pr-4' : ''">
      <button type="button" class="btn btn-outline-success mr-4" @click="backListRecipe"><i class="fas fa-times"></i> Descartar</button>
      <button class="btn btn-primary" @click="saveRecipe"><i class="far fa-save"></i> Salvar</button>
    </div>
    <modal-food-info id-modal="idModalMoreInfoFood" ref="modalmoreinfofood"/>
    <modal-detail-total-nutrients id-modal="idModalRecipeForMicronutrient" type="TOTAL" ref="modalrecipeformicrotrient"/>
  </div>
</template>

<script>
import globals from '@/globals'
import axios from 'axios'
import ModalDetailTotalNutrients from '@/components/modals/nutrients/modalDetailTotalNutrients'
import InfoTotalMacronutrients from '@/components/ui/InfoTotalMacronutrients'
import ModalFoodInfo from '@/components/modals/foods/modalFoodInfo'
import TextFoodWithMeasure from '@/components/ui/TextFoodWithMeasure'
import InputImage from '@/components/ui/InputImage'
import buscustom from '@/config/buscustom'
import { VueEditor } from 'vue2-editor'

export default {
  name: 'FormRecipeInner',
  props: ['recipeEdit', 'action', 'type', 'location'],
  components: { ModalDetailTotalNutrients, InfoTotalMacronutrients, ModalFoodInfo, TextFoodWithMeasure, InputImage, VueEditor, SearchItem: () => import('@/components/ui/SearchItem') },
  data () {
    return {
      idReceita: '',
      imageData: null,
      configMask: globals().defineNumberMask,
      configEditorText: globals().configEditorText,
      receitaParam: {
        nome: '',
        porcoes: '',
        quantidadeTotalGramagem: 0,
        modoPreparo: '',
        linkYoutube: '',
        ingredientes: [],
        foto: '',
        fotoBase64: { base64: '', filename: '' },
        totalMacronutriente: {
          totalKcal: 0,
          totalCarboidratosGramas: 0,
          totalProteinasGramas: 0,
          totalGordurasGramas: 0
        }
      },
      paramMicronutrient: {
        alimentos: []
      }
    }
  },
  methods: {
    moreInfoIngredient (ingredient) {
      const param = { idMedida: ingredient.medida.idMedida }
      axios.get(globals().baseApiUrl + '/alimentos/' + ingredient.alimento.idAlimento + '/dadosnutricionais', { params: param, showLoader: true, useCompany: true })
        .then(res => {
          this.$refs.modalmoreinfofood.openModal(res.data, ingredient.alimento.descricao, ingredient.quantidade, ingredient.medida)
        })
        .catch(globals.showError)
    },
    removeIngredient (index) {
      this.receitaParam.ingredientes.splice(index, 1)
    },
    validateSave () {
      if (this.receitaParam.ingredientes.length <= 0) {
        globals().showWarning('Insira ingredientes na receita.')
        return false
      }
      if (!this.receitaParam.modoPreparo) {
        globals().showWarning('Informe o modo de preparo.')
        return false
      }
      if (!this.receitaParam.nome) {
        globals().showWarning('Informe o nome da receita')
        return false
      }
      if (!this.receitaParam.porcoes) {
        this.receitaParam.porcoes = 0
      } else {
        this.receitaParam.porcoes = this.$options.filters.stringToNumberDecimal(this.receitaParam.porcoes)
      }
      return true
    },
    async saveRecipe () {
      if (this.imageData) {
        this.receitaParam.fotoBase64.base64 = await globals().toBase64(this.imageData)
        this.receitaParam.fotoBase64.filename = this.imageData.name
      }
      this.receitaParam.quantidadeTotalGramagem = this.totalGramagem
      if (this.validateSave()) {
        if (this.action === 'NEW_RECIPE') {
          axios.post(globals().baseApiUrl + '/receitas', this.receitaParam, { showLoader: true })
            .then(res => {
              globals().showSuccess(res.data.message)
              this.backListRecipe()
              if (this.type === 'MODAL') {
                buscustom.newRecipeSave(res.data.receita)
              }
            })
            .catch(globals().showError)
        } else {
          axios.put(globals().baseApiUrl + '/receitas/' + this.recipeEdit.idReceita, this.receitaParam, { showLoader: true })
            .then(res => {
              globals().showSuccess(res.data.message)
              this.backListRecipe()
            })
            .catch(reason => {
              globals().showError(reason)
            })
        }
      }
    },
    loadRecipeEdit () {
      axios.get(globals().baseApiUrl + '/receitas/find/' + this.recipeEdit.idReceita, { showLoader: true })
        .then(res => {
          this.receitaParam.linkYoutube = res.data.linkYoutube
          this.receitaParam.porcoes = this.$options.filters.parseDoubleStringDecimal(res.data.porcoes)
          this.receitaParam.quantidadeTotalGramagem = res.data.quantidadeTotalGramagem
          this.receitaParam.modoPreparo = res.data.modoPreparo
          this.receitaParam.ingredientes = res.data.ingredientes
          this.receitaParam.totalMacronutriente = res.data.totalMacronutriente
          this.receitaParam.foto = res.data.foto
          this.$refs.imagePreview.loadPreviewImage(res.data.foto)
        })
        .catch(globals.showError)
    },
    openModalNutrients () {
      this.paramMicronutrient.alimentos = this.receitaParam.ingredientes.map(ingredient => {
        return { idAlimento: ingredient.alimento.idAlimento, idMedida: ingredient.medida.idMedida, quantidade: ingredient.quantidade }
      })
      axios.post(globals().baseApiUrl + '/nutrientes/micronutrientes/combine', this.paramMicronutrient, { showLoader: true })
        .then(res => {
          this.$refs.modalrecipeformicrotrient.openModal(this.receitaParam.totalMacronutriente, res.data.resultado, 'Tabela nutricional da receita de acordo com os ingredientes adicionados')
        })
        .catch(globals.showError)
    },
    backListRecipe () {
      if (this.type === 'FORM') {
        this.$router.push({ name: 'receitasList' })
      } else {
        this.$emit('closeModalRecipe')
      }
    }
  },
  computed: {
    totalGramagem () {
      if (this.receitaParam.ingredientes.length > 0) {
        const result = this.receitaParam.ingredientes.reduce((sum, ingrediente) => {
          return sum + (ingrediente.quantidade * ingrediente.medida.valorMedida)
        }, 0)
        return result
      } else {
        return 0
      }
    },
    isEnableActionButton () {
      return !(this.receitaParam.ingredientes.length > 0)
    }
  },
  mounted () {
    if (this.action === 'EDIT_RECIPE') {
      this.loadRecipeEdit()
      this.receitaParam.nome = this.recipeEdit.nome
    }
    document.querySelector('.ql-editor').setAttribute('style', 'height: 250px !important; overflow-y: scroll !important; font-family: Dosis !important;')
  },
  beforeCreate: function () {
    this.$options.components.SearchItem = require('@/components/ui/SearchItem').default
  }
}
</script>

<style scoped>
.hide {
  display: none;
}
</style>
