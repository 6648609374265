<template>
  <div id="app">
    <Loader id="demo" :is-visible="isLoading"></Loader>
    <router-view/>
  </div>
</template>

<style src="@/vendor/styles/bootstrap-material.scss" lang="scss"></style>
<style src="@/vendor/styles/appwork-material.scss" lang="scss"></style>
<style src="@/vendor/styles/theme-soft-material.scss" lang="scss"></style>
<style src="@/vendor/styles/colors-material.scss" lang="scss"></style>

<!-- <style src="@/vendor/styles/bootstrap-dark.scss" lang="scss"></style>
<style src="@/vendor/styles/appwork-dark.scss" lang="scss"></style>
<style src="@/vendor/styles/theme-soft-dark.scss" lang="scss"></style>
<style src="@/vendor/styles/colors-dark.scss" lang="scss"></style> -->

<style src="@/vendor/styles/uikit.scss" lang="scss"></style>
<style src="./style.scss" lang="scss"></style>

<script>
import globals from '@/globals'
import * as axios from 'axios'
import Loader from '@/components/ui/Loader'

export default {
  name: 'app',
  components: { Loader },
  metaInfo: {
    title: 'Index',
    titleTemplate: 'Nutrieduc Clínica'
  },
  data: function () {
    return {
      isLoading: false,
      axiosInterceptor: null
    }
  },
  methods: {
    async validateToken () {
      const json = localStorage.getItem(globals().userToken)
      const userToken = JSON.parse(json)
      if (!userToken) {
        return this.$router.push({ name: 'login' })
      }
    },
    enableInterceptor () {
      this.axiosInterceptor = axios.interceptors.request.use((config) => {
        if (config.showLoader) {
          this.isLoading = config.showLoader
        }
        return config
      }, (error) => {
        this.isLoading = false
        return Promise.reject(error)
      })
      axios.interceptors.response.use((response) => {
        this.isLoading = false
        return response
      }, function (error) {
        if (document.getElementById('demo')) {
          document.getElementById('demo').classList.remove('loader-overlay')
        }
        return Promise.reject(error)
      })
    },
    disableInterceptor () {
      axios.interceptors.request.eject(this.axiosInterceptor)
    }
  },
  created () {
    this.validateToken()
    this.enableInterceptor()
  }
}
</script>
