
export default function () {
  return {
    rdaConstantes () {
      const mapCalcio = new Map()
      mapCalcio.set('0-6m', 201)
      mapCalcio.set('7-12m', 270)
      mapCalcio.set('c-1-3', 500)
      mapCalcio.set('c-4-8', 800)
      mapCalcio.set('h-9-13', 1300)
      mapCalcio.set('h-14-18', 1300)
      mapCalcio.set('h-19-30', 1000)
      mapCalcio.set('h-31-50', 1000)
      mapCalcio.set('h-51-70', 1200)
      mapCalcio.set('h-71', 1200)
      mapCalcio.set('m-9-13', 1300)
      mapCalcio.set('m-14-18', 1300)
      mapCalcio.set('m-19-30', 1000)
      mapCalcio.set('m-31-50', 1000)
      mapCalcio.set('m-51-70', 1200)
      mapCalcio.set('m-71', 1200)
      mapCalcio.set('g-18', 1300)
      mapCalcio.set('g-19-30', 1000)
      mapCalcio.set('g-31-50', 1000)
      mapCalcio.set('l-18', 1300)
      mapCalcio.set('l-19-30', 1000)
      mapCalcio.set('l-31-50', 1000)

      const mapAcidoAlfaGraxoLinoleico = new Map()
      mapAcidoAlfaGraxoLinoleico.set('0-6m', 0.5)
      mapAcidoAlfaGraxoLinoleico.set('7-12m', 0.5)
      mapAcidoAlfaGraxoLinoleico.set('c-1-3', 0.7)
      mapAcidoAlfaGraxoLinoleico.set('c-4-8', 0.9)
      mapAcidoAlfaGraxoLinoleico.set('h-9-13', 1.2)
      mapAcidoAlfaGraxoLinoleico.set('h-14-18', 1.6)
      mapAcidoAlfaGraxoLinoleico.set('h-19-30', 1.6)
      mapAcidoAlfaGraxoLinoleico.set('h-31-50', 1.6)
      mapAcidoAlfaGraxoLinoleico.set('h-51-70', 1.6)
      mapAcidoAlfaGraxoLinoleico.set('h-71', 1.6)
      mapAcidoAlfaGraxoLinoleico.set('m-9-13', 1.0)
      mapAcidoAlfaGraxoLinoleico.set('m-14-18', 1.1)
      mapAcidoAlfaGraxoLinoleico.set('m-19-30', 1.1)
      mapAcidoAlfaGraxoLinoleico.set('m-31-50', 1.1)
      mapAcidoAlfaGraxoLinoleico.set('m-51-70', 1.1)
      mapAcidoAlfaGraxoLinoleico.set('m-71', 1.1)
      mapAcidoAlfaGraxoLinoleico.set('g-18', 1.4)
      mapAcidoAlfaGraxoLinoleico.set('g-19-30', 1.4)
      mapAcidoAlfaGraxoLinoleico.set('g-31-50', 1.4)
      mapAcidoAlfaGraxoLinoleico.set('l-18', 1.3)
      mapAcidoAlfaGraxoLinoleico.set('l-19-30', 1.3)
      mapAcidoAlfaGraxoLinoleico.set('l-31-50', 1.3)

      const mapAcGraxoLinoleico = new Map()
      mapAcGraxoLinoleico.set('0-6m', 4.4)
      mapAcGraxoLinoleico.set('7-12m', 4.6)
      mapAcGraxoLinoleico.set('c-1-3', 7)
      mapAcGraxoLinoleico.set('c-4-8', 10)
      mapAcGraxoLinoleico.set('h-9-13', 12)
      mapAcGraxoLinoleico.set('h-14-18', 16)
      mapAcGraxoLinoleico.set('h-19-30', 17)
      mapAcGraxoLinoleico.set('h-31-50', 17)
      mapAcGraxoLinoleico.set('h-51-70', 14)
      mapAcGraxoLinoleico.set('h-71', 14)
      mapAcGraxoLinoleico.set('m-9-13', 10)
      mapAcGraxoLinoleico.set('m-14-18', 11)
      mapAcGraxoLinoleico.set('m-19-30', 12)
      mapAcGraxoLinoleico.set('m-31-50', 12)
      mapAcGraxoLinoleico.set('m-51-70', 11)
      mapAcGraxoLinoleico.set('m-71', 11)
      mapAcGraxoLinoleico.set('g-18', 13)
      mapAcGraxoLinoleico.set('g-19-30', 13)
      mapAcGraxoLinoleico.set('g-31-50', 13)
      mapAcGraxoLinoleico.set('l-18', 13)
      mapAcGraxoLinoleico.set('l-19-30', 13)
      mapAcGraxoLinoleico.set('l-31-50', 13)

      const mapAcPantotenico = new Map()
      mapAcPantotenico.set('0-6m', 1.7)
      mapAcPantotenico.set('7-12m', 1.8)
      mapAcPantotenico.set('c-1-3', 2)
      mapAcPantotenico.set('c-4-8', 3)
      mapAcPantotenico.set('h-9-13', 4)
      mapAcPantotenico.set('h-14-18', 5)
      mapAcPantotenico.set('h-19-30', 5)
      mapAcPantotenico.set('h-31-50', 5)
      mapAcPantotenico.set('h-51-70', 5)
      mapAcPantotenico.set('h-71', 5)
      mapAcPantotenico.set('m-9-13', 4)
      mapAcPantotenico.set('m-14-18', 5)
      mapAcPantotenico.set('m-19-30', 5)
      mapAcPantotenico.set('m-31-50', 5)
      mapAcPantotenico.set('m-51-70', 5)
      mapAcPantotenico.set('m-71', 5)
      mapAcPantotenico.set('g-18', 6)
      mapAcPantotenico.set('g-19-30', 6)
      mapAcPantotenico.set('g-31-50', 6)
      mapAcPantotenico.set('l-18', 7)
      mapAcPantotenico.set('l-19-30', 7)
      mapAcPantotenico.set('l-31-50', 7)

      const mapAgua = new Map()
      mapAgua.set('0-6m', 0.7)
      mapAgua.set('7-12m', 0.8)
      mapAgua.set('c-1-3', 1.3)
      mapAgua.set('c-4-8', 1.7)
      mapAgua.set('h-9-13', 2.4)
      mapAgua.set('h-14-18', 3.3)
      mapAgua.set('h-19-30', 3.7)
      mapAgua.set('h-31-50', 3.7)
      mapAgua.set('h-51-70', 3.7)
      mapAgua.set('h-71', 3.7)
      mapAgua.set('m-9-13', 2.1)
      mapAgua.set('m-14-18', 2.3)
      mapAgua.set('m-19-30', 2.7)
      mapAgua.set('m-31-50', 2.7)
      mapAgua.set('m-51-70', 2.7)
      mapAgua.set('m-71', 2.7)
      mapAgua.set('g-18', 3)
      mapAgua.set('g-19-30', 3)
      mapAgua.set('g-31-50', 3)
      mapAgua.set('l-18', 3.8)
      mapAgua.set('l-19-30', 3.8)
      mapAgua.set('l-31-50', 3.8)

      const mapFerro = new Map()
      mapFerro.set('0-6m', 0.27)
      mapFerro.set('7-12m', 11)
      mapFerro.set('c-1-3', 7)
      mapFerro.set('c-4-8', 10)
      mapFerro.set('h-9-13', 8)
      mapFerro.set('h-14-18', 11)
      mapFerro.set('h-19-30', 8)
      mapFerro.set('h-31-50', 8)
      mapFerro.set('h-51-70', 8)
      mapFerro.set('h-71', 8)
      mapFerro.set('m-9-13', 8)
      mapFerro.set('m-14-18', 15)
      mapFerro.set('m-19-30', 18)
      mapFerro.set('m-31-50', 18)
      mapFerro.set('m-51-70', 8)
      mapFerro.set('m-71', 8)
      mapFerro.set('g-18', 27)
      mapFerro.set('g-19-30', 27)
      mapFerro.set('g-31-50', 27)
      mapFerro.set('l-18', 10)
      mapFerro.set('l-19-30', 9)
      mapFerro.set('l-31-50', 9)

      const mapFibra = new Map()
      mapFibra.set('0-6m', null)
      mapFibra.set('7-12m', null)
      mapFibra.set('c-1-3', 19)
      mapFibra.set('c-4-8', 25)
      mapFibra.set('h-9-13', 31)
      mapFibra.set('h-14-18', 38)
      mapFibra.set('h-19-30', 38)
      mapFibra.set('h-31-50', 38)
      mapFibra.set('h-51-70', 30)
      mapFibra.set('h-71', 30)
      mapFibra.set('m-9-13', 26)
      mapFibra.set('m-14-18', 26)
      mapFibra.set('m-19-30', 25)
      mapFibra.set('m-31-50', 25)
      mapFibra.set('m-51-70', 21)
      mapFibra.set('m-71', 1)
      mapFibra.set('g-18', 28)
      mapFibra.set('g-19-30', 28)
      mapFibra.set('g-31-50', 28)
      mapFibra.set('l-18', 29)
      mapFibra.set('l-19-30', 29)
      mapFibra.set('l-31-50', 29)

      const mapFluor = new Map()
      mapFluor.set('0-6m', 0.01)
      mapFluor.set('7-12m', 0.5)
      mapFluor.set('c-1-3', 0.7)
      mapFluor.set('c-4-8', 1)
      mapFluor.set('h-9-13', 2)
      mapFluor.set('h-14-18', 3)
      mapFluor.set('h-19-30', 4)
      mapFluor.set('h-31-50', 4)
      mapFluor.set('h-51-70', 4)
      mapFluor.set('h-71', 4)
      mapFluor.set('m-9-13', 2)
      mapFluor.set('m-14-18', 3)
      mapFluor.set('m-19-30', 3)
      mapFluor.set('m-31-50', 3)
      mapFluor.set('m-51-70', 3)
      mapFluor.set('m-71', 3)
      mapFluor.set('g-18', 3)
      mapFluor.set('g-19-30', 3)
      mapFluor.set('g-31-50', 3)
      mapFluor.set('l-18', 3)
      mapFluor.set('l-19-30', 3)
      mapFluor.set('l-31-50', 3)

      const mapFosforo = new Map()
      mapFosforo.set('0-6m', 100)
      mapFosforo.set('7-12m', 275)
      mapFosforo.set('c-1-3', 460)
      mapFosforo.set('c-4-8', 500)
      mapFosforo.set('h-9-13', 1250)
      mapFosforo.set('h-14-18', 1250)
      mapFosforo.set('h-19-30', 700)
      mapFosforo.set('h-31-50', 700)
      mapFosforo.set('h-51-70', 700)
      mapFosforo.set('h-71', 700)
      mapFosforo.set('m-9-13', 1250)
      mapFosforo.set('m-14-18', 1250)
      mapFosforo.set('m-19-30', 700)
      mapFosforo.set('m-31-50', 700)
      mapFosforo.set('m-51-70', 700)
      mapFosforo.set('m-71', 700)
      mapFosforo.set('g-18', 1250)
      mapFosforo.set('g-19-30', 700)
      mapFosforo.set('g-31-50', 700)
      mapFosforo.set('l-18', 1250)
      mapFosforo.set('l-19-30', 700)
      mapFosforo.set('l-31-50', 700)

      const mapMagnesio = new Map()
      mapMagnesio.set('0-6m', 30)
      mapMagnesio.set('7-12m', 75)
      mapMagnesio.set('c-1-3', 80)
      mapMagnesio.set('c-4-8', 130)
      mapMagnesio.set('h-9-13', 240)
      mapMagnesio.set('h-14-18', 410)
      mapMagnesio.set('h-19-30', 400)
      mapMagnesio.set('h-31-50', 420)
      mapMagnesio.set('h-51-70', 420)
      mapMagnesio.set('h-71', 420)
      mapMagnesio.set('m-9-13', 240)
      mapMagnesio.set('m-14-18', 360)
      mapMagnesio.set('m-19-30', 310)
      mapMagnesio.set('m-31-50', 320)
      mapMagnesio.set('m-51-70', 320)
      mapMagnesio.set('m-71', 320)
      mapMagnesio.set('g-18', 400)
      mapMagnesio.set('g-19-30', 350)
      mapMagnesio.set('g-31-50', 360)
      mapMagnesio.set('l-18', 360)
      mapMagnesio.set('l-19-30', 310)
      mapMagnesio.set('l-31-50', 320)

      const mapTreonina = new Map()
      mapTreonina.set('0-6m', 0.073)
      mapTreonina.set('7-12m', 0.049)
      mapTreonina.set('c-1-3', 0.032)
      mapTreonina.set('c-4-8', 0.024)
      mapTreonina.set('h-9-13', 0.024)
      mapTreonina.set('h-14-18', 0.022)
      mapTreonina.set('h-19-30', 0.020)
      mapTreonina.set('h-31-50', 0.020)
      mapTreonina.set('h-51-70', 0.020)
      mapTreonina.set('h-71', 0.020)
      mapTreonina.set('m-9-13', 0.022)
      mapTreonina.set('m-14-18', 0.021)
      mapTreonina.set('m-19-30', 0.020)
      mapTreonina.set('m-31-50', 0.020)
      mapTreonina.set('m-51-70', 0.020)
      mapTreonina.set('m-71', 0.020)
      mapTreonina.set('g-18', 0.026)
      mapTreonina.set('g-19-30', 0.026)
      mapTreonina.set('g-31-50', 0.026)
      mapTreonina.set('l-18', 0.03)
      mapTreonina.set('l-19-30', 0.03)
      mapTreonina.set('l-31-50', 0.03)

      const mapTriptofano = new Map()
      mapTriptofano.set('0-6m', 0.028)
      mapTriptofano.set('7-12m', 0.013)
      mapTriptofano.set('c-1-3', 0.008)
      mapTriptofano.set('c-4-8', 0.006)
      mapTriptofano.set('h-9-13', 0.006)
      mapTriptofano.set('h-14-18', 0.006)
      mapTriptofano.set('h-19-30', 0.005)
      mapTriptofano.set('h-31-50', 0.005)
      mapTriptofano.set('h-51-70', 0.005)
      mapTriptofano.set('h-71', 0.005)
      mapTriptofano.set('m-9-13', 0.006)
      mapTriptofano.set('m-14-18', 0.005)
      mapTriptofano.set('m-19-30', 0.005)
      mapTriptofano.set('m-31-50', 0.005)
      mapTriptofano.set('m-51-70', 0.005)
      mapTriptofano.set('m-71', 0.005)
      mapTriptofano.set('g-18', 0.007)
      mapTriptofano.set('g-19-30', 0.007)
      mapTriptofano.set('g-31-50', 0.007)
      mapTriptofano.set('l-18', 0.009)
      mapTriptofano.set('l-19-30', 0.009)
      mapTriptofano.set('l-31-50', 0.009)

      const mapManganes = new Map()
      mapManganes.set('0-6m', 0.003)
      mapManganes.set('7-12m', 0.6)
      mapManganes.set('c-1-3', 1.2)
      mapManganes.set('c-4-8', 1.5)
      mapManganes.set('h-9-13', 1.9)
      mapManganes.set('h-14-18', 2.2)
      mapManganes.set('h-19-30', 2.3)
      mapManganes.set('h-31-50', 2.3)
      mapManganes.set('h-51-70', 2.3)
      mapManganes.set('h-71', 2.3)
      mapManganes.set('m-9-13', 1.6)
      mapManganes.set('m-14-18', 1.6)
      mapManganes.set('m-19-30', 1.8)
      mapManganes.set('m-31-50', 1.8)
      mapManganes.set('m-51-70', 1.8)
      mapManganes.set('m-71', 1.8)
      mapManganes.set('g-18', 2)
      mapManganes.set('g-19-30', 2)
      mapManganes.set('g-31-50', 2)
      mapManganes.set('l-18', 2.6)
      mapManganes.set('l-19-30', 2.6)
      mapManganes.set('l-31-50', 2.6)

      const mapPotassio = new Map()
      mapPotassio.set('0-6m', 400)
      mapPotassio.set('7-12m', 700)
      mapPotassio.set('c-1-3', 3000)
      mapPotassio.set('c-4-8', 3800)
      mapPotassio.set('h-9-13', 4500)
      mapPotassio.set('h-14-18', 4700)
      mapPotassio.set('h-19-30', 4700)
      mapPotassio.set('h-31-50', 4700)
      mapPotassio.set('h-51-70', 4700)
      mapPotassio.set('h-71', 4700)
      mapPotassio.set('m-9-13', 4500)
      mapPotassio.set('m-14-18', 4700)
      mapPotassio.set('m-19-30', 4700)
      mapPotassio.set('m-31-50', 4700)
      mapPotassio.set('m-51-70', 4700)
      mapPotassio.set('m-71', 4700)
      mapPotassio.set('g-18', 4700)
      mapPotassio.set('g-19-30', 4700)
      mapPotassio.set('g-31-50', 4700)
      mapPotassio.set('l-18', 5100)
      mapPotassio.set('l-19-30', 5100)
      mapPotassio.set('l-31-50', 5100)

      const mapSelenio = new Map()
      mapSelenio.set('0-6m', 15)
      mapSelenio.set('7-12m', 20)
      mapSelenio.set('c-1-3', 20)
      mapSelenio.set('c-4-8', 30)
      mapSelenio.set('h-9-13', 40)
      mapSelenio.set('h-14-18', 55)
      mapSelenio.set('h-19-30', 55)
      mapSelenio.set('h-31-50', 55)
      mapSelenio.set('h-51-70', 55)
      mapSelenio.set('h-71', 55)
      mapSelenio.set('m-9-13', 40)
      mapSelenio.set('m-14-18', 55)
      mapSelenio.set('m-19-30', 55)
      mapSelenio.set('m-31-50', 55)
      mapSelenio.set('m-51-70', 55)
      mapSelenio.set('m-71', 55)
      mapSelenio.set('g-18', 60)
      mapSelenio.set('g-19-30', 60)
      mapSelenio.set('g-31-50', 60)
      mapSelenio.set('l-18', 70)
      mapSelenio.set('l-19-30', 70)
      mapSelenio.set('l-31-50', 70)

      const mapSodio = new Map()
      mapSodio.set('0-6m', 120)
      mapSodio.set('7-12m', 370)
      mapSodio.set('c-1-3', 1000)
      mapSodio.set('c-4-8', 30)
      mapSodio.set('h-9-13', 1200)
      mapSodio.set('h-14-18', 1500)
      mapSodio.set('h-19-30', 1500)
      mapSodio.set('h-31-50', 1500)
      mapSodio.set('h-51-70', 1300)
      mapSodio.set('h-71', 1200)
      mapSodio.set('m-9-13', 1500)
      mapSodio.set('m-14-18', 1500)
      mapSodio.set('m-19-30', 1500)
      mapSodio.set('m-31-50', 1500)
      mapSodio.set('m-51-70', 1300)
      mapSodio.set('m-71', 1200)
      mapSodio.set('g-18', 1500)
      mapSodio.set('g-19-30', 1500)
      mapSodio.set('g-31-50', 1500)
      mapSodio.set('l-18', 1500)
      mapSodio.set('l-19-30', 1500)
      mapSodio.set('l-31-50', 1500)

      const mapVitaminaA = new Map()
      mapVitaminaA.set('0-6m', 400)
      mapVitaminaA.set('7-12m', 500)
      mapVitaminaA.set('c-1-3', 300)
      mapVitaminaA.set('c-4-8', 400)
      mapVitaminaA.set('h-9-13', 600)
      mapVitaminaA.set('h-14-18', 900)
      mapVitaminaA.set('h-19-30', 900)
      mapVitaminaA.set('h-31-50', 900)
      mapVitaminaA.set('h-51-70', 900)
      mapVitaminaA.set('h-71', 900)
      mapVitaminaA.set('m-9-13', 600)
      mapVitaminaA.set('m-14-18', 700)
      mapVitaminaA.set('m-19-30', 700)
      mapVitaminaA.set('m-31-50', 700)
      mapVitaminaA.set('m-51-70', 700)
      mapVitaminaA.set('m-71', 700)
      mapVitaminaA.set('g-18', 750)
      mapVitaminaA.set('g-19-30', 770)
      mapVitaminaA.set('g-31-50', 770)
      mapVitaminaA.set('l-18', 1200)
      mapVitaminaA.set('l-19-30', 1300)
      mapVitaminaA.set('l-31-50', 1300)

      const mapVitaminaB12 = new Map()
      mapVitaminaB12.set('0-6m', 0.4)
      mapVitaminaB12.set('7-12m', 0.5)
      mapVitaminaB12.set('c-1-3', 0.9)
      mapVitaminaB12.set('c-4-8', 1.2)
      mapVitaminaB12.set('h-9-13', 1.8)
      mapVitaminaB12.set('h-14-18', 2.4)
      mapVitaminaB12.set('h-19-30', 2.4)
      mapVitaminaB12.set('h-31-50', 2.4)
      mapVitaminaB12.set('h-51-70', 2.4)
      mapVitaminaB12.set('h-71', 2.4)
      mapVitaminaB12.set('m-9-13', 1.8)
      mapVitaminaB12.set('m-14-18', 2.4)
      mapVitaminaB12.set('m-19-30', 2.4)
      mapVitaminaB12.set('m-31-50', 2.4)
      mapVitaminaB12.set('m-51-70', 2.4)
      mapVitaminaB12.set('m-71', 2.4)
      mapVitaminaB12.set('g-18', 2.6)
      mapVitaminaB12.set('g-19-30', 2.6)
      mapVitaminaB12.set('g-31-50', 2.6)
      mapVitaminaB12.set('l-18', 2.8)
      mapVitaminaB12.set('l-19-30', 2.8)
      mapVitaminaB12.set('l-31-50', 2.8)

      const mapVitaminaB2 = new Map()
      mapVitaminaB2.set('0-6m', 0.3)
      mapVitaminaB2.set('7-12m', 0.4)
      mapVitaminaB2.set('c-1-3', 0.5)
      mapVitaminaB2.set('c-4-8', 0.6)
      mapVitaminaB2.set('h-9-13', 0.9)
      mapVitaminaB2.set('h-14-18', 1.3)
      mapVitaminaB2.set('h-19-30', 1.3)
      mapVitaminaB2.set('h-31-50', 1.3)
      mapVitaminaB2.set('h-51-70', 1.3)
      mapVitaminaB2.set('h-71', 1.3)
      mapVitaminaB2.set('m-9-13', 0.9)
      mapVitaminaB2.set('m-14-18', 1)
      mapVitaminaB2.set('m-19-30', 1.1)
      mapVitaminaB2.set('m-31-50', 1.1)
      mapVitaminaB2.set('m-51-70', 1.1)
      mapVitaminaB2.set('m-71', 1.1)
      mapVitaminaB2.set('g-18', 1.4)
      mapVitaminaB2.set('g-19-30', 1.4)
      mapVitaminaB2.set('g-31-50', 1.4)
      mapVitaminaB2.set('l-18', 1.6)
      mapVitaminaB2.set('l-19-30', 1.6)
      mapVitaminaB2.set('l-31-50', 1.6)

      const mapVitaminaB3 = new Map()
      mapVitaminaB3.set('0-6m', 2)
      mapVitaminaB3.set('7-12m', 4)
      mapVitaminaB3.set('c-1-3', 6)
      mapVitaminaB3.set('c-4-8', 8)
      mapVitaminaB3.set('h-9-13', 12)
      mapVitaminaB3.set('h-14-18', 16)
      mapVitaminaB3.set('h-19-30', 16)
      mapVitaminaB3.set('h-31-50', 16)
      mapVitaminaB3.set('h-51-70', 16)
      mapVitaminaB3.set('h-71', 16)
      mapVitaminaB3.set('m-9-13', 12)
      mapVitaminaB3.set('m-14-18', 14)
      mapVitaminaB3.set('m-19-30', 14)
      mapVitaminaB3.set('m-31-50', 14)
      mapVitaminaB3.set('m-51-70', 14)
      mapVitaminaB3.set('m-71', 14)
      mapVitaminaB3.set('g-18', 18)
      mapVitaminaB3.set('g-19-30', 18)
      mapVitaminaB3.set('g-31-50', 18)
      mapVitaminaB3.set('l-18', 17)
      mapVitaminaB3.set('l-19-30', 17)
      mapVitaminaB3.set('l-31-50', 17)

      const mapVitaminaB6 = new Map()
      mapVitaminaB6.set('0-6m', 0.1)
      mapVitaminaB6.set('7-12m', 0.3)
      mapVitaminaB6.set('c-1-3', 0.5)
      mapVitaminaB6.set('c-4-8', 0.6)
      mapVitaminaB6.set('h-9-13', 1)
      mapVitaminaB6.set('h-14-18', 1.3)
      mapVitaminaB6.set('h-19-30', 1.3)
      mapVitaminaB6.set('h-31-50', 1.3)
      mapVitaminaB6.set('h-51-70', 1.7)
      mapVitaminaB6.set('h-71', 1.7)
      mapVitaminaB6.set('m-9-13', 1)
      mapVitaminaB6.set('m-14-18', 1.2)
      mapVitaminaB6.set('m-19-30', 1.3)
      mapVitaminaB6.set('m-31-50', 1.3)
      mapVitaminaB6.set('m-51-70', 1.5)
      mapVitaminaB6.set('m-71', 1.5)
      mapVitaminaB6.set('g-18', 1.9)
      mapVitaminaB6.set('g-19-30', 1.9)
      mapVitaminaB6.set('g-31-50', 1.9)
      mapVitaminaB6.set('l-18', 2)
      mapVitaminaB6.set('l-19-30', 2)
      mapVitaminaB6.set('l-31-50', 2)

      const mapVitaminaC = new Map()
      mapVitaminaC.set('0-6m', 40)
      mapVitaminaC.set('7-12m', 50)
      mapVitaminaC.set('c-1-3', 15)
      mapVitaminaC.set('c-4-8', 25)
      mapVitaminaC.set('h-9-13', 45)
      mapVitaminaC.set('h-14-18', 75)
      mapVitaminaC.set('h-19-30', 90)
      mapVitaminaC.set('h-31-50', 90)
      mapVitaminaC.set('h-51-70', 90)
      mapVitaminaC.set('h-71', 90)
      mapVitaminaC.set('m-9-13', 45)
      mapVitaminaC.set('m-14-18', 65)
      mapVitaminaC.set('m-19-30', 75)
      mapVitaminaC.set('m-31-50', 75)
      mapVitaminaC.set('m-51-70', 75)
      mapVitaminaC.set('m-71', 75)
      mapVitaminaC.set('g-18', 80)
      mapVitaminaC.set('g-19-30', 85)
      mapVitaminaC.set('g-31-50', 85)
      mapVitaminaC.set('l-18', 115)
      mapVitaminaC.set('l-19-30', 120)
      mapVitaminaC.set('l-31-50', 120)

      const mapVitaminaD = new Map()
      mapVitaminaD.set('0-6m', 5)
      mapVitaminaD.set('7-12m', 5)
      mapVitaminaD.set('c-1-3', 5)
      mapVitaminaD.set('c-4-8', 5)
      mapVitaminaD.set('h-9-13', 5)
      mapVitaminaD.set('h-14-18', 5)
      mapVitaminaD.set('h-19-30', 5)
      mapVitaminaD.set('h-31-50', 5)
      mapVitaminaD.set('h-51-70', 10)
      mapVitaminaD.set('h-71', 15)
      mapVitaminaD.set('m-9-13', 5)
      mapVitaminaD.set('m-14-18', 5)
      mapVitaminaD.set('m-19-30', 5)
      mapVitaminaD.set('m-31-50', 5)
      mapVitaminaD.set('m-51-70', 10)
      mapVitaminaD.set('m-71', 15)
      mapVitaminaD.set('g-18', 5)
      mapVitaminaD.set('g-19-30', 5)
      mapVitaminaD.set('g-31-50', 5)
      mapVitaminaD.set('l-18', 5)
      mapVitaminaD.set('l-19-30', 5)
      mapVitaminaD.set('l-31-50', 5)

      const mapVitaminaE = new Map()
      mapVitaminaE.set('0-6m', 4)
      mapVitaminaE.set('7-12m', 5)
      mapVitaminaE.set('c-1-3', 6)
      mapVitaminaE.set('c-4-8', 7)
      mapVitaminaE.set('h-9-13', 11)
      mapVitaminaE.set('h-14-18', 15)
      mapVitaminaE.set('h-19-30', 15)
      mapVitaminaE.set('h-31-50', 15)
      mapVitaminaE.set('h-51-70', 15)
      mapVitaminaE.set('h-71', 15)
      mapVitaminaE.set('m-9-13', 11)
      mapVitaminaE.set('m-14-18', 15)
      mapVitaminaE.set('m-19-30', 15)
      mapVitaminaE.set('m-31-50', 15)
      mapVitaminaE.set('m-51-70', 15)
      mapVitaminaE.set('m-71', 15)
      mapVitaminaE.set('g-18', 15)
      mapVitaminaE.set('g-19-30', 15)
      mapVitaminaE.set('g-31-50', 15)
      mapVitaminaE.set('l-18', 19)
      mapVitaminaE.set('l-19-30', 19)
      mapVitaminaE.set('l-31-50', 19)

      const mapZinco = new Map()
      mapZinco.set('0-6m', 2)
      mapZinco.set('7-12m', 3)
      mapZinco.set('c-1-3', 3)
      mapZinco.set('c-4-8', 5)
      mapZinco.set('h-9-13', 8)
      mapZinco.set('h-14-18', 11)
      mapZinco.set('h-19-30', 11)
      mapZinco.set('h-31-50', 11)
      mapZinco.set('h-51-70', 11)
      mapZinco.set('h-71', 11)
      mapZinco.set('m-9-13', 8)
      mapZinco.set('m-14-18', 9)
      mapZinco.set('m-19-30', 8)
      mapZinco.set('m-31-50', 8)
      mapZinco.set('m-51-70', 8)
      mapZinco.set('m-71', 8)
      mapZinco.set('g-18', 13)
      mapZinco.set('g-19-30', 11)
      mapZinco.set('g-31-50', 11)
      mapZinco.set('l-18', 14)
      mapZinco.set('l-19-30', 12)
      mapZinco.set('l-31-50', 12)

      return {
        mapAcidoAlfaGraxoLinoleico: mapAcidoAlfaGraxoLinoleico,
        mapAcGraxoLinoleico: mapAcGraxoLinoleico,
        mapAcPantotenico: mapAcPantotenico,
        mapAgua: mapAgua,
        mapCalcio: mapCalcio,
        mapFerro: mapFerro,
        mapFibra: mapFibra,
        mapFluor: mapFluor,
        mapFosforo: mapFosforo,
        mapTreonina: mapTreonina,
        mapTriptofano: mapTriptofano,
        mapMagnesio: mapMagnesio,
        mapManganes: mapManganes,
        mapPotassio: mapPotassio,
        mapSelenio: mapSelenio,
        mapSodio: mapSodio,
        mapVitaminaA: mapVitaminaA,
        mapVitaminaB2: mapVitaminaB2,
        mapVitaminaB12: mapVitaminaB2,
        mapVitaminaB3: mapVitaminaB3,
        mapVitaminaB6: mapVitaminaB6,
        mapVitaminaC: mapVitaminaC,
        mapVitaminaD: mapVitaminaD,
        mapZinco: mapZinco
      }
    }
  }
}
