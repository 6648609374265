import Vue from 'vue'
import moment from 'moment'
import globals from '@/globals'
import utils from '@/config/utils'
import constants from '@/config/constants'

Vue.filter('arrendondamento', value => {
  const result = +(Math.round(value + 'e+2') + 'e-2')
  if (isNaN(result)) {
    return 0
  } else {
    return result
  }
})

Vue.filter('arrendondamentoCasas3', value => {
  const result = +(Math.round(value + 'e+3') + 'e-3')
  if (isNaN(result)) {
    return 0
  } else {
    return result
  }
})

Vue.filter('pluralizeReplacementList', value => {
  if (value === 0) {
    return 'Consumir à vontade'
  } else if (value === 1) {
    return '1 item para consumir'
  } else {
    return value + ' itens para consumir'
  }
})

Vue.filter('medida100gramas', value => {
  if (value === '1 (100 g)' || value === '1  (100 g)' || value === '(100.00 g)' || value === '1 (100.00 g)' || value === '1 gramas (100 g)') {
    return '100 g'
  } else {
    return value
  }
})

Vue.filter('lowercase', value => {
  if (!value) {
    return ''
  }
  return value.toString().toLowerCase()
})

Vue.filter('parseDoubleStringDecimal', value => {
  if (!value) {
    return value
  } else {
    return value.toLocaleString('pt-br', { minimumFractionDigits: 2 })
  }
})

Vue.filter('stringToNumberDecimal', value => {
  if (!value) {
    return value
  } else {
    const test = value.replace('.', '')
    const test2 = test.replace(',', '.')
    return parseFloat(test2)
  }
})

Vue.filter('CHOGToKcal', value => {
  const result = value * 4
  return +(Math.round(result + 'e+2') + 'e-2')
})

Vue.filter('PTNGToKcal', value => {
  const result = value * 4
  return +(Math.round(result + 'e+2') + 'e-2')
})

Vue.filter('LipGToKcal', value => {
  const result = value * 9
  return +(Math.round(result + 'e+2') + 'e-2')
})

Vue.filter('formatMedida', (medida, qtd) => {
  let result = ''
  let valorMedida = qtd * medida.valorMedida
  valorMedida = +(Math.round(valorMedida + 'e+2') + 'e-2')
  if (medida.descricaoMedida === 'gramas' || medida.descricaoMedida === 'grama') {
    result = valorMedida + ' ' + medida.descricaoMedida
  } else {
    result = qtd + ' ' + medida.descricaoMedida + ' (' + valorMedida + ' ' + medida.unidade + ')'
  }
  return result
})

Vue.filter('formatDateHourIso', value => {
  if (value === '0' || value === '-' || value === undefined || value === null || value === '') {
    return ''
  } else {
    return moment(value, moment.ISO_8601).format('DD/MM/YYYY H:mm:ss')
  }
})

Vue.filter('formatDateIso', value => {
  if (value === '0' || value === '-' || value === undefined || value === null || value === '') {
    return ''
  } else {
    return moment(value, moment.ISO_8601).format('DD/MM/YYYY')
  }
})

Vue.filter('tipoPlanoTemplate', value => {
  if (value === '0' || value === '-' || value === undefined || value === null || value === '') {
    return ''
  } else {
    var result = ''
    switch (value) {
      case 'TEMPLATE_PLANO_UNICO':
        result = 'Único'
        break
      case 'TEMPLATE_PLANO_SEMANAL':
        result = 'Semanal'
        break
      case 'TEMPLATE_PLANO_PERIODO':
        result = 'Periodo'
        break
      default:
        result = ''
    }
    return result
  }
})
Vue.filter('formatElementFood', value => {
  if (value === undefined || value === '') {
    return ''
  } else {
    if (value.type === 'FOOD' || value.type === 'RECIPE') {
      const gramagemValor = +(Math.round((value.quantidade * value.medida.valorMedida) + 'e+2') + 'e-2')
      if (value.medida.descricaoMedida === 'gramas' || value.medida.descricaoMedida === 'grama') {
        return gramagemValor + ' ' + value.medida.descricaoMedida + ' de ' + value.item.descricao
      } else {
        const gramagem = value.medida.medidaLiquida ? '(' + gramagemValor + ' ml)' : '(' + gramagemValor + ' g)'
        return value.quantidade + ' ' + value.medida.descricaoMedida + ' ' + value.item.descricao + ' ' + gramagem
      }
    } else if (value.type === 'REPLACEMENT') {
      const labelItem = value.quantidade > 1 ? 'item' : 'itens'
      return value.quantidade + ' ' + labelItem + ' da lista de substituição ' + value.item.descricao
    } else {
      return ''
    }
  }
})

Vue.filter('calculaIdadeFormat', dataNascimento => {
  if (dataNascimento === undefined || dataNascimento === '') {
    return ''
  } else {
    const hoje = new Date()
    const nascimento = new Date(dataNascimento)
    let diferencaAnos = hoje.getFullYear() - nascimento.getFullYear()
    if (new Date(hoje.getFullYear(), hoje.getMonth(), hoje.getDate()) < new Date(hoje.getFullYear(), nascimento.getMonth(), nascimento.getDate())) {
      diferencaAnos--
    }
    if (diferencaAnos === 0) {
      let months = hoje.getMonth() - nascimento.getMonth() + (12 * (hoje.getFullYear() - nascimento.getFullYear()))
      if (hoje.getDate() < nascimento.getDate()) {
        months--
      }
      return months + ' meses'
    } else if (diferencaAnos === 1) {
      return '1 ano'
    } else {
      return diferencaAnos + ' anos'
    }
  }
})

Vue.filter('getDateDiff', (date1, date2, interval) => {
  const second = 1000; const minute = second * 60; const hour = minute * 60; const day = hour * 24; const week = day * 7
  const dateone = new Date(date1).getTime()
  const datetwo = (date2) ? new Date().getTime() : new Date(date2).getTime()
  const timediff = datetwo - dateone
  const secdate = new Date(date2)
  const firdate = new Date(date1)
  if (isNaN(timediff)) return NaN
  switch (interval) {
    case 'anos':
      return secdate.getFullYear() - firdate.getFullYear()
    case 'meses':
      return ((secdate.getFullYear() * 12 + secdate.getMonth()) - (firdate.getFullYear() * 12 + firdate.getMonth()))
    case 'semanas':
      return Math.floor(timediff / week)
    case 'dias':
      return Math.floor(timediff / day)
    case 'horas':
      return Math.floor(timediff / hour)
    case 'minutos':
      return Math.floor(timediff / minute)
    case 'segundos':
      return Math.floor(timediff / second)
    default:
      return undefined
  }
})

Vue.filter('rdaFilter', (valor, dataNascimento, nutriente, genero, estadoFisico, peso) => {
  const objIdade = globals().getAgeCalculation(dataNascimento)
  let result = ''
  const isGravida = estadoFisico === 'GESTANTE'
  const isLactante = estadoFisico === 'LACTANTE'
  const valorArrendondado = utils.rounding(valor)
  switch (nutriente.nutriente.idNutriente) {
    case '586d6a0503894421f0f96b85' :
      // Ácido graxo alfa linoléico
      result = utils.caculateRDAForMicronutrient(objIdade, constants().rdaConstantes().mapAcidoAlfaGraxoLinoleico, valorArrendondado, genero, isGravida, isLactante, nutriente.nutriente.unidade)
      break
    case '586d6a0503894421f0f96b91' :
      // Ácido graxo linoléico
      result = utils.caculateRDAForMicronutrient(objIdade, constants().rdaConstantes().mapAcGraxoLinoleico, valorArrendondado, genero, isGravida, isLactante, nutriente.nutriente.unidade)
      break
    case '586d6a0503894421f0f96b9d' :
      // Ácido Pantotênico
      result = utils.caculateRDAForMicronutrient(objIdade, constants().rdaConstantes().mapAcPantotenico, valorArrendondado, genero, isGravida, isLactante, nutriente.nutriente.unidade)
      break
    case '586d6a0503894421f0f96b9e' :
      // água
      result = utils.caculateRDAForMicronutrient(objIdade, constants().rdaConstantes().mapAgua, valorArrendondado, genero, isGravida, isLactante, nutriente.nutriente.unidade)
      break
    case '586d6a0503894421f0f96baa' :
      // Calcio
      result = utils.caculateRDAForMicronutrient(objIdade, constants().rdaConstantes().mapCalcio, valorArrendondado, genero, isGravida, isLactante, nutriente.nutriente.unidade)
      break
    case '586d6a0503894421f0f96bb5' :
      // Ferro
      result = utils.caculateRDAForMicronutrient(objIdade, constants().rdaConstantes().mapFerro, valorArrendondado, genero, isGravida, isLactante, nutriente.nutriente.unidade)
      break
    case '586d6a0503894421f0f96bb6' :
      // Fibra
      result = utils.caculateRDAForMicronutrient(objIdade, constants().rdaConstantes().mapFibra, valorArrendondado, genero, isGravida, isLactante, nutriente.nutriente.unidade)
      break
    case '586d6a0503894421f0f96bba' :
      // Fosforo
      result = utils.caculateRDAForMicronutrient(objIdade, constants().rdaConstantes().mapFosforo, valorArrendondado, genero, isGravida, isLactante, nutriente.nutriente.unidade)
      break
    case '586d6a0503894421f0f96bcb' :
      // Magnésio
      result = utils.caculateRDAForMicronutrient(objIdade, constants().rdaConstantes().mapMagnesio, valorArrendondado, genero, isGravida, isLactante, nutriente.nutriente.unidade)
      break
    case '586d6a0503894421f0f96bcc' :
      // Manganês
      result = utils.caculateRDAForMicronutrient(objIdade, constants().rdaConstantes().mapManganes, valorArrendondado, genero, isGravida, isLactante, nutriente.nutriente.unidade)
      break
    case '586d6a0503894421f0f96bd0' :
      // Potassio
      result = utils.caculateRDAForMicronutrient(objIdade, constants().rdaConstantes().mapPotassio, valorArrendondado, genero, isGravida, isLactante, nutriente.nutriente.unidade)
      break
    case '586d6a0503894421f0f96bd5' :
      // Selenio
      result = utils.caculateRDAForMicronutrient(objIdade, constants().rdaConstantes().mapSelenio, valorArrendondado, genero, isGravida, isLactante, nutriente.nutriente.unidade)
      break
    case '586d6a0503894421f0f96bd7' :
      // Sódio
      result = utils.caculateRDAForMicronutrient(objIdade, constants().rdaConstantes().mapSodio, valorArrendondado, genero, isGravida, isLactante, nutriente.nutriente.unidade)
      break
    case '586d6a0503894421f0f96bdf' :
      // Treonina
      if (peso) {
        const valorArr = utils.rouding3(valor)
        const valorgKg = utils.rouding3(valorArr / peso)
        result = utils.caculateRDAForMicronutrient(objIdade, constants().rdaConstantes().mapTreonina, valorgKg, genero, isGravida, isLactante, 'g/Kg')
      }
      break
    case '586d6a0503894421f0f96be0' :
      // Triptofano
      if (peso) {
        const valorArr = utils.rouding3(valor)
        const valorgKg = utils.rouding3(valorArr / peso)
        result = utils.caculateRDAForMicronutrient(objIdade, constants().rdaConstantes().mapTriptofano, valorgKg, genero, isGravida, isLactante, 'g/Kg')
      }
      break
    case '586d6a0503894421f0f96bd3' :
      // Vitamina A
      result = utils.caculateRDAForMicronutrient(objIdade, constants().rdaConstantes().mapVitaminaA, valorArrendondado, genero, isGravida, isLactante, nutriente.nutriente.unidade)
      break
    case '586d6a0503894421f0f96be6' :
      // Vitamina B12
      result = utils.caculateRDAForMicronutrient(objIdade, constants().rdaConstantes().mapVitaminaB12, valorArrendondado, genero, isGravida, isLactante, nutriente.nutriente.unidade)
      break
    case '586d6a0503894421f0f96bd4':
      // Vitamina B2 (Riboflavina)
      result = utils.caculateRDAForMicronutrient(objIdade, constants().rdaConstantes().mapVitaminaB2, valorArrendondado, genero, isGravida, isLactante, nutriente.nutriente.unidade)
      break
    case '586d6a0503894421f0f96bcf':
      // Vitamina B3 (Niacina)
      result = utils.caculateRDAForMicronutrient(objIdade, constants().rdaConstantes().mapVitaminaB3, valorArrendondado, genero, isGravida, isLactante, nutriente.nutriente.unidade)
      break
    case '586d6a0503894421f0f96be8':
      // Vitamina B6 (Piridoxina)
      result = utils.caculateRDAForMicronutrient(objIdade, constants().rdaConstantes().mapVitaminaB6, valorArrendondado, genero, isGravida, isLactante, nutriente.nutriente.unidade)
      break
    case '586d6a0503894421f0f96be9':
      // Vitamina C (Ácido ascórbico)
      result = utils.caculateRDAForMicronutrient(objIdade, constants().rdaConstantes().mapVitaminaC, valorArrendondado, genero, isGravida, isLactante, nutriente.nutriente.unidade)
      break
    case '586d6a0503894421f0f96beb':
      // Vitamina D (D2 + D3)
      result = utils.caculateRDAForMicronutrient(objIdade, constants().rdaConstantes().mapVitaminaD, valorArrendondado, genero, isGravida, isLactante, nutriente.nutriente.unidade)
      break
    case '586d6a0503894421f0f96bed':
      // Vitamina E (alfatocoferol)
      result = utils.caculateRDAForMicronutrient(objIdade, constants().rdaConstantes().mapVitaminaD, valorArrendondado, genero, isGravida, isLactante, nutriente.nutriente.unidade)
      break
    case '586d6a0503894421f0f96bf0':
      // Zinco
      result = utils.caculateRDAForMicronutrient(objIdade, constants().rdaConstantes().mapZinco, valorArrendondado, genero, isGravida, isLactante, nutriente.nutriente.unidade)
      break
    default:
      result = ''
  }
  return result
})

Vue.filter('removeMaskMoney', value => {
  value = value.replace(/\./g, '')
  return /\d+,\d+/.exec(value)[0].toString()
})

Vue.filter('formatEndereco', input => {
  if (input === undefined || input === '' || input === 0) {
    return ''
  } else {
    let enderecoFormatado = ''
    if (input.endereco === undefined || input.endereco === '') {
      enderecoFormatado = ''
    } else {
      enderecoFormatado = input.endereco.logradouro + ' • ' + input.endereco.numeroLogradouro + ' • '
      if (input.endereco.complemento !== undefined && input.endereco.complemento !== '') {
        enderecoFormatado = enderecoFormatado + input.endereco.complemento + ' • '
      }
      enderecoFormatado = enderecoFormatado + input.endereco.bairro + ' • ' + input.endereco.cidade + ' • ' + input.endereco.estado
    }
    return enderecoFormatado
  }
})

Vue.filter('semValor', value => {
  if (value === '0' || value === '-' || value === undefined || value === null || value === '') {
    return '-'
  } else {
    return value
  }
})
